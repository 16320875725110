
.ui-slider {
	position: relative;
	text-align: left;
}
.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 1.2em;
	height: 1.2em;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: 0.7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}

.ui-slider-horizontal {
	height: 0.8em;

	background: transparent;
	position: relative;

	&:before {
		content: "";
		width: 120%;
		height: 2px;
		background: $gray-500;
		top: 50%;
		margin-top: -2px;
		display: block;
		position: absolute;
		left: -10%;
	}
}
.ui-slider-horizontal .ui-slider-handle {
	top: -0.3em;
	margin-left: -0.6em;
}
.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: 0.8em;
	height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
	left: -0.3em;
	margin-left: 0;
	margin-bottom: -0.6em;
}
.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

.ui-slider-handle {
	background: $gray-100 !important;
	border-radius: 50%;
	cursor: pointer !important;
	border: 1px solid $gray-500;

	&:focus {
		outline: none !important ;
		@extend %no-drop-shadow;
	}
}

.ui-draggable,
.ui-droppable {
	background-position: top;
}

.price-range {
	position: relative;
	margin: 1em 1.5em;
	margin-bottom: 0.25em;

	.amount {
		text-align: center !important ;
		width: 100% !important ;

		margin: 0 auto !important;
		width: auto;
		border: 0;
		pointer-events: none;
		cursor: default;

		color: $green;
		font-size: 1.05em;
		letter-spacing: 1px;
		display: block;
		color: $complementary;

		span {
			display: inline-block;

			@include opacity(0.75);

			&:first-child {
				text-align: left;
			}
			&:last-child {
				text-align: right;
			}

			font-family: $font-family-numbers;
			font-weight: 900;

			padding: 0.75em 0.25em;
		}
	}

	.slider-range-display {
		position: relative;
		width: auto !important;
		height: 80px;
		display: table;
		margin: 0 auto;
		margin-bottom: -4px !important ;

		left: 50%;

		@include translate(-50%, 0);

		width: 100%;
		min-width: 100%;
		overflow: hidden;

		span {
			@include bg-opacity($green, 0.45);

			display: block;

			width: 10px;

			position: absolute;

			margin: 0;
			padding: 0;

			bottom: 0;

			content: " ";

			//@include setNumericPorpertyToChilds( 1, 8, 1, 28, 'left', 'px') ;

			//@include setNumericPorpertyToChilds( 1, 4, 1, 28, 'height', '%') ;
		}
	}
}

// price range heights
.price-range .slider-range-display span:nth-child(1) {
	left: 0px;
}
.price-range .slider-range-display span:nth-child(2) {
	left: 9px;
}
.price-range .slider-range-display span:nth-child(3) {
	left: 17px;
}
.price-range .slider-range-display span:nth-child(4) {
	left: 25px;
}
.price-range .slider-range-display span:nth-child(5) {
	left: 33px;
}
.price-range .slider-range-display span:nth-child(6) {
	left: 41px;
}
.price-range .slider-range-display span:nth-child(7) {
	left: 49px;
}
.price-range .slider-range-display span:nth-child(8) {
	left: 57px;
}
.price-range .slider-range-display span:nth-child(9) {
	left: 65px;
}
.price-range .slider-range-display span:nth-child(10) {
	left: 73px;
}
.price-range .slider-range-display span:nth-child(11) {
	left: 81px;
}
.price-range .slider-range-display span:nth-child(12) {
	left: 89px;
}
.price-range .slider-range-display span:nth-child(13) {
	left: 97px;
}
.price-range .slider-range-display span:nth-child(14) {
	left: 105px;
}
.price-range .slider-range-display span:nth-child(15) {
	left: 113px;
}
.price-range .slider-range-display span:nth-child(16) {
	left: 121px;
}
.price-range .slider-range-display span:nth-child(17) {
	left: 129px;
}
.price-range .slider-range-display span:nth-child(18) {
	left: 137px;
}
.price-range .slider-range-display span:nth-child(19) {
	left: 145px;
}
.price-range .slider-range-display span:nth-child(20) {
	left: 153px;
}
.price-range .slider-range-display span:nth-child(21) {
	left: 161px;
}
.price-range .slider-range-display span:nth-child(22) {
	left: 169px;
}
.price-range .slider-range-display span:nth-child(23) {
	left: 177px;
}
.price-range .slider-range-display span:nth-child(24) {
	left: 185px;
}
.price-range .slider-range-display span:nth-child(25) {
	left: 193px;
}
.price-range .slider-range-display span:nth-child(26) {
	left: 201px;
}
.price-range .slider-range-display span:nth-child(27) {
	left: 209px;
}
.price-range .slider-range-display span:nth-child(28) {
	left: 217px;
}
//-->
.price-range .slider-range-display span:nth-child(1) {
	height: 31px;
}
.price-range .slider-range-display span:nth-child(2) {
	height: 10px;
}
.price-range .slider-range-display span:nth-child(3) {
	height: 11px;
}
.price-range .slider-range-display span:nth-child(4) {
	height: 19px;
}
.price-range .slider-range-display span:nth-child(5) {
	height: 27px;
}
.price-range .slider-range-display span:nth-child(6) {
	height: 61px;
}
.price-range .slider-range-display span:nth-child(7) {
	height: 5px;
}
.price-range .slider-range-display span:nth-child(8) {
	height: 69px;
}
.price-range .slider-range-display span:nth-child(9) {
	height: 16px;
}
.price-range .slider-range-display span:nth-child(10) {
	height: 42px;
}
.price-range .slider-range-display span:nth-child(11) {
	height: 39px;
}
.price-range .slider-range-display span:nth-child(12) {
	height: 45px;
}
.price-range .slider-range-display span:nth-child(13) {
	height: 31px;
}
.price-range .slider-range-display span:nth-child(14) {
	height: 11px;
}
.price-range .slider-range-display span:nth-child(15) {
	height: 76px;
}
.price-range .slider-range-display span:nth-child(16) {
	height: 23px;
}
.price-range .slider-range-display span:nth-child(17) {
	height: 6px;
}
.price-range .slider-range-display span:nth-child(18) {
	height: 78px;
}
.price-range .slider-range-display span:nth-child(19) {
	height: 57px;
}
.price-range .slider-range-display span:nth-child(20) {
	height: 50px;
}
.price-range .slider-range-display span:nth-child(21) {
	height: 24px;
}
.price-range .slider-range-display span:nth-child(22) {
	height: 59px;
}
.price-range .slider-range-display span:nth-child(23) {
	height: 13px;
}
.price-range .slider-range-display span:nth-child(24) {
	height: 27px;
}
.price-range .slider-range-display span:nth-child(25) {
	height: 38px;
}
.price-range .slider-range-display span:nth-child(26) {
	height: 74px;
}
.price-range .slider-range-display span:nth-child(27) {
	height: 42px;
}
.price-range .slider-range-display span:nth-child(28) {
	height: 63px;
}