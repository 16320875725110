%gradient {
	background-image: -webkit-linear-gradient(21deg, #2ed77d, #03cc5e);
	background-image: -moz-linear-gradient(21deg, #2ed77d, #03cc5e);
	background-image: -o-linear-gradient(21deg, #2ed77d, #03cc5e);
	background-image: linear-gradient(69deg, #2ed77d, #03cc5e);
}

%gradient-logo {
	background-image: -webkit-linear-gradient(135deg, #00a3a6 14%, #00b583 36%, #01c964 50%);
	background-image: -moz-linear-gradient(135deg, #00a3a6 14%, #00b583 36%, #01c964 50%);
	background-image: -o-linear-gradient(135deg, #00a3a6 14%, #00b583 36%, #01c964 50%);
	background-image: linear-gradient(135deg, #00a3a6 14%, #00b583 36%, #01c964 50%);
}

%drop-shadow {
	-webkit-box-shadow: 0px 10px 15px 4px rgba($complementary, 0.1);
	-moz-box-shadow: 0px 10px 15px 4px rgba($complementary, 0.1);
	box-shadow: 0px 10px 15px 4px rgba($complementary, 0.1);
}

%drop-shadow-popups {
	-webkit-box-shadow: 0px 10px 20px 4px rgba($complementary, 0.15);
	-moz-box-shadow: 0px 10px 20px 4px rgba($complementary, 0.15);
	box-shadow: 0px 10px 20px 4px rgba($complementary, 0.15);
}

%no-drop-shadow {
	-webkit-box-shadow: 0 none !important;
	-moz-box-shadow: 0 none !important;
	box-shadow: 0 none !important;
}

%text-shadow {
	text-shadow: 0.5px 0 0 currentColor;
	letter-spacing: 0px;
}

%user-select {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}

%border-tb-light {
	border-top: 4px solid $gray-100;
	border-bottom: 4px solid $gray-100;
}

%mp-0 {
	margin: 0;
	padding: 0;
}

%aspect-ratio {
	// aspect ratio 16:9 56.25%
	// 2:1 50%
	// 9:4 44%
	padding-top: 50%;
}

%aspect-ratio-2 {
	// aspect ratio 16:9 56.25%
	// 2:1 50%
	// 9:4 44%
	padding-top: 56.25%;
}


%no-image {
	background-image: url(../../../../storage/logo/default-16-9.svg);
	background-color: transparent !important;
	background-size: 65%;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
	z-index: 1;
	
	&:after,
	&:before {
		position: absolute;
		top: 0;
		display: block;
		height: 0;
		width: 0;
		content: "";
		background-color: transparent;
		border-left: 25px solid transparent;
		border-right: 250px solid transparent;
	}

	&:after {
		right: 0;
		transform: rotate(33deg) scale(8);
		border-bottom: 100px solid $green;
		@include opacity(0.25);
		z-index: -1;
	}
	&:before {
		left: 0;
		transform: rotate(-33deg) scale(4);
		border-bottom: 100px solid $blue;
		@include opacity(0.35);
		z-index: -1;
	}
	
}


%block-before-after { 

	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	display: block;
	
}

%vertical-top-middle {
	top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}


%rotate-vertical-top-middle {
	top: 50%;
  -webkit-transform: translateY(-50%) rotate(135deg);
      -ms-transform: translateY(-50%) rotate(135deg);
          transform: translateY(-50%) rotate(135deg);
}
