.component---hotel-edit-bar {
	
	@extend %border-tb-light;
    @extend %mp-0;
	overflow: hidden;
	z-index: 1;

	ul {
		@extend %mp-0;
		position: relative;
        font-size: $font-smaller;
        white-space: nowrap;
		min-width: 900px;
		
		@include media-breakpoint-down("md") { 
			font-size: 0.75em;
		}

		li {
			position: relative;
			color: $gray-600 !important;
			vertical-align: middle;
            padding: 0.5em 0.5em !important;
            white-space: nowrap;

			&:before {
				content: attr(data-text);
                @include opacity(0.75);
                @include media-breakpoint-down("sm") { 

                    display: none;

                }
			}

			&:after {
				content: "";
				position: absolute;
				display: block;
				width: 1px;
				height: 100%;
				background-color: $gray-500;
				top: 0%;
				right: -0.25em;
				@include rotate(15);

				@include media-breakpoint-down("sm") { 

                    display: none;

				}				
			}
			&:last-child:after {
				display: none;
			}
			/*
			&:first-child:after {
				padding-left: 0.25em !important;
				padding-right: 0.25em  !important;
			}
			*/
			@include media-breakpoint-down("sm") { 

				display: none;
				&:first-child {
					display: block;
					height: 30px;
				}

			}
 
		}
	}

	a {
        width: 40px;
        height: 100%;
		display: block;
        margin: 0 auto;
        border:1px solid transparent;

		i.icon-rxxl--container {
			width: 100%;
			height: 100%;
            display: block;
            background-size: 28px 28px;
            padding: 0.5em;
            position: absolute;
            top:50%;
            left:50%;
			@include translate(-50%,-50%);

			@include media-breakpoint-down("md") { 
				background-size: 24px 24px;	
			}
            
        }
        
        @include media-breakpoint-down("sm") { 
            width: 30px;
		}
		
		&.go-back-search {

			position: absolute;
			right: 0;
			//border:1px solid $green;
			top:50%;
			@include translate(0%,-50%);

			i.icon-rxxl--container {
				background-size: 10px 10px !important;
			}
		}
	}
	//end edit-bar-hotel
}
