.icon-rxxl-background {
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
	background-color: transparent;

	&.small {
		background-size: 10px 10px;
		background-position: 50%;
		margin-right: $grid-gutter-width / 2;
	}
}

.icon-rxxl {
	&--container {
		@extend .icon-rxxl-background;
		position: relative;
		display: inline-block;
		width: (1 / 1) * 1em; // 1:1
		line-height: 1em;
		text-decoration: none;
		border: 1px solid transparent;

		&:before {
			content: "\00a0";
		}

		//end container
	}


	&---size-xl {
			width: 25px;
			height: 25px;
	}
	

	
	&---pictures--gallery {
		background-image: $icon-pictures-gallery;
		&.yellow {

			background-image: $icon-pictures-gallery-yellow;
		}

		&.dark {

			background-image: $icon-pictures-gallery-dark;
		}

	}
	

	// icons

	&---location {
		background-image: $icon-pointer-map;

		&.blue {

			background-image: $icon-pointer-map-blue;
		}
	}

	&---copy-clipboard {
		background-image: $icon-copy-clipboard;
	}

	&---phone {
		background-image: $icon-phone-white;

		&.blue {

			background-image: $icon-phone-blue;
		}
	}

	&---phone-2 {
		background-image: $icon-phone-2;
	}

	&---mail {
		background-image: $icon-mail-white;

		&.blue {

			background-image: $icon-mail-blue;
		}
	}

	

	&---back {
		background-image: $btn-arrow-left;
		&.white {

			background-image: $btn-arrow-left-white;

		}
	}

	&---forward {
		background-image: $btn-arrow-right;
		&.white {

			background-image: $btn-arrow-right-white;

		}

		&.green 
		{
			background-image: $btn-arrow-right-green;

		}
	}
	

	&---location--dark {
		background-image: $icon-pointer-map-dark;
	}


	//--

	&---hotel {
		background-image: $icon-hotel-dark;
	}

	&---user {
		background-image: $icon-user-male;
	}

	&---hotel--dark {
		background-image: $icon-hotel-dark;
	}

	&---edit-dark {
		background-image: $icon-edit-dark;
	}

	&---edit {
		background-image: $icon-edit;
	}

	&---edit-search {
		background-image: $icon-search-dark;
	}

	&---ro {
		background-image: $icon-base-ro;
		&.white {
			background-image: $icon-base-ro-white;
    }
    &.blue {
			background-image: $icon-base-ro-blue;
			@include media-breakpoint-down("sm") {
				background-image: $icon-base-ro-white;
			}
    }
	}

	&---bb {
		background-image: $icon-base-bb;
		&.white {
			background-image: $icon-base-bb-white;
    }
    &.blue {
			background-image: $icon-base-bb-blue;
			@include media-breakpoint-down("sm") {
				background-image: $icon-base-bb-white;
			}
    }
	}

	&---hb {
		background-image: $icon-base-hb;
		&.white {
			background-image: $icon-base-hb-white;
    }
    &.blue {
			background-image: $icon-base-hb-blue;
			@include media-breakpoint-down("sm") {
				background-image: $icon-base-hb-white;
			}
    }
	}

	&---fb {
		background-image: $icon-base-fb;
		&.white {
			background-image: $icon-base-fb-white;
    }
    &.blue {
			background-image: $icon-base-fb-blue;
			@include media-breakpoint-down("sm") {
				background-image: $icon-base-fb-white;
			}
    }
	}

	&---ai {
		background-image: $icon-base-ai;
		&.white {
			background-image: $icon-base-ai-white;
    }
    &.blue {
			background-image: $icon-base-ai-blue;
			@include media-breakpoint-down("sm") {
				background-image: $icon-base-ai-white;
			}
    }
	}

	&---all {
		background-image: $icon-base-all;

		&.white {
			background-image: $icon-base-all-white;
    }
    

    &.blue {
			background-image: $icon-base-all-blue;
			@include media-breakpoint-down("sm") {
				background-image: $icon-base-all-white;
			}
    }
    
	}

	// just for room offers
	&---reload {
		background-image: $icon-reset-dark;
		background-size: 1.5em 1.5em !important;
		background-position: left 8px center !important;
	}

	&---coin {
		background-image: $icon-base-pah;
		background-position: center center;
	}

	&---card {
		background-image: $icon-base-pis;
		background-position: center center;

		&.blue {
			background-size: 140%;
			background-image: $icon-room-card-blue;
		}
	}

	&---tax {
		background-image: $icon-trending-up;
		background-size: 85%;
		background-position: center center;

		&.yellow {
			background-image: $icon-trending-up-yellow;
		}
	}

	&---no-money {
		background-image: $icon-base-norefundable;
		background-position: center center;

		&.red {
			background-size: 140%;
			background-image: $icon-room-no-money-red;
		}
		&.blue {
			background-size: 140%;
			background-image: $icon-room-no-money-blue;
		}
	}

	&---info {
		background-image: $icon-info;
		background-size: 80%;
		background-position: center center;

		&.blue {
			background-image: $icon-info-blue;
		}

	}
	&---refundable {
		background-image: $icon-base-refundable;
		background-position: center center;

		&.green {
			background-image: $icon-room-refundable-green;
		}
	}

	&---refresh {
		background-image: $icon-reset-dark;
		background-size: 70%;
		background-position: center center;
	}

	&---camera {
		background-image: $icon-room-camera;
		background-position: center center;
		background-repeat:no-repeat;
		//background-size: 150% !important;
	}

	&---hang {
		background-image: $icon-base-tag;
		background-position: center center;
	}

	&---key {
		background-image: $icon-base-key;
		background-position: center center;
	}

	&---timer {
		background-image: $icon-room-timer;
		background-size: 95%;
		background-position: center center;
	}

	&---reset {
		background-image: $icon-reset;
		background-size: 95%;
		background-position: center center;
	}

	&---expand-gallery {
		background-image: $icon-expand-gallery;
		background-size: 95%;
		background-position: center center;
		background-repeat: none;
	}

	

	///---- amienities

	///--- "1";"Air conditioning"

	///--- "2396";"Satellite TV service"
	///--- "2397";"Digital TV service"
	///--- "2398";"Cable TV service"
	///--- "2399";"Flat-panel TV"
	///--- "2402";"HDTV"
	///--- "26";"Television"
	///--- "2575";"LCD TV"

	///--- "20";"Room service (limited hours)"

	///--- "2403";"Free WiFi"

	//"128";"Free newspaper"

	// parking 2554, 2783, 3761, 3861, 3862, 3863, 4454, 4455, 1073742906

	//  "2391";"WiFi (surcharge)"

	///"132";"Coffee/tea maker"

	&---logout {

		background-image: $icon-logout;
	}


	&---eye {

		background-image: $icon-eye;
	}

	
	&---eye-close {

		background-image: $icon-eye-close;
	}

	&---amenities {
		//-- ac
		&-1 {
			background-image: $icon-amenities-ac;
		}
		//-- wifi surcharge
		&-2391,
		&-2404 {
			background-image: $icon-amenities-wifi-yellow;
		}
		//-- wifi
		&-2403,
		&-2390 {
			background-image: $icon-amenities-wifi;
		}
		//-- tv
		&-1073743569,
		&-2396,
		&-2397,
		&-2398,
		&-2399,
		&-2402,
		&-26,
		&-2575,
		&-6147 {
			background-image: $icon-amenities-tv;
		}

		//-- pay movies
		&-1073742776,
		&-2039 {
			background-image: $icon-amenities-tv-yellow;
		}

		//-- fitness
		&-9,
		&-2048,
		&-2178,
		&-4468,
		&-1073742765,
		&-2008,
		&-5054 {
			background-image: $icon-amenities-fitness;
			background-size: 140%;
		}
		/*
		//-- Tour/tikets assistance
		&-2387 {
			background-image: $icon-amenities-tickets-assistance;
			background-size: 125%;
		}
		*/

		//-- bar lounge
		&-3 {
			background-image: $icon-amenities-lounge;
			background-size: 125%;
		}
		
		//-- parking
		&-2554, 
		&-2783, 
		&-3761, 
		&-3861, 
		&-3863, 
		&-4454, 
		&-1073742906 {
			background-image: $icon-amenities-parking;
			background-size: 160%;
		}

		//-- parking surcharge
		&-3862,
		&-4455,  
		&-4451 {
			background-image: $icon-amenities-parking-yellow;
			background-size: 160%;
		}
		 // spa 

		&-2017,
		&-2123,
		&-2123,
		&-2129
		{

			background-image: $icon-amenities-spa;
			background-size: 130%;

		}

		// pool
		&-24, 
		&-14, 
		&-2014,  
		&-2859,
		&-1073742768,
		&-1073742469,
		&-1073743549
		{

			background-image: $icon-amenities-pool;
			background-size: 120%;

		}
		// pets
		
		&-51,
		&-1073741807,
		&-1073742807 {
			background-image: $icon-pet-dark;
			background-size: 110%;
		}

		// 24hs front-desk
		&-2063,
		&-2015 {

			background-image: $icon-amenities-s24hrs;
			background-size: 125%;

		}
		//-- newspapper
		&-128 {
			background-image: $icon-amanities-newspapper;
			background-size: 120%;
		}
		//-- coffe maker
		&-132 {
			background-image: $icon-amanities-coffeemaker;
		}
		//--atm
		&-41 {
			background-image: $icon-amenities-atm;
			background-size: 120%;
		}
		//-- Room Service
		&-20,
		&-43,
		&-2015,
		&-2053 {
			background-image: $icon-amanities-conciergebell;
			background-size: 120%;
		}
	}

	//end icon
}