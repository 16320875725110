.box-banners {
	min-height: 120px;
	margin: 0 !important ;
	// I don't know why....
	border-bottom: 1px solid transparent;

	img {
		max-height: 80px;
		width: auto;
		text-align: center;

		&.img-max {

			max-height: 60px;

			@include media-breakpoint-down("md") {

				max-height: 60px;

			}

			@include media-breakpoint-down("sm") {

				max-height: 50px;

			}


		}
	}

	img.payment-methods-img {
		@include opacity(0.5);
	}

	padding-top: 2em;
	padding-bottom: 2em;
}

.box-banners {
	$a: 100%;
	$b: 10px;

	.international,
	.quik-links {
		position: relative;
		display: inline-block;
		width: 100%;
		padding: 0;
		line-height: 2.8em;
		&:before {
			content: "\00a0";
		}

		margin-bottom: 15px;
		/*
    &:after {
      content: "";
      position: absolute;
      top: calc(#{$a} + #{$b});
      width: 100%;
      height: 2px;
      background-color: $white;
      left: 0;
	}
	*/
	}

	.international {
		background-image: url(../../../../storage/logo/international.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: left 20px center;

		@include media-breakpoint-down("xs") {
      		background-size: 45%;
      		background-position: left 10px center;

		}
	}
}

.payment-methods {
	.paragraph {
		display: table;

		h4,
		p {
			display: table-cell;
			vertical-align: middle;
		}

		p {
			padding: 2em;
		}

		h4 {
			width: 25%;
			position: relative;

			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 2px;
				height: 100%;
				top: 0;
				right: 20px;
				background-color: $primary;
			}
		}
	}
}

.security-partners {
	.row {
		display: table;
		width: 100%;
		border: 1px solid transparent;
	}

	a,
	p {
		display: table-cell;
		vertical-align: middle;
		width: 25%;
	}
}
