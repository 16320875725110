/* Fonts */

.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
	position: relative;
	min-height: 1px;
}

@include media-breakpoint-up("lg") {
	.col-lg-1-5 {
		flex: 0 0 14.583333333325%;
		max-width: 14.583333333325%;
	}
}

//--no border sides

.bl-0 {
	border-left: 0 !important;
}
.br-0 {
	border-right: 0 !important;
}
.bt-0 {
	border-top: 0 !important;
}
.bb-0 {
	border-bottom: 0 !important;
}
