$height: 64px;
$angle: calc(#{$height} / 1.75);

.component---booking-summary {
	width: 100%;
	margin: 1.25em auto;
	margin-top: 0;
	background-color: $white;
	list-style-type: none;
	padding: 0;
	
	

	> li {
	//  border-bottom: 1px solid $gray-500;
	padding-bottom:1.75em !important;
	  h5 {
		padding: 0.75em;
		color: $primary;
		text-transform: uppercase;
		font-size: $font-small;
		margin: 0;
		font-weight: 600;
		letter-spacing: 0.75px;
		position: relative;
	  }
  
	  > .inner {

		width: 100%;
		padding: 0 !important;
  
		  // end header

		.line-data {
		//  border-bottom: 1px solid $gray-100;
		  padding: 0.5em 0.75em;
		  overflow: hidden;
		  white-space: nowrap;
		  position: relative;

			
		  &:last-child {
			border: 0;
		  }
		}
  
		p.line-data {
		  margin-bottom: 0;
		}
  
		.line-prices {
		//  border-bottom: 1px solid $gray-100;
		  padding:  0em 0em;
		  overflow: hidden;
		  
		  position: relative;
		  display: table;
		  width: 100%;
		  margin:0 !important;


		  strong, span { display: table-cell; vertical-align: middle;}

		  &.rooms-and-board { span, strong { 
			  font-weight: normal;
			color: $gray-600;  } }


			&.subtitle { 
			
				span, strong { 
				font-size: 0.85em;
				font-weight: normal;
			 	color: $gray-400;  } }


		 &.partial {
			padding-top: 0.5em !important;
			padding-bottom: 0.75em !important;
			
			span { font-weight: bold;  }

		  }

		  span { white-space: nowrap; }

		  &:last-child {
			border: 0;
		  }
		  
		  &.partial {

			border-top: 2px solid $gray-100;
	
		//	background-color: rgba($dark, .01);

		  }

		  &.line {

		//	border-top: 1px solid $gray-100;

		  }

		  &.total {

		//	border-top: 1px solid $gray-500;
			border-top: 2px solid $gray-100;
			span { font-weight: bold;  }
			padding-top: 0.5em !important;


		  }

		 	&.selected {
			margin-left: -0.75em;
			margin-right: -0.75em;
			margin-bottom: 0.5em !important;
			background-color: $gray-100;
			display: flex;
			white-space: nowrap;
			padding:  0.75em 0em;
		//	border-top:2px solid $gray-600;
		//	border-bottom:2px solid $gray-600;
			border:0;

			span {
			  position: absolute;
			  top: 0;
			  right: 0;
			  height: 100%;
			  background-color: $green;
			  color: $white;
			  width: auto;
			  font-family: $font-family-numbers;
			  font-size: 1.35em;
			  font-weight: 900;
			  display: inline-block;
			  padding: 0.45em 0.75em ;
			  letter-spacing: 1px;
  
			  &:first-letter {
				font-family: $font-family-base;
			  }
  
			  &:after {
				content: "";
				position: absolute;
				z-index: 2;
				bottom: 0;
				right: 100%;
				top: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 $height $angle;
				border-color: transparent transparent $green transparent;

				/*
				@include media-breakpoint-down("md") {
				  display: none;
				}
				*/
			  }
			}
		  }
		  &.highlight
		  {

			font-size: 0.75em;
			height: 20px;
			line-height: 20px;
			margin-top: 50px !important;
			background-color: rgba($green, .25);
			strong, span {
				text-transform: uppercase;
				font-weight: 500;
				font-size: 0.75em;
			}
			border-top: 1px solid $gray-500;

		  }
		  &.tax,
		  &.message {
			 // border-top: 1px solid $gray-100;
			strong,
			span {
			  font-weight: normal;
			  color: $gray-600;
			}
		  }

		  &.message {

			strong,
			span { 
				font-size: 0.85em;
				color: $gray-400;
			}

		  }
  
		  span {
			text-align: right;
		  }
		}
	  }
	}
  }