.component---tooltip
 {

    position: relative;
   
    &-inner {

        border:4px solid red;
        position: absolute;
        background-color: black;
        top:0;
        left:0;
        display: none;
    }

    &:hover {

        .component---tooltip-inner {

            display: block;

        }



    }

 }