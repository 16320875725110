.component---alert {
	position: fixed;
	background: rgba($black, 0.75);
	width: 100%;
	height: 100%;
	z-index: 999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;

	&.active {
		display: block;
	}

	&:before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-image: url($base-64-background);
	//	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPgo8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI4IiBmaWxsPSIjNDAzYzNmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDBMOCA4Wk04IDBMMCA4WiIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiMxZTI5MmQiPjwvcGF0aD4KPC9zdmc+");
		@include opacity(0.15);
	}
	&---message {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: transparent;
		padding: 1.75em 2.75em;
		padding-left: 120px;
		background-image: $icon-alert-bell;
		background-repeat: no-repeat;
		background-size: 60px 60px;
		background-position: left 30px center;
		color: $dark;
		background-color: $white;

		&.warning {
			background-image: $icon-alert-bell !important;
		}

		&.success {
			background-image: $alert-success !important;
		}

		&.danger {
			background-image: $alert-danger !important;
		}

		a.btn {
			background-color: $dark;
			position: relative;
			display: inline-block;
			min-width: 100px;
			height: 40px;
			margin-left: 0.75em;
			margin-right: 0.75em;
			padding-left: 1em;
			padding-right: 1em;
			color: $white;
			background-repeat: no-repeat;
			background-size: 30px 30px;
			background-position: center center;

			@include media-breakpoint-down("xs") {
				width: calc(100% - 1.5em) ;
				margin-bottom:0.75em;
			}

			&.btn---reset {
				background-image: $icon-reset;
			}

			&.btn---accept {
				background-color: $green;

				&.reverse {
					background-color: $red;
				}
			}

			&.btn---reject {
				background-color: $red;
				&.reverse {
					background-color: $green;
				}
			}
		}
		@include media-breakpoint-down("md") {
			background-position: top 20px center;
			padding: 100px 2.75em 80px 2.75em;
		}

		@include media-breakpoint-down("sm") {
			width: 90%;
		}

		/* To style the document scrollbar, remove `.custom-scrollbar` */
	}
	.alert---title {
		position: relative;
		// top right bottom left
		padding: 0.75em 2.5em 0.75em 0em !important;

		text-transform: none;
		font-weight: 600;
		width: auto;
		display: inline-block;
		margin: 0.5em;
		margin-top: 0;

		background: $gray-100;

		&:before {
			content: "";
			display: block;
			width: 50vw;
			height: 100%;
			background: $gray-100;
			display: block;
			left: -50vw;
			top: 0;
			position: absolute;
			z-index: -1; /* to be below the parent element but it doesn't work' */
		}
	}

	.alert---footer {
		position: absolute;
		min-height: 40px;
		text-align: center;
		bottom: 1em;
		left: 0;
		width: 100%;

		&.compact {
			display: none;
		}
	}

	.alert---content {
		position: relative;
		min-height: 40px;
		text-align: left;
		padding-top: 1em;
		padding-bottom: 1em;
		margin-bottom: 3em;

		&.compact {
			margin-bottom: 0;
		}
	}

	.alert---close {
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		position: absolute;
		top: 0.5em;
		right: 0.5em;
		display: none;

		&.compact {
			display: block;
		}
		&:before {
			width: 100%;
			height: 100%;
			display: block;
			content: "×";
			color: $dark;
			position: absolute;
		}

		&:hover {
			&::before {
				@include transition(all 0.3s ease);
				color: $gray-500;
			}
		}
	}

	//end modal
}
