.main-content {
	position: relative;
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	height: auto;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
	width: 100%;
	min-height: 100vh;
	transition: all 0.3s;
	position: relative;
	top: 0;
	right: 0;

	padding-top: $grid-gutter-width / 2 !important;

	.utils--messages {
		margin-top: 10px;
	}
}

.filters---submit {
	position: fixed;
	top: 28%;

	z-index: 10;
	padding: 1em;
	font-weight: 200;
	padding-right: 46px;
	left: 25%;
	// left:-300px;
	display: none;
	// top :50% ;
	// right : 0 ;
	//  @include translate ( 100%, -50% ) ;

	background-image: $btn-arrow-right-white;
	background-size: 20px 20px;
	background-position: 90% center;
	background-repeat: no-repeat;

	@include transition(all 0.3s ease);

	&:hover {
		@include transition(all 0.3s ease);
		background-color: $green !important ;
	}

	&.active {
		@include translate(100%, -50%);
		display: block;
	}
}

#dismiss {
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	background: $white;
	position: absolute;
	top: 8px;
	right: 12px;
	cursor: pointer;

	background-image: $btn-arrow-left;
	background-size: 20px 20px;
	background-position: 42.5% center;
	background-repeat: no-repeat;

	@include transition(background-color 0.3s ease);

	&:hover {
		@include transition(background-color 0.3s ease);
		background-color: $green;
		background-image: $btn-arrow-left-white;
		border: 0;
	}

	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

#filters {
	width: 90px;
	height: 45px;
	line-height: 35px;
	text-align: center;
	background: $secundary;
	position: absolute;
	top: 24px;
	left: -45px;
	cursor: pointer;
	z-index: 100;
	margin-left: -90px;

	background-image: $icon-filter;
	background-size: 20px 20px;
	background-position: 80% center;
	background-repeat: no-repeat;

	@include transition(background-color 0.3s ease);
	@include transition(margin-left 0.3s ease);
	display: none;

	&.sticky {
		position: fixed;
		top: 140px;
		z-index: 100;
	}

	&.active {
		margin-left: 0px;
		@include transition(margin-left 0.3s ease);

		&:hover {
			@include transition(background-color 0.3s ease);
			@include transition(margin-left 0.3s ease);

			background-color: $green;
			border: 0;
			margin-left: 45px;
		}
	}

	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.overlay {
	display: none;
	position: absolute;
	width: 0;
	height: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: -1;
	opacity: 0;
	top: 0;
	left: 0;
	transition: all 0.5s ease-in-out;
}
.overlay.active {
	display: block;
	opacity: 100;
	width: 100%;
	height: 100%;
	z-index: 10;
}

// sidebar

.sidebar {
	nav {
		overflow: hidden;

		height: auto;
		display: block;
		position: relative;

		ul {
			width: 100%;
			margin: 0 auto;

			> li {
				border-bottom: 1px solid $gray-500;

				&.bt-1:first-child {
					border-top: 1px solid $gray-500;
				}

				h5 {
					padding: 0.75em;
					color: $primary;
					margin: 0;
					font-weight: bold;
					position: relative;
					font-size: $font-small;
					letter-spacing: 0.75px;
					text-transform: uppercase;
				}

				table td a {
					border-color: $gray-100;
					padding: 0.25em;
					font-weight: normal;
					color: $secundary;
				}

				table td.icon {
					padding-left: 40px;
					background-image: none;
					background-size: 18px;
					background-repeat: no-repeat;
					background-position: left 10px center;

					&.icon--male {
						background-image: $icon-user-male;
					}
					&.icon--female {
						background-image: $icon-user-female;
					}

					&.icon--phone {
						background-image: $icon-phone;
						background-size: 20px;
					}
					&.icon--hotel {
						background-image: $icon-hotel;
						background-size: 24px;
					}
					&.icon--mail {
						background-image: $icon-mail;
					}
					&.icon--address {
						background-image: $icon-globe-address;
						background-size: 22px;
						background-position: left 8px center;
					}
					&.icon--company {
						background-image: $icon-company;
						background-size: 20px;
					}
					&.icon--check {
						background-image: $icon-check;
						background-size: 8px;
						background-position: left 14px center;
					}

					&.icon--ro {
						background-image: $icon-gray-ro;
						background-size: 24px;
						background-position: left 8px center;
					}
					&.icon--bb {
						background-image: $icon-gray-bb;
						background-size: 24px;
						background-position: left 8px center;
					}
					&.icon--hb {
						background-image: $icon-gray-hb;
						background-size: 24px;
						background-position: left 8px center;
					}
					&.icon--fb {
						background-image: $icon-gray-fb;
						background-size: 24px;
						background-position: left 8px center;
					}
					&.icon--ai {
						background-image: $icon-gray-ai;
						background-size: 24px;
						background-position: left 8px center;
					}
				}

				> .inner {
					//
					width: 100%;
					padding: 0.75em;
					padding-bottom: 0;

					&.border-top {
						border-top: 1px solid $gray-500;
					}

					.header-data {
						position: relative;
						height: auto;
						display: block;
						margin: -0.75em;
						margin-bottom: 0.75em;
						background-color: $dark;

						figure {
							//background-color: $yellow;
							width: 100%;
							padding-top: 45.25%; /* 16:9 Aspect Ratio */
							position: relative; /* If you want text inside of it */
							overflow: hidden;
							margin: 0;
							@extend %no-image;
							background-size: 100%;
							z-index: 2;

							> img {
								position: absolute;
								vertical-align: middle;
								height: 100%;
								width: 100%;
								object-fit: cover;
								border: 0;
								z-index: 5;

								&:empty {
									top: 50%;
									left: 50%;
									-webkit-transform: translate(-50%, -50%);
									-moz-transform: translate(-50%, -50%);
									-ms-transform: translate(-50%, -50%);
									-o-transform: translate(-50%, -50%);
									transform: translate(-50%, -50%);
								}
							}

							&.no-image {
								img {
									display: none !important;
								}
							}
						}

						h6 {
							text-align: center;
							background: rgba($dark, 0.75);
							color: $white;
							padding-top: 0.5em;
							padding-bottom: 0.5em;
							margin: 0;
							position: absolute;
							bottom: 0px;
							left: 0;
							text-align: left;
							padding: 0.75em;
							width: auto;
							display: inline-block;
							width: 100%;
							font-weight: normal;
							font-size: 1em;
							letter-spacing: 1px;
							z-index: 10;
						}

						// end header
					}

					.line-data {
						border-bottom: 1px solid $gray-100;
						padding: 0.75em;
						overflow: hidden;
						white-space: nowrap;
						position: relative;
						&:last-child {
							border: 0;
						}
					}

					p.line-data {
						margin-bottom: 0;
					}
				}

				> .banner {
					width: 100%;
					padding: 1.3em 4em 1.3em 4em;
					background-color: $white;
					position: relative;

					&.widget {
						height: 150px;
						position: relative;

						a { 

							
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top:0;
							left:0;
							text-align: center;

							img { 
								
								width: 50%; 
								position: absolute;
								top:50%;
								left:50%;
								@include translate(-50%, -50%);

							
							}

						}

						@include media-breakpoint-down("md") {
							padding-left: 5em;
							padding-right: 5em;
							height: 140px;
						}
					}
				}

				/*
        &.active {
          a {
            color: $secundary;
          }
        }
        */

				a {
					display: block;
					width: 100%;
					padding: 0.75em;
					position: relative;
					color: $complementary;
					font-family: $font-family-base;
					font-weight: bold;

					&:after {
						content: "";
						display: none !important;
					}
					// .btn-plus, 
					.icon-rxxl--container {
						position: absolute;
						right: 10px;
					//	top: calc(50% - 10px);
					}

					.btn-plus { 

						position: absolute;
						right: 10px;
						top: calc(50% - 10px);
					 }

					&.btn-dark {
						$color: $white !important;
						font-weight: 100;
					}
				}
			}

			.border-top {
				border-top: 1px solid $gray-500;
			}
			.border-top-null {
				border-top: 0;
			}
		}

		.component---booking-summary {
			@extend %no-drop-shadow;
		}
	}

	&--aditional-information {
		a.dropdown-toggle {
			position: relative;
			font-weight: normal;

			padding-left: 38px;
			&.cancellation {
				padding-left: 44px;
			}

			&.disabled {
				pointer-events: none;
			}

			// icons
			i {
				width: 25px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 7.5px;

				&.icon-rxxl---refundable {
					width: 30px;
				}
			}

			small {
				display: block;
				width: 100%;
				font-size: 1em;
				position: relative;
				//margin-left: 6px;

				&:before {
					content: "";
					display: block;
					width: 75%;
					height: 1px;
					background-color: $gray-100;
					top: -0.5px;
					left: -6px;
					position: absolute;
				}

				&:after {
					content: "";
					display: block;
					width: 1px;
					height: 200%;
					background-color: $gray-100;
					top: 0;
					left: -6px;
					position: absolute;
					@include translate(0, -50%);
				}
			}

			/*
			&.red {
			//	color: $red !important;
			}
			&.green {
			//	color: $green !important;
			}
			&.yellow {
			//	color: $yellow !important;
			}
			&.blue {
			//	color: $blue !important;
			}
			*/

			&.step {
				color: $dark !important;
				padding-left: 0.75em !important;
			}

			/*
      span.bubble {

      
      
        text-align: center ;
        color: $white ;
        width: 26px;
        height: 26px; 
        display: inline-block;
        font-weight: 100 ;
        position:relative ;
        background: transparent ;
        z-index: 2;
        margin-left: 0.25em; 
        font-size: small;
   

        &:before {

          content: "";
          position: absolute ;
          width : 24px;
          height: 24px;
          top:40%;
          left:50%;
          @include translate(-50%,-50%) ;
          background-color: $green ; 
          z-index: -1;

          display: block;
          border-radius: 100%;

 
        }

      }
      */
		}
	}
	//end sidebar
}

.apply-changes {
	z-index: 60;
	width: 100%;
	height: auto;
	background-color: $yellow;

	//padding: 0.75em;
	text-align: center;
	white-space: nowrap;
	position: sticky;
	min-height: 2em;
	background-color: $yellow;
	overflow: hidden;
	background-image: $btn-arrow-right;
	background-size: 10px 10px;
	background-position: center right 0.75em;
	background-repeat: no-repeat;
	cursor: pointer;
	@include transition(all 0.3s ease);
	display: block;
	color: $yellow;

	&.active {
		display: block;
		position: sticky;
		top: 90px;

		position: relative; /* I am the fallback */

		/* Give it everything you got! (use an auto prefixer...) */
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;

		top: 0; /* Required  */
	}

	&:before {
		position: absolute;
		display: block;
		@include transition(all 0.3s ease);
		height: 100%;
		line-height: 4em;
		content: attr(data-text);
		padding-left: 0.75em;
		padding-right: 0.75em;
		overflow: hidden;
		width: 210px;
		background-color: $dark;
		color: $white;
		right: 2em;
		top: 0;
		width: 100%;
	}

	&:hover {
		@include transition(all 0.3s ease);
	}
}

.filter-hd {
	padding-left: 46px !important;
}

.filter-icon {
	width: 24px;
	@include media-breakpoint-down("xs") {
		width: 24px;
	}
	height: 24px;
	position: absolute;
	margin: 0px;
	display: block;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	top: 50%;
	left: $grid-gutter-width/2;
	@include translate(0, -55%);
}

.filter-icon span {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	border-radius: 0px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

.filter-icon span {
	background: $green;
}

.filter-icon span {
	border-radius: 0px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	position: relative;
}
///--- settings

.filter-icon.settings span {
	&:before {
		content: "";
		position: absolute;
		width: 2px;
		height: 6px;
		background: $green;
		top: -2px;
	}
}

.filter-icon.settings span:nth-child(1) {
	top: 6px;
	width: 100%;

	&:before {
		left: 10%;
	}
}

.filter-icon.settings span:nth-child(2) {
	top: 10px;
	opacity: 1;
	width: 100%;

	&:before {
		left: 80%;
	}
}

.filter-icon.settings span:nth-child(3) {
	top: 14px;
	width: 100%;

	&:before {
		left: 50%;
	}
}

.border-top-fix-sm {
	@include media-breakpoint-down("sm") {
		border-top: 1px solid $gray-500;
	}
}
