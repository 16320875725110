// variables

$pt-icon-css-path: "../assets/components/payment-types" !default;
$pt-icon-rect-path: "" !default;
$pt-icon-square-path: "" !default;

// base

.pt-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.pt-icon {
  @extend .pt-icon-background;
  position: relative;

  display: inline-block;
  width: (4 / 3) * 2em;
  line-height: 2em;

  &---big {

    width: (4 / 3) * 2.5em;
    line-height: 2.5em;

  }

  &:before {
    content: "\00a0";
  }
  &.pt-icon-squared {
    width: 2em;
  }
}

@mixin pt-icon($svg) {
  .pt-icon-#{$svg} {
    background-image: url(#{$pt-icon-css-path}#{$pt-icon-rect-path}/#{$svg}.svg);
    &.pt-icon-squared {
      background-image: url(#{$pt-icon-css-path}#{$pt-icon-square-path}/#{$svg}.svg);
    }
  }
}



@mixin pt-icon-class($svg, $class ) {
  .pt-icon-#{$class} {
    background-image: url(#{$pt-icon-css-path}#{$pt-icon-rect-path}/#{$svg}.svg);
    &.pt-icon-squared {
      background-image: url(#{$pt-icon-css-path}#{$pt-icon-square-path}/#{$svg}.svg);
    }
  }
}

// list

@if variable-exists(include) {
  @each $svg in $include {
    @include pt-icon($svg);
  }
} @else {
  @include pt-icon(amex);
  @include pt-icon(banc);
  @include pt-icon(dins);
  @include pt-icon-class(dins, diners);
  @include pt-icon(idea);
  @include pt-icon(maes);
  @include pt-icon-class(maes, maestro);
  @include pt-icon(mscd);
  @include pt-icon-class(mscd, mastercard);
  @include pt-icon(payp);
  @include pt-icon(disb);
  @include pt-icon-class(disv, discover);
  @include pt-icon(jcbc);
  @include pt-icon-class(jcbc, jcb);
  @include pt-icon(payt);
  @include pt-icon(poli);
  @include pt-icon(sofb);
  @include pt-icon(sofo);
  @include pt-icon(trus);
  @include pt-icon(mir);
  @include pt-icon(visa);
  @include pt-icon(unip); 
  @include pt-icon(uatp);
  @include pt-icon(instapayment);
  @include pt-icon(dankort); 
  @include pt-icon-class(unip, unionPay);
  @include pt-icon(bcmc);
  @include pt-icon(blik);
  @include pt-icon(dotpay);
  @include pt-icon(ebanking_FI);
  @include pt-icon(eps);
  @include pt-icon(giropay);
  @include pt-icon(ideal);
  @include pt-icon(trustly);
  @include pt-icon(twint);
  @include pt-icon(vipps);
  @include pt-icon(card);
}