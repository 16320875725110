// manage background opacity color

@mixin bg-opacity($color, $opacity) {
	background-color: $color; // default
	background-color: rgba($color, $opacity);
}

// background-image: -webkit-linear-gradient(21deg,#2ed77d,#03cc5e);
// background-image: -moz-linear-gradient(21deg,#2ed77d,#03cc5e);
// background-image: -o-linear-gradient(21deg,#2ed77d,#03cc5e);
// background-image: linear-gradient(69deg,#2ed77d,#03cc5e);

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(
					#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
						((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
				);
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

/*
@include media-breakpoint-up(xs) { ... }
@include media-breakpoint-up(sm) { ... }
@include media-breakpoint-up(md) { ... }
@include media-breakpoint-up(lg) { ... }
@include media-breakpoint-up(xl) { ... }
*/

@mixin render-into($screen-size) {
	@if ($screen-size == "xs") {
		@include media-breakpoint-down(sm) {
			@content;
		}
	}

	@if ($screen-size == "sm") {
		@include media-breakpoint-up(sm) {
			@content;
		}
	}

	@if ($screen-size == "md") {
		@include media-breakpoint-up(md) {
			@content;
		}
	}
	@if ($screen-size == "lg") {
		@include media-breakpoint-up(lg) {
			@content;
		}
	}
	@if ($screen-size == "xl") {
		@include media-breakpoint-up(xl) {
			@content;
		}
	}

	@if ($screen-size == "xxl") {
		@include media-breakpoint-up(xxl) {
			@content;
		}
	}

	@if ($screen-size == "2k") {
		@include media-breakpoint-up(2k) {
			@content;
		}
	}
}

// childs
@mixin setNumericPorpertyToChilds($init, $gap, $start, $end, $property, $measure) {
	@for $i from $start through $end {
		&:nth-child(#{$i}) {
			$init: $init + $gap;
			#{$property}: #{$init}#{$measure};
		}
	}
}

//-- Animation delay items

@mixin stallTiming($hold, $start, $end, $gap) {
	$delay: $hold;
	@for $i from $start through $end {
		$delay: $delay + $gap;

		&:nth-child(#{$i}) {
			animation-delay: #{$delay}ms;
		}
	}
}

//  @include stallTiming(200, 1, 4, 500);

// Set a rem font size with pixel fallback

@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}

@mixin set-font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

//  OUTPUT :
//  font-size: 14px; //Will be overridden if browser supports rem
//  font-size: 0.8rem;

// SVG background images with PNG and retina fallback

$image-path: "../img" !default;
$fallback-extension: "png" !default;
$retina-suffix: "@2x";

@mixin background-image($name, $size: false) {
	background-image: url(#{$image-path}/#{$name}.svg);
	@if ($size) {
		background-size: $size;
	}
	.no-svg & {
		background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

		@media only screen and (-moz-min-device-pixel-ratio: 1.5),
			only screen and (-o-min-device-pixel-ratio: 3/2),
			only screen and (-webkit-min-device-pixel-ratio: 1.5),
			only screen and (min-device-pixel-ratio: 1.5) {
			background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
		}
	}
}

/*
  USAGE:
  @include background-image('pattern');
*/

// Animations and keyframes

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

/*
USAGE:

@include keyframes(slide-down) {
  0% { opacity: 1; }
  90% { opacity: 0; }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  @include animation('slide-down 5s 3');
}


*/

// Transitions

@mixin transition($args...) {
	will-change: transform;
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

/*

USAGE:

a {
  color: gray;
  @include transition(color .3s ease);
  &:hover {
    color: black;
  }
}

*/

// Cross browser opacity

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

/*

USAGE:

.faded-text {
  @include opacity(0.8);
}

*/

// Visually hide an element

%visuallyhidden {
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

/*
USAGE:

<button class="mobile-navigation-trigger">
  <b class="visually-hidden">Open the navigation</b>
  <img src="img/mobile-navigation-icon.svg">
</button>


.visually-hidden {
  @extend %visuallyhidden;
}

  
*/

// Transform

// generic transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}
// rotate
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
	moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}


@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}


/*
USAGE :

.test {
@include skew(25,10);
@include transform-origin(top left);
position: absolute;
top: 25%;
bottom: 25%;
left: 25%;
right: 25%;
background-color: rgba(20,20,20,.5);
}
*/

// pacleholder

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}

@mixin unselectable() {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}
