// init Hotel

.hotel {
	margin: 0;
	padding: 0;

	.relative {
		position: relative;
	}


	.section {
		min-height: 15vh;
		&.set-min-height {
			min-height: 50vh;
		}
		&:nth-child(1) {
			padding-top: $grid-gutter-width / 2;
			padding-bottom: $grid-gutter-width / 2;
		}
	}

	// lists
	.list-group-flush {
		padding-bottom: $grid-gutter-width / 2 !important ;
	}

	.list-group-item {
		background: none !important;
		border-color: $gray-100;
		color: $dark;
		border-top: 0;

		strong {
			text-transform: uppercase;
			color: $green;
		}

		position: relative;

		.utils--category {
			font-size: 1em;
		}

		&.icon {
			padding-left: 34px;

			&:before {
				display: inline-block;
				width: 30px;
				height: 100%;
				content: " ";
				background: $yellow;
				position: absolute;
				top: 0;
				left: 0;

				background-size: contain;
				background-position: 50%;
				background-repeat: no-repeat;
				background-color: transparent;
				background-image: $icon-check;
				background-size: 50%;
			}
			&.info:before {
				background-image: $icon-info;
				background-size: 75%;
			}

			&.category:before {
				background-image: $icon-category;
				background-size: 100%;
			}

			&.adress:before {
				background-image: $icon-adress;
				background-size: 100%;
			}

			&.timecalendar:before {
				background-image: $icon-calendar-time;
				background-size: 80%;
			}

			&.agelimit:before {
				background-image: $icon-agelimit-frame;
				background-size: 100%;
				content: attr(data-limit);
				font-size: 10px;
				color: $secundary;
				text-align: center;
				line-height: 48px;
			}
			&.petpolicy:before {
				background-image: $icon-pet;
				background-size: 80%;
			}

			&.policy:before {
				background-image: $icon-paperclip;
				background-size: 80%;
			}

			&.document:before {
				background-image: $icon-document-blue;
				background-size: 80%;
			}

			&.hotel-name:before {
				//background-image: url(#{$icon-css-path}/icon-hotel.svg);
				background-image: $icon-hotel;
				background-size: 100%;
			}

			&.payment:before {
				background-image: $icon-wallet;
				background-size: 100%;
			}
		}

		&:last-child {
			border-bottom: 1px solid $gray-100 !important;
		}
	}

	&--section---header {
		margin: 0;
		padding: 0;
		background: $secundary;
		padding-top: 0.5em;
		padding-bottom: 0.5em;

		.container {
			position: relative;
		}

		h2 {
			color: $white;
			text-transform: none;
			display: inline-block;
			width: auto;
			margin: 0;
			padding: 0;

			@include media-breakpoint-down("sm") {

				padding-top: 0.25em;
				padding-bottom: 0.25em;
	
			}
			
		}
	}

	&--map---container {
		width: 100%;
		padding-top: 35%;

		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: $green;
	}

	.link-to {
		background-color: $yellow;
		color: $dark;
		padding: 1em;
		display: block;

		position: absolute;
		bottom: 10px;
		right: 10px;

		text-transform: uppercase;
		z-index: 2;
		font-size: 0.75em;
	}

	&---subtitle {
		position: relative;
		// top right bottom left
		padding: 0.75em 2.5em 0.75em 0em !important;

		text-transform: none;
		font-weight: 600;
		width: auto;
		display: inline-block;
		margin: 0.5em;
		margin-top: 0;

		background: $gray-100;

		&:before {
			content: "";
			display: block;
			width: 50vw;
			height: 100%;
			background: $gray-100;
			display: block;
			left: -50vw;
			top: 0;
			position: absolute;
			z-index: -1; /* to be below the parent element but it doesn't work' */
		}
	}

	$angle: 120px / 2.1;

	$angle-small: 60px / 2.1;

	&--best-offer {
		&---banner-small {
			position: fixed;
			z-index: 101;

			bottom: 60px;
			right: 0px;

			display: none;
			width: 280px;
			height: 60px;
			padding-left: 15px;
			background-color: $yellow !important;

			@include translate(95%, 0);

			@include media-breakpoint-down("md") {
				display: block;
			}

			&.active {
				display: block !important;
			}

			a {
				width: 100%;
				height: 100%;
				line-height: 50px;
				margin: 0;
				padding: 0;

				background-color: transparent !important;

				&:hover {
					background-color: transparent !important;
					color: $dark !important;
				}

				table {
					height: 100%;
					width: 80%;
					vertical-align: middle;
				}

				small {
					position: absolute;
					bottom: 0;
					right: 60px;
					display: block;
					height: 20px;
					color: $dark;
					text-transform: uppercase;
					line-height: 20px;
					font-size: 0.625em !important;
				}

				@include media-breakpoint-down("sm") {
					width: 100% !important ;
					margin: 0 !important ;
				}
			}

			&:after {
				content: "";
				display: block;
				width: 0;
				height: 0;
				background: transparent;
				position: absolute;
				z-index: -1;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 $angle-small 60px 0;
				border-color: transparent $yellow transparent transparent;

				top: 0;
				left: $angle-small * -1;
			}

			&:hover,
			&:focus {
				@include transition(all 0.3s ease);
				@include translate(0, 0);
			}

			.price-container {
				span {
					display: inline-block !important;
					width: auto !important;
					font-weight: 900 !important;
				}

				//span { display: inline-block; width:auto ; }
				span.price {
					font-family: $font-family-numbers;
					font-size: 1.5em;
				}
				span.currency {
					font-size: 1.55em;
					margin-right: 2px;
				}
			}

			.info-container {
				font-weight: 900 !important;
				font-size: 1.5em;
				vertical-align: middle;
				color: $green;
			}
		}

		&---banner {
			position: fixed;
			z-index: 101;

			top: 60px;
			right: 0px;

			min-width: 100%;
			max-width: 100%;
			height: 120px;
			overflow: hidden;
			pointer-events: none;
			display: none;

			color: $white;

			// background-color: rgba( $green, .75) ;

			padding-left: $angle;

			.banner--background {
				@extend %gradient-logo;

				position: absolute;
				width: 100%;
				height: 100%;
				display: block;
				z-index: -1;
				left: 52%;

				@include opacity(0.9);

				color: $white;

				&:after {
					content: "";
					display: block;
					width: 0;
					height: 0;
					background: transparent;
					position: absolute;
					z-index: -1;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 $angle 120px 0;
					border-color: transparent $gadient-color-left transparent transparent;

					top: 0;
					left: $angle * -1;
				}
			}

			.container {
				height: 100%;
			}

			&.active {
				display: block;
			}

			@include media-breakpoint-down("md") {
				display: none !important;
			}

			// end banner
		}

		&---body {
			height: 100%;

			> .row {
				height: 100%;

				div > .row {
					display: table;
					height: 50%;
					width: 100%;

					> div {
						vertical-align: middle;
						position: relative;
						display: table-cell;
						width: 25%;

						strong,
						span {
							display: block;
							width: 100%;
							font-weight: 100;
						}

						strong {
							position: relative;
							font-weight: 400;
							letter-spacing: 1px;
						}
					}
				}

				.labels span {
					text-align: right;
					line-height: 1.25;
					text-transform: uppercase;
					font-size: 0.765em;
					letter-spacing: 1px;
				}

				.dates:before {
					content: "";
					width: 1px;
					height: 80%;
					background-color: $white;
					top: 10%;
					right: 0px;
					margin-right: -0.5px;
					position: absolute;
					display: block;
				}

				.best-deal--container {
					position: relative;
					padding: 0 !important ;

					p {
						display: block;
						width: auto;
						text-align: center;
						position: absolute;
						top: 50%;
						left: 50%;

						font-size: 1.15em;
						letter-spacing: 1px;
						font-weight: 900;
						text-transform: uppercase;
						white-space: nowrap;

						border-bottom: 4px solid $white;

						&.null {
							border-bottom: 0;
						}

						&:before {
							background-size: contain;
							background-position: 50%;
							background-repeat: no-repeat;
							background-color: transparent;
							width: 100%;
							height: 30px;
							top: 110%;
							position: absolute;
							display: block;
							content: "";
						}

						@include translate(-50%, -75%);

						&.null {
							@include translate(-50%, -50%);
						}

						&.trivago {
							&:before {
								background-image: url(../../../../storage/images/traking/trivago-white.svg);
							}
						}
						&.google {
							&:before {
								background-image: url(../../../../storage/images/traking/google-white.svg);
							}
						}
						&.kayak {
							&:before {
								background-image: url(../../../../storage/images/traking/kayak-white.svg);
							}
						}
						&.tripadvisor {
							&:before {
								background-image: url(../../../../storage/images/traking/tripadvisor-white.svg);
							}
						}
						&.idealo {
							&:before {
								background-image: url(../../../../storage/images/traking/idealo-white.svg);
							}
						}
					}
				}

				/// end first rows
			}

			.best-deal-price {
				padding-left: 0.25em;

				span {
					display: inline-block !important;
					width: auto !important;
					font-weight: 900 !important;
				}

				//span { display: inline-block; width:auto ; }
				span.price {
					font-family: $font-family-numbers;
					font-size: 2.35em;
				}
				span.currency {
					font-size: 2.35em;
					margin-right: 4px;
				}
			}

			.best-deal-price {
				position: relative;
			}

			td {
				overflow: hidden;
				white-space: nowrap;
			}

			.utils--spinner {
				margin: 0 auto;

				> div {
					border: 0;
				}
			}

			a {
				background-color: $yellow;
				text-transform: uppercase;

				i,
				i:before,
				i::after {
					background-size: 15px 15px;
					width: 40px;
				}
			}

			// end body
		}

		// end best offer
	}

	.room-list {
		border-bottom: 8px solid $gray-600;

		margin-bottom: $grid-gutter-width !important;

		padding-bottom: $grid-gutter-width / 2 !important;

		&---footer {
			position: relative;
			margin-top: $grid-gutter-width / 2 !important;

			.btn {
				padding-left: 30px;
				padding-right: 40px;
				position: relative;

				&:after,
				&:before {
					content: "";
					width: 10px;
					height: 100%;
					background-color: $white;
					top: 50%;
					@include translate(0, -50%);
					margin-top: -0.5px;
					position: absolute;
					display: block;
				}

				&:after {
					border-right: 1px solid $gray-500;
					right: -11px;
				}

				&:before {
					left: -11px;
					border-left: 1px solid $gray-500;
				}

				background-repeat: no-repeat;
				background-size: 10px 10px;
				background-position: right 12px center;
				background-image: $btn-arrow-up-white;

				&.collapsed {
					background-image: $btn-arrow-down-white;
				}
			}

			&:after {
				content: "";
				width: 100%;
				height: 1px;
				background-color: $gray-500;
				top: 50%;
				margin-top: -0.5px;
				position: absolute;
				left: 0;
				z-index: -1;
			}
		}
	}

	.relative {
		position: relative !important;
	}

	.widget {
		background-color: $gray-100;
		padding: $grid-gutter-width;
		position: relative;


		img { border:0; margin:0 auto; padding:0; width:auto; height: 35px; margin-top:-20px; }

		&.mb-10 { margin-bottom: $grid-gutter-width / 2; }

		span.reviewed {
			position: absolute;
			font-size: small;
			color: $dark;
			bottom: 6px;
			left: $grid-gutter-width * 1.6;
			z-index: 2;

			@include media-breakpoint-down("xs") {
				
				left:0;
				bottom: 4px;
				width: 100%;
				text-align: center;
			
			}
		}
	}

	.trustpilot-widget {
		padding-top: 6px;
	
	}

	// end hotel
}

.hotel--section---header {
	a {
		position: absolute;
		top: 50%;
		width: 30px;
		height: 30px;
		right: $grid-gutter-width / 2;
		@include translate(0, -50%);

		i {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		display: none;

		@include media-breakpoint-down("sm") {
			display: block;
		}
	}
}

.hotel---anchor {

	border:0 ;
	height: 0;
	width: 100%;

}