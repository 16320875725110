.component---list-amenities {
	list-style: none;
	display: table;
	margin: 0 auto;
	text-align: center;

	padding: 0;
	margin-bottom: 0.25em;

	li {
		display: table-cell;
		vertical-align: middle;
		width: 40px;
		height: 30px;
		position: relative;
		margin-right: 4px;
		padding: 0.25em !important ;

		&:before {
			position: absolute;
			width: 1px;
			height: 80%;
			content: "";
			background-color: $gray-500;

			top: 50%;
			right: -0.5px;
			z-index: 1;
			@include translate(0, -50%);
		}

		&:last-child:before {
			display: none;
		}
		text-align: center;

		&:hover,
		&:focus {
			span.utils---tooltip {
				display: block;
			}
		}

		i {
			width: 18px;
			height: 18px;
		}
	}
}
