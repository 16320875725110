input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $gray-400 !important;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: $gray-400 !important;
  opacity: 1;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: $gray-400 !important;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: $gray-400 !important;
  opacity: 1;
}

#sorting {
  display: none;
}

input:-moz-read-only,
input:read-only,
input:disabled {
  background-color: $white !important;
  color: $dark !important;
}

[disabled] {
  background-color: $white !important;
  color: $dark !important;
}

// should not be here
.form-inline--bounderies {
  min-width: 250px;
  max-width: 250px;
  margin: 0 auto !important;
  z-index: 9999999;
}

.invalid-tooltip {
  top: -68px;

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    bottom: -16px;
    border: 8px solid;
    border-color: $red transparent transparent $red;
    @include opacity(0.75);
  }
}

.valid-tooltip {
  top: -68px;

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    bottom: -15px;
    border: 8px solid;
    border-color: $green transparent transparent $green;
    @include opacity(0.75);
  }
}

/* selects: */

select::-ms-expand {
  display: none;
}

select:focus::-ms-value {
  background-color: transparent;
}

select {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  // just in case
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block !important ;

  -webkit-padding-end: 1em;
  -moz-padding-end: 1em;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;

  background-color: $white;
  background-image: $btn-arrow-down-gray;
  background-position: right 10px center;
  background-size: 10px 10px;
  background-repeat: no-repeat;

  border: 0 !important ;
  border-radius: 0;
  @extend %no-drop-shadow;
  color: $dark;
  font-size: 1em;
  padding-left: 0.75em !important;
  //font-weight: 200 ;
  vertical-align: middle;
  padding-top: 25px;
  //	color:red !important;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-indent: 0.01px;
  text-overflow: "";
  width: 100%;
  //border:1px solid blue !important;
  padding-top: 0.75em !important; /* fix for html2canvas */
  cursor: pointer;

  &:focus {
    border: 0 !important ;
    @extend %no-drop-shadow;
    outline: none !important;
  }

  // options
  *,
  option {
    border: 0 !important ;
    border-radius: 15px;
    background-color: $white;
    padding: 0.5em !important;

    &:hover {
      background-color: $green;
    }
  }
}

.form {
  // container form navbar
  &--nav---container {
    @include bg-opacity($white, 1);
    height: auto !important ;
    padding: 1rem;
    z-index: -1;
    form {
      margin-top: -200%;
      @include opacity(0);
      @include transition(all 0.3s ease);
    }
  }

  // form-controls--container old css

  &--container {
    // mantein all inside
    position: relative;
    //font-weight: 200 ;

    fieldset,
    .form-control-xxl {
      border: 2px solid $gray-500;
      position: relative;
      margin-bottom: 8px !important;
      z-index: 1;
      padding: 0 !important;
      min-height: 50px;
    }

    label {
      position: relative;
      font-weight: 500;
      color: $complementary;
      z-index: 2;
      margin: 0;
      // font-weight: 200 ;
    }

    // end container
  }
  // Input-texts

  &--fieldset---input--search {
    border: 2px solid $gray-500;

    input[type="text"] {
      height: 45px;
      width: calc(100% -30px);
      //font-weight: 200 ;
    }

    button {
      width: 32px;
      height: calc(100% + 4px);
      display: inline-block;
      position: absolute;
      top: -2px;
      right: -2px;
      background-color: $dark;

      background-image: $btn-arrow-right-white;
      background-size: 10px 10px;
      background-position: center center;
      background-repeat: no-repeat;

      border: 0;

      @include transition(all 0.3s ease);

      &:hover {
        @include transition(all 0.3s ease);
        background-color: $yellow;
        background-image: $btn-arrow-right;
        border: 0;
      }
    }
  }
  // checkboxes

  //hide checkbox & radios
  &--fieldset---checkbox,
  &--fieldset---radio {
    input[type="checkbox"],
    input[type="checkbox"] {
      visibility: hidden;
    }
  }

  &--fieldset---checkbox {
    // overrides the stars line

    .component--category {
      position: absolute;
      left: 2em;
      @include translate(0%, 50%);
      @include media-breakpoint-down("xs") {
        @include translate(0%, 50%);
      }
    }

    border: 0 !important;

    //	border:2px solid red !important;

    label {
      position: absolute;
      width: 100%;
      height: 100%;
      min-height: 50px;
      border: 0;
      border-bottom: 1px solid $gray-100;
      font-weight: 400;
      cursor: pointer;

      span {
        position: absolute;
        left: 0;
        top: 50%;
        padding-left: 2.75em;
        @include translate(0%, -50%);

        /* IE10+ specific styles go here */
        @media screen and (-ms-high-contrast: active),
          screen and (-ms-high-contrast: none) {
          top: 7px;
          padding-left: 4em;
        }

        @include media-breakpoint-down("xs") {
          @include translate(0%, -50%);
        }
      }

      @include only_ie10_above(
        "span",
        (
          top: 8px,
          padding-left: 4em,
        )
      );

      @include only_ie10_above(
        ".component--category",
        (
          top: 6px,
          margin-left: 12px,
        )
      );

      &.right {
        span {
          right: 0;
          left: auto;
          padding-left: 0;
          padding-right: 1.75em;
        }
      }

      // end label
    }

    i.ckeckbox {
      position: absolute;
      width: 1em;
      border: 2px solid $gray-500;
      height: 1em;
      display: block;
      z-index: 2;
      margin-left: 1em;
      @include translate(0, -50%);
      top: 25px;

      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    /// i tag must be after input
    input[type="checkbox"]:checked + i.ckeckbox {
      font-style: normal;
      background-image: $form-icon-valid;
    }

    &:last-child label {
      border-bottom: 0;
    }

    margin-bottom: 0 !important;
  }

  //--------------->

  // .form-control-xxl = theme structure

  &--fieldset---input {
    border: 2px solid $gray-500;
    position: relative;
    margin-bottom: 12px !important;

    z-index: 1;
    padding: 0 !important;
    min-height: 50px;
    //not all browser... yet...
    &.bl-0:before {
      background-color: $gray-500;
      display: none;
    }

    /*
		&:focus-within {
			@include transition(border-color 0.5s ease);
			border-color: rgba($dark, 0.5) !important ;

			&.bl-0:before {
				@include transition(background-color 0.5s ease);
				content: "";
				width: 2px;
				background-color: rgba($dark, 0.5) !important ;
				height: calc(100% + 4px);
				top: -2px;
				left: -2px;
				display: block;
				position: absolute;
				z-index: 100;
			}
		}
		*/

    .btn-popover {
      position: absolute;
      z-index: 100;
      background-color: $gray-400;
      border-radius: 100%;
      width: 20px;
      height: 20px;
      right: $grid-gutter-width / 4;
      top: 50%;
      margin-top: -10px;

      background-image: $icon-info-letter;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;

      &:hover {
        background-color: rgba($green, 0.75);
      }
    }

    label {
      position: absolute;
      font-weight: 500;
      color: $complementary;
      z-index: 10;
      background-color: $white;
      top: 0;
      left:0;
      @include transform(translate(0, -50%));
      margin: 0 !important;
      margin-left: 0.75em !important;
      padding-left: 0.75em;
      padding-right: 0.75em;
      cursor: pointer;
      white-space: nowrap;
    }

    &.checkbox {
      border: 0 !important;

      label {
        @include transform(translate(0, 0));
        width: 100%;
        height: 100%;
        min-height: 48px;
        text-align: left !important;
        font-weight: normal;
        margin-left: 0 !important;
        background-color: transparent !important ;
        cursor: pointer;
        white-space: nowrap;
        justify-content: left !important;
        display: flex;
        align-items: center;

        padding-left: 44px;

        a {
          padding-left: 4px;
          padding-right: 5px;
          color: $green;
          display: inline;
          white-space: normal;
          &:hover {
            color: $secundary;
          }
        }
      }

      &.white--space {
        label {
          white-space: normal !important;
          min-height: 50px !important;
          height: auto !important;
          position: relative;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        visibility: hidden;
        display: none;
      }

      input[type="checkbox"]:checked ~ label {
        &:before {
          //	background-size: 75%;
          //	background-size: 10px 10px;
          //	background-position: 50%;
          background-repeat: no-repeat;
          background-color: transparent;
          font-style: normal;
          //	background-image: $form-icon-valid;
          content: "\2713";
          color: $green;
          font-weight: 700;
          font-size: 90%;
          text-align: center;
          line-height: 1.15 !important;
        }
      }

      label:before {
        display: block;
        width: 20px;
        height: 20px;
        background-color: transparent;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        border: 2px solid $gray-500;
        left: 10px;
        content: "";
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    textarea {
      //position: absolute;
      width: 100%;
      bottom: 0;
      background: $white;
      padding: 0.75em;
      border: none;
      font-family: $font-family-sans-serif;
      color: $dark;
      line-height: 1;
      margin-top: 4px !important;
      //font-weight: 200 ;
    }

    select {
      width: 100% !important ;
      height: 100% !important ;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100% !important ;
      height: 48px !important ;
      position: relative;
    }

    textarea {
      padding-top: 1em;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 140px;
      min-height: 140px;
      max-height: 140px;

      position: relative;

      // removes the bottom right botton thing
      resize: none;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: $gray-100;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        box-shadow: inset 0 0 6px rgba($dark, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba($dark, 0.1);
        background-color: $gray-500;
      }
    }

    /*
    
    button,
    a.btn {
      width: 100%;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      background-color: $dark;
      border: 0;
      padding: 1em;
      font-size: $button-font-size;
      letter-spacing: 0.5px;
      white-space: nowrap;
      @include translate(0,0);
      background-image: $btn-arrow-right-white;
      background-size: 10px 10px;
      background-position: right 15px center;
      background-repeat: no-repeat;

      &.reverse {
        background-image: $btn-arrow-left-white;
        background-size: 10px 10px;
        background-position: left 15px center;
        background-repeat: no-repeat;
      }

      &.rounded {
        width: 2em;
        height: 2em;
        margin: 0 auto;
        border-radius: 100% !important;
        position: absolute;
        top: 50%;
        left: $grid-gutter-width;
        overflow: hidden;
        @include translate(0, -50%);
        background-color: transparent;
        opacity: 0.5;
        &:hover {
          background-color: transparent;
        }
      }

      &.help {
        background-image: $alert-help-dark;
        background-size: contain;
        background-position: center center;
      }

      @include transition(background-color 0.3s ease);

      &:hover {
        //  background-image: $btn-arrow-right-white;
        background-color: $yellow;
        color: $dark;
      }

      &.next {
        background-color: $yellow;
        color: $dark;
        background-image: $btn-arrow-right;

        &:hover {
          background-color: $green;
          color: $white;
        }
      }

      // end fielset input
    }

	*/

    &.null-margin {
      margin-bottom: 0 !important;
    }
  }

  // end form
}

/// old Forms Init needs to rename to the new system

form {
  width: 100%;

  .form-controls--container {
    width: 100%;
    position: relative;
    //font-weight: 200 ;

    // form-controls--container old css

    p,
    span {
      display: block;
      padding: 1em;
      background: $red;
      color: $white;
      margin-bottom: -0.5em;

      &:empty {
        display: none;
      }
    }

    .form-control-xxl {
      position: relative;
      margin-bottom: 8px !important;
      z-index: 1;
      padding: 0 !important;
      min-height: 50px;

      label {
        position: relative;
        font-weight: 500;
        color: $complementary;
        z-index: 2;
        background-color: $white;
        top: 0;
        @include transform(translate(0, 50%));
        margin: 0 !important;
        margin-left: 0.75em !important;
        padding-left: 0.75em;
        padding-right: 0.75em;
        display: table;
        width: auto;
      }

      input[type="text"],
      textarea {
        position: relative;
        width: 100%;
        bottom: 0;
        background: $white;
        padding: 0.75em;
        border: none;
        font-family: $font-family-sans-serif;
        color: $dark;
        line-height: 1;
        font-weight: normal;
        background-size: 20% 20% !important;
        background-position: center right !important;
        border: 2px solid $gray-500;
      }

      textarea {
        padding-top: 1em;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 140px;
        min-height: 140px;
        max-height: 140px;

        position: relative;

        // removes the bottom right botton thing
        resize: none;

        &::-webkit-scrollbar {
          width: 8px;
          background-color: $gray-100;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 1px;
          box-shadow: inset 0 0 6px rgba($dark, 0.1);
          -webkit-box-shadow: inset 0 0 6px rgba($dark, 0.1);
          background-color: $gray-500;
        }
      }

      // end input conatiner
    }

    input[type="submit"] {
      width: 100%;
      text-align: center;
      color: $white;
      padding: 1em;
      border: none;
      background-color: $yellow;
      text-transform: uppercase;
      color: $white;
      font-family: $font-family-titles;
      //font-weight : 300;
      letter-spacing: 1px;
      text-align: center;

      font-size: $button-font-size;
      position: relative;
      color: $dark;

      @include media-breakpoint-down("md") {
        top: 10px;
        right: 0px;
      }

      background-image: $btn-arrow-right;
      background-size: 20px 20px;
      background-position: 95% center;
      background-repeat: no-repeat;

      @include transition(all 0.3s ease);

      &:hover {
        @include transition(all 0.3s ease);
        background-color: $green;
        background-image: $btn-arrow-right-white;
      }
    }

    // end container
  }
}

.form-control-xxl.error-xxl {
  .error-message {
    padding-left: 1.5em;
  }
}

.form-control-errors {
  position: relative;

  margin-bottom: 1em !important;
  display: none;

  &.error-xxl {
    display: table;
  }

  div {
    display: none;
  }
  width: 100%;

  .error-message {
    background-color: rgba($red, 0.75);
    padding: 0;

    color: $white;
    display: table-cell;
    position: relative;
    top: 0;
    right: auto;
    left: 0;
    z-index: 100;
    font-size: 1em;
    @include translate(0, 0);
    width: 100%;
    height: 100%;

    // top / right / bottom / left
    padding: 1em 0.75em 1em 60px;

    background-image: $alert-danger;
    background-size: 30px 30px;
    background-position: 15px center;
    background-repeat: no-repeat;
  }
}

.invalid-tooltip {
  top: -68px;
  position: absolute;
  z-index: 9999;
  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    bottom: -16px;
    border: 8px solid;
    border-color: $red transparent transparent $red;
    @include opacity(0.75);
  }
}

.valid-tooltip {
  top: -68px;
  position: absolute;

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    bottom: -15px;
    border: 8px solid;
    border-color: $green transparent transparent $green;
    @include opacity(0.75);
  }
}

///-- forcing widths and position
/*
.dual--input {
  display: inline-block;
  padding-bottom: 54px !important ;

  &:before {
    content: "";

    display: block;
    width: 1px;
    height: 80%;
    background: $gray-500;
    position: absolute;
    top: 50%;
    left: 50%;

    @include translate(-50%, -50%);

    z-index: 2;
  }

  input,
  select {
    position: absolute;
    width: 50%;
    display: block;
    top: 0;

    &:nth-child(2) {
      left: 50%;
    }
  }

  input {
    text-align: left;
    height: 100%;
  }

  select {
    height: 54px;
  }

  background: $red !important;

  label {
    width: 100%;

    left: -0.75em;
    background: none;

    &:before {
      display: none;
    }

    span {
      position: absolute;
      background: $white;
      padding-left: 0.75em;
      padding-right: 0.75em;

      &:first-child {
        left: 0.75em;
      }
      &:last-child {
        left: calc(50% + 0.75em);
      }

      @include media-breakpoint-down("xs") {
        @include translate(0, -50%);
      }
    }
  }

  @include only_ie9(
    "label span",
    (
      top: -0.75em
    )
  );
}
*/

.form {
  &--hidden---container {
    position: absolute;
    z-index: 2;
    width: 100%;
    min-height: 100px;

    background: rgba($white, 1);
    border-bottom: 1px solid $gray-100;

    @extend %drop-shadow;

    display: none;

    &.active {
      display: block;
    }

    > .search-home-form-container {
      height: 100px;
      margin: 0;

      @extend %no-drop-shadow;

      border: 0;

      &:before {
        width: 100%;
        left: 0;

        -webkit-clip-path: polygon(0 0, 100% 100%, 0% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        display: none;
      }
    }
  }

  &--hotel-rooms {
    &---display-container {
      border-bottom: 4px solid $gray-100;
      padding-bottom: $grid-gutter-width / 2;
      padding-top: $grid-gutter-width / 2;
      margin-bottom: $grid-gutter-width / 2 !important;

      .icon-rxxl--container {
        width: 2em;
        line-height: 2em;
      }

      table {
        border-color: $gray-100;

        td {
          padding: 0.25em 0.75em;
          border-left: 1px solid $gray-100;
          &:first-child {
            border-left: 0;
          }
        }
      }
    }
  }

  &--hotel-rooms---display-container {
    fieldset {
      display: none;
    }

    &.active {
      td span {
        display: none;
      }
      fieldset {
        display: inherit;
      }

      .disabled {
        pointer-events: none !important;
      }

      .stay strong {
        display: none;
      }

      fieldset,
      .form-group {
        border-bottom: 4px solid $gray-500;
        margin: 0 !important ;
        padding: 0 !important ;
      }

      .buttons {
        border: 0;
        position: relative;
      }

      border: 2px solid $gray-500;

      input[type="text"] {
        height: 50px;
        width: 100%;
        //font-weight: 200 ;
        border: 0;
      }

      input[type="text"],
      select {
        padding-left: 1em;
      }

      button {
        width: auto;
        height: 50px;
        display: inline-block;
        position: relative;
        top: 0;
        right: 0;
        background-color: $dark;
        padding-right: 30px;
        padding-left: 0.75em;
        color: $white;
        background-image: $btn-arrow-right-white;
        background-size: 20px 20px;
        background-position: right 5px center;
        background-repeat: no-repeat;
        border: 0;

        @include transition(all 0.3s ease);

        &:hover {
          @include transition(all 0.3s ease);
          background-color: $yellow;
          border: 0;
        }
      }
    }
  }

  // end form
}

///----variables

$height-labels: 28px;
$height-inputs: 40px;

.form {
  &--elements---full {
    &.hide {
      display: none;
    }

    fieldset {
      border: 0;
      min-height: 70px;
      height: 70px;

      &:nth-child(1),
      &:nth-child(3) {
        border-right: 1px solid $gray-500;
      }

      @include media-breakpoint-down("md") {
        border-bottom: 1px solid $gray-500;

        &:nth-child(1) {
          border-right: 0;
        }

        &:last-child {
          border: 0;
        }
      }

      @include media-breakpoint-down("sm") {
        border-bottom: 1px solid $gray-500;

        &:nth-child(3) {
          border-right: 0;
        }

        &:last-child {
          border: 0;
        }
      }

      @include media-breakpoint-down("xs") {
        min-height: 61px;
        height: 61px;
      }
    }

    &.check-availabilities {
      fieldset {
        border: 0 !important ;
      }
    }

    input,
    select {
      height: 40px !important;
      border: 0 !important;
      min-height: 40px !important;
      max-height: 40px !important;
      height: 40px !important;
      line-height: 40px !important;
      width: 100% !important;
      padding: 0 !important;

      @include media-breakpoint-down("xs") {
        margin-bottom: 0;
        min-height: 30px !important;
        max-height: 30px !important;
        height: 30px !important;
        line-height: 30px;
      }
    }
    label {
      min-height: 28px !important;
      max-height: 28px !important;
      height: 28px !important;
      line-height: 28px;
      font-weight: bolder;
      border: 0 !important;
      cursor: pointer;
      justify-content: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-down("xs") {
        margin-bottom: 0;
        min-height: 22px !important;
        max-height: 22px !important;
        height: 22px !important;
        line-height: 22px;
      }
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;

      //text-transform: uppercase ;

      height: 50px !important;
      border: 0 !important;
      min-height: 50px !important;
      max-height: 50px !important;
      height: 50px !important;
      line-height: 50px !important;
      padding: 0 !important ;
      // font-family: $font-family-titles;
      letter-spacing: 1px;

      @include translate(-50%, -50%);

      background-image: $btn-arrow-right;
      background-size: 10px 10px;
      background-position: right 15px center;
      background-repeat: no-repeat;
      background-color: $yellow;

      @include transition(all 0.3s ease);

      &:hover {
        @include transition(all 0.3s ease);
        background-color: $green;
        background-image: $btn-arrow-right-white;
      }
    }
  }

  &--hide-fileds {
    height: 40px;
    margin-bottom: $grid-gutter-width;
    position: relative;

    &:after {
      content: "";
      position: absolute;

      background-color: $gray-500;
      width: calc(100% - 20px);
      height: 2px;
      color: $white;
      display: block;
      top: 50%;
      @include translate(0, -50%);
      left: $grid-gutter-width / 2;
      z-index: -1;
    }

    a {
      position: relative;
      font-weight: 600;
      color: rgba($green, 0.75);

      padding-left: 2.75em;
      background-color: $white;

      small {
        color: $gray-600;
        font-weight: normal;
        font-size: 1em;
      }

      &:visited {
        text-decoration: none;
        color: rgba($green, 0.75);
      }

      &:before {
        content: "";
        position: absolute;

        width: 30px;
        height: 30px;

        color: $white;
        display: block;
        border-radius: 100%;
        top: 2px;
        @include transition(all 0.3s ease);
        //@include transform(translateY(-50%) rotate(135deg));
        @include transform(rotate(135deg));
        left: 6px !important;
        //top:2px !important;
        //content: '\d7';
        font-weight: 400;
        font-size: 150%;
        text-align: center;
        white-space: nowrap;
        background-image: $icon-plus-white;
        background-size: 20px 20px;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: rgba($green, 0.75);
      }

      &.collapsed {
        &:before {
          @include transition(all 0.3s ease);
          //	@include transform(translateY(-50%) rotate(0deg));
          @include transform(rotate(0deg));
        }
      }
    }
  }
}

// end form

fieldset.autocomplete {
  position: relative !important;

  .utils--spinner {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .reset {
    right: 10px;
    bottom: 14px;
    background-color: $white;
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 5;

    cursor: pointer;

    display: none !important;

    &.active {
      display: block !important;
    }

    @include transform(scale(0.75));

    &:before,
    &:after {
      background-color: $gray-600;
      left: 50%;
      top: 50%;
      @include transform(translate(-50%, -50%) rotate(45deg));
    }

    &:hover {
      &:before,
      &:after {
        background-color: $red;
      }
    }
  }
}

.field-error-message {
  z-index: 10;
  background-color: rgba($red, 0.75);
  color: $white;
  width: auto;
  display: inline-block;
  top: -80px;
  right: 0;

  &:empty {
    display: none;
  }

  &::before {
    position: absolute;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    box-sizing: content-box;
    width: calc(100% + 4px);

    height: 4px;
    background: $red;
    display: block;
    content: "0000";
    opacity: 1;
  }
}

.invalid-feedback {
  z-index: 100;
  background-color: rgba($red, 0.75);
  color: $white;
  position: absolute;

  top: -50px;
  width: auto;
  padding: 0.75em;
  right: 0.75em;
  min-width: 4em;

  &:empty:before {
    content: attr(data-default);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0.5px;
    left: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: $red;
    border-bottom: 0;
    border-left: 0;
    margin-left: -10px;
    margin-bottom: -15px;
    opacity: 0.75;
  }
}

.was-validated {
  input:invalid ~ label,
  select:invalid ~ label {
    &:after {
      display: block;
      width: calc(100% - 20px);
      height: 2px;
      background-color: rgba($red, 0.75);
      position: absolute;
      bottom: 0%;
      left: 10px;
      content: "";
    }

    &:before {
      border-color: rgba($red, 0.75);
    }
  }
}

.was-validated {
  input:focus ~ .invalid-feedback,
  select:focus ~ .invalid-feedback {
    display: none !important;
  }
}

.form-control ~ .pt-icon {
  position: absolute;
  top: 50%;
  right: $grid-gutter-width / 2;
  @include translate(0, -50%);
}

// sidebar form empty field position
.inner form .reset {
  top: 50%;
  right: 4px;
  @include transform(translate(0, -50%) scale(0.75));
}

// Home form empty field position

.form---search--container .utils--spinner {
  bottom: $grid-gutter-width / 3 !important;

  @include media-breakpoint-down("sm") {
    bottom: -6px !important;
  }
}

.btn-contact {
  color: $white !important;
  font-weight: normal !important;
}

.js-states {
  .utils--spinner {
    position: absolute;
    z-index: 20;
    top: 50%;
    @include transform(translate(0, -25%));
  }
}

.bootstrap-select {
  > .dropdown-toggle {
    &:after {
      background-image: $btn-arrow-down-gray;
      background-position: center top 5px !important;
      background-size: 10px 10px !important;
      background-repeat: no-repeat !important;
      border: 0 !important;
      width: 10px !important;
      height: 20px !important;
    }
  }
}

.btn-input {
  z-index: 2;
  @extend %vertical-top-middle;
  right: 0.688rem;
  top: 57.5% !important;
}

.btn-display-alerts {
  position: absolute;
  top: -40px;
  right: 46px;
}

.form-login {
  // label { position: relative !important; }

  input[type="email"],
  input[type="password"],
  input[type="text"] {
    width: 100% !important;
    height: 100% !important;
  }

  input[type="password"] {
    font-size: 162%;
  }
  input[type="password"],
  input[name="password"],
  input[name="password_confirm"] {
    background: none !important;
  }

  &.was-validated {
    input {
      &:valid ~ .icon-rxxl---eye {
        background-image: $icon-eye-valid;
      }
      &:invalid ~ .icon-rxxl---eye {
        background-image: $icon-eye-invalid;
      }

      &:valid ~ .icon-rxxl---eye-close {
        background-image: $icon-eye-close-valid;
      }
      &:invalid ~ .icon-rxxl---eye-close {
        background-image: $icon-eye-close-invalid;
      }
    }
  }

  .icon-rxxl--container {
    position: absolute !important;
    cursor: pointer;
  }

  .form--fieldset---checkbox {
    position: relative !important;

    i.ckeckbox {
      @extend %vertical-top-middle;
      margin-left: 0;
    }

    label {
      border: 0 !important;
      span {
        padding-left: 1.75em;
      }
    }
  }
}

.dropdown-menu {
  small {
    &.quote {
      display: block;
    }
  }
}

@import "forms/search";
