.autocomplete-suggestions {
	text-align: left;
	cursor: default;
	border-top: 0;
	background: $white;
	@extend %drop-shadow;
	padding: $grid-gutter-width / 2;
	border: 2px solid $gray-500;
	margin-left: -2px;
	margin-top: 2px;

	/* core styles should not be changed max-height: 254px; */
	position: absolute;
	display: none;
	z-index: 1037;
	overflow: hidden;
	overflow-y: auto;
	box-sizing: border-box;

	&.fixed {
		position: fixed;
		margin-top: -1px;
	}
}

.autocomplete-suggestion {
	position: relative;
	padding: 0.5em 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: $font-small;
	color: $dark;
	border-bottom: 1px solid $gray-500;

	&:last-child {
		border: 0;
	}

	background-size: 20px 20px;
	background-position: left 2.5px center;
	background-repeat: no-repeat;
	padding-left: 25px;
	cursor: pointer;
	@include transition(background-color 0.3s ease);

	&.hotel {
		background-image: $icon-hotel-dark;		
	}

	&.location {
		background-image: $icon-pointer-map-dark;
	}

	span {
		//border: 1px solid red;
		display: block;
		min-width: 100%;
		max-width: 100%;
		width:100%;
		overflow: hidden;
		position: relative;
		height: 1.8em;
		padding: 0.25em;
	//	border:1px solid blue;
		white-space: nowrap;

		.inner {
			display: block;
			width: auto;
			position: absolute;
			white-space: nowrap;
			transform: translateX(0);
			white-space: nowrap;
		//	background-color: rgba($dark, 0.25);
			@include transition(transform 0.5s ease); 
			
		}
	}

	&:hover {

		.slide { 
			@include transition(transform 2.5s linear); 
		}


	}
}
.autocomplete-suggestion b {
	font-weight: normal;
	color: $green;
}

.autocomplete-suggestion.selected {
	background-color: $secundary;
	color: $white;

	b {
		color: $dark;
	}
}
