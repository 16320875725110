$nbp-height : 50px ;
.nav--booking-process {

	background-color: $secundary;
	color: $white;
    height: auto;
    padding-top: 0!important;
	padding-bottom: 0!important;
	
	.btn-go-back {

		position: absolute;
		right: $grid-gutter-width / 2 ;
		top:50%;
		z-index: 25;
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
		transform: translate(0, -50%);
		background-color: $secundary;
		height: 100%;
		border-left: 1px solid $white ;

		i { line-height: 50px; background-size: 55%; }

		@include media-breakpoint-down("md") { 

			right: ( $grid-gutter-width / 2 ) * -1 ;
			padding-left: $grid-gutter-width / 1.5;
			padding-right: $grid-gutter-width / 1.5;
		}
		
	}

	ul {
        min-height: $nbp-height;
	}

	a,
	h2 {
		color: $white;
		overflow: hidden;
		white-space: nowrap;
        font-family: $font-family-titles;
        height: 100%;
		// text-transform: uppercase;
        //calc(0.75rem + 0.6vw);
        margin-top:0;
        margin-bottom:0;

        padding-top: 0!important;
        padding-bottom: 0!important;
        line-height: $nbp-height;
        font-size: 1em;
        font-weight: normal;

    }
    
    h2 { font-size: 1.15em; }

	li {
		// border: 1px solid blue ;
		position: relative;
		z-index: 2;

		a {
			padding-left: $grid-gutter-width * 1.25 !important ;

			&:before {
				content: "";
				width: 30px;
				height: 100%;
				display: inline-block;
				position: absolute;

				@include transform(skew(0deg));
				top: 0;
				left: 0;

				//     background-image: $btn-arrow-right-white;
				background-size: 30%;
				background-position: right center;
				background-repeat: no-repeat;
			}

			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: left 45% center;
			height: 100%;

			&:after {
				content: attr(data-step);
				font-family: $font-family-numbers;
				font-weight: 900;
				text-align: center;
				width: auto;
				display: inline-block;
				height: auto;
				position: absolute;
				top: 0;
				left: $grid-gutter-width / 1.5;
			}

			@include media-breakpoint-down("md") {
				min-width: 80px !important;
				&:after {
					position: absolute;
					top: 50%;
					left: 48%;
					@include translate(-50%, -50%);
				}
			}

			span {
				@include media-breakpoint-down("md") {
					display: none;
				}
			}
		}

		&.active {
		
			a {
				color: $green;

				padding-left: $grid-gutter-width * 1.25 !important;


				&:before {
				//	width: calc(100% + 2px);
					height:  calc(100% - 8px);
					width:150%;
					
					top: 4px;
			
					background-color: $white;
					@include transform(skew(-20deg));
					left: 0;
					z-index: -1;
				}
				

			}


			&:after {
				content: "";
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				// right: $grid-gutter-width / 2 ;
				background-color: $green;
				@include transform(skew(-20deg));
				z-index: -2;
			}


		
			
		}

		&:before {
			content: "";
			width: 1px;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			// right: $grid-gutter-width / 2 ;
			background-color: $white;
			@include transform(skew(-20deg));
		}






		&:last-child:before {
			display: none;
		}

		&.disabled {
			pointer-events: none !important;
		}

		&.pending {
			a {
				color: $gray-600;
			}

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				// right: $grid-gutter-width / 2 ;
				background-color: $gray-100;
				@include transform(skew(-20deg));
				z-index: -1;
			}
		}
	}

	@include media-breakpoint-down("sm") {

		.navbar-brand { 
			width:100% !important ; 
			flex-shrink: 0;
			flex: 0 0 100%;
	

		
		}	
	

	.navbar-collapse {

		width:100% !important ; 
		flex-shrink: 0;
		flex: 0 0 100%;
		position: absolute;
		top:100%;
		display: none !important ; 
	
	}
}

}
