.canvas-print {
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 9999999;
	opacity: 0.75;
}

.btn-plus {
	width: 16px;
	height: 16px;

	background-color: transparent;
	display: block;
	position: relative;
	display: inline-block !important;
	@include transition(all 0.3s ease);
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		background-color: $green;
		width: 2px;
		height: 14px;
		top: 1px;
		left: calc(50% - 1px);
	}

	&:after {
		content: '';
		position: absolute;
		background-color: $green;
		width: 14px;
		height: 2px;
		top: calc(50% - 1px);
		left: 1px;
	}
}

.active {
	> a .btn-plus {
		@include transition(all 0.3s ease);
		@include transform(rotate(135deg));

		background: color;
	}
}

.v-align {
	position: relative;
	top: 50%;
	@include transform(rotate(0deg) translateY(-50%));
}

// utils init

.utils {
	/// loader

	&--loader {
		width: 400px;

		height: 200px;

		text-align: center;
		font-size: 10px;

		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 0 !important ;

		@include translate(-50%, -50%);

		&.active {
			display: block;
		}

		& > .rect {
			@include bg-opacity($dark, 0.25);
			height: 100%;
			width: 18%;
			display: inline-block;

			@include animation('sk-stretchdelay 1.2s infinite ease-in-out');

			&:nth-child(1) {
				@include bg-opacity($primary, 1);
			}
			&:nth-child(2) {
				@include bg-opacity($secundary, 1);

				-webkit-animation-delay: -1.1s;
				animation-delay: -1.1s;
			}
			&:nth-child(3) {
				@include bg-opacity($complementary, 0.8);

				-webkit-animation-delay: -1s;
				animation-delay: -1s;
			}
			&:nth-child(4) {
				@include bg-opacity($primary, 0.25);

				-webkit-animation-delay: -0.9s;
				animation-delay: -0.9s;
			}
			&:nth-child(5) {
				@include bg-opacity($primary, 0.75);

				-webkit-animation-delay: -0.8s;
				animation-delay: -0.8s;
			}
		}

		@include keyframes(sk-stretchdelay) {
			0%,
			40%,
			100% {
				@include transform(scaleY(0.4));
			}
			20% {
				@include transform(scaleY(1));
			}
		}

		// end loader
	}

	&--messages {
		position: relative;
		width: 94%;
		display: table;
		background: rgba($green, 0.75);
		margin: 2.5% auto;
		height: 80px;
		color: $complementary;
		text-align: center;
		// padding-bottom: 140px;

		p {
			/*
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      */
			display: table-cell;
			vertical-align: middle;
			color: $white;
			padding: 10px;
		}

		&:before {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent $white transparent;
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-left: -5px;
		}

		&---alerts {
			margin: $grid-gutter-width / 2;
			width: 100%;

			&:before {
				border-width: 10px 0 10px 10px;
				border-color: transparent transparent transparent $white;
				bottom: 50%;
				left: 0;
				margin-left: 0;
				margin-bottom: -10px;
			}

			text-align: left;

			p {
				// left: 0;
				// top: 50% !important;
				//transform: translateX(0) translateY(-50%);
				padding-left: 80px;
			}

			background-size: 35px 35px;
			background-position: left 27.5px center;
			background-repeat: no-repeat;

			&.danger {
				background-color: rgba($red, 0.9);

				background-image: $icon-info-white;
			}

			&.error {
				background-color: rgba($red, 0.9);

				background-image: $alert-warning;
			}

			&.info {
				background-image: $icon-info-white;
				background-color: rgba($blue, 0.75);
			}

			&.success {
				background-image: $alert-success;
			}

			&.ssl-security {
				background-color: rgba($green, 0.9);

				background-image: $icon-ssl-lock;

				background-size: 60px 60px;

				p {
					padding-left: 100px;
				}
			}

			&.auto {
				height: auto !important ;
			}

			a {
				color: $white;
				border-bottom: 2px solid $white;
				padding: 0.15em 0.5em;
				margin-left: -4px;

				&:hover {
					background-color: $white;
					color: $secundary;
					@include transition(all 0.3s ease);
				}
			}

			&.btn-ca {
				a {
					padding: 0.25em 1em;
					padding-right: 2em;
					margin-bottom: -0.25rem;
					color: $white;
					margin-left: 2px;
					background-color: $warning;
					font-weight: normal;
					border: 0;

					&::after {
						width: 2em;
						height: 100%;
						top: 0;
						right: 0;
						position: absolute;
						display: block;
						content: '';
						background-image: $btn-arrow-right-white;
						background-size: 10px 10px;
						background-position: center;
						background-repeat: no-repeat;
					}

					&:hover {
						background-color: $warning;
						color: $white;
						@include transition(all 0.3s ease);
						border: 0;

						&::before {
							display: none !important;
						}
					}
				}
			}

			&.info {
				a {
					color: $white;
					border-bottom: 0;
					display: inline-block;
					border: 1px solid $white;
					font-weight: normal;
					padding-top: 0;
					margin-left: 0.15rem;
					padding-bottom: 0;
					padding-left: 0.75rem;
					margin-bottom: -0.095rem;
					padding-right: 24px;
					position: relative;

					&::after {
						width: 20px;
						height: 100%;
						top: 0;
						right: 0;
						position: absolute;
						display: block;
						content: '';
						background-image: $btn-arrow-right-white;
						background-size: 10px 10px;
						background-position: center;
						background-repeat: no-repeat;
					}

					&:hover {
						background-color: $white;
						color: $secundary;
						@include transition(all 0.3s ease);

						&::after {
							background-image: $btn-arrow-right-blue;
						}
						&::before {
							display: none !important;
						}
					}
				}
			}
		}

		// end messages
	}

	&--btn {
		@include transition(all 0.3s ease);

		display: block;
		position: absolute;
		z-index: 2;
		width: 30px;
		height: 30px;
		top: 50%;
		@include translate(0, -50%);
		cursor: pointer;
		top: 50%;
		right: $grid-gutter-width / 2;
		background-size: contain;
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: transparent;

		&:before {
			content: attr(data-text);
			position: absolute;
			z-index: -1;
			width: auto;
			overflow: hidden;
			white-space: nowrap;
			padding-left: 0.75em;
			padding-right: 0.75em;
			height: 28px;
			background: $white;
			display: block;
			text-transform: none;
			color: $dark;
			left: 600px;
			top: 50%;
			opacity: 0;
			@include translate(-100%, -50%);
			font-weight: normal;
			line-height: 28px;
			@include transition(all 0.3s ease);
			font-family: $font-family-base;
		}

		&:hover {
			&:before {
				@include transition(all 0.3s ease);
				left: 0 !important;
				opacity: 1;
			}
		}
	}

	&--btn---reset {
		background-image: $icon-reset;
	}

	&--btn---apply-changes {
		background-image: $icon-apply-changes;
	}

	&---filter-bubble {
		background-color: $secundary;
		border-radius: 0;
		font-size: 10px;
		font-weight: normal;
		text-align: center;
		color: $white;
		padding-left: 8px;
		padding-right: 8px;
		padding-top: 4px;
		padding-bottom: 5px;
		position: absolute;
		top: 50%;
		@include translate(0, -90%);
		left: 22px;
		z-index: 2;
	}

	&--labels {
		background-color: $secundary;
		color: $white;
		padding: 0.25em 1.25em 0.25em 30px;
		display: inline-block;
		font-size: small;
		margin-right: 16px;
		position: relative;

		white-space: nowrap;
		pointer-events: none;
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */

		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 2em (2em / 2.25) 0 0;
			border-color: $secundary transparent transparent transparent;
			position: absolute;
			left: 100%;
			top: 0;
		}

		.close {
			&:before {
				content: '\00d7';
				font-size: 1.5em;
				line-height: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				overflow: hidden;
				text-align: center;
				line-height: 18px;
				font-weight: 100;
				/// override from bootstrap
				color: $white;
				opacity: 1 !important;
				text-shadow: none !important ;
				pointer-events: all;
			}

			&:after {
				content: '';

				line-height: 0;
				position: absolute;
				width: 1px;
				height: calc(100% + 4px);
				overflow: hidden;
				opacity: 1 !important;
				text-shadow: none !important ;
				background-color: $white;
				top: 50%;
				right: -2.5px;
				@include transform(translateY(-50%));
			}

			color: $white;

			position: absolute;

			display: block;

			width: 20px;
			height: 20px;
			cursor: pointer;

			left: 0;
			top: 50%;
			z-index: 2;
			@include translate(0, -50%);
			opacity: 1 !important;
			text-shadow: none !important ;

			&:hover {
				&:before {
					color: $dark;
				}
			}
		}
	}

	&--labels-filters,
	&--messages-content {
		padding: $grid-gutter-width / 2;
	}

	&--labels-filters {
		height: 40px !important;
		overflow: hidden;
		width: auto;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;

		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE 10+ */
		&::-webkit-scrollbar {
			display: none; /* safari & chrome */
		}
	}

	&--labels-filters-scroll {
		white-space: nowrap;
		display: inline-block !important;
		padding-top: 4px;
	}

	&--result---info {
		height: 100%;
		display: inline-block;
		line-height: 50px;
	}

	&--bubble {
		position: absolute;
		border-radius: 100%;
		background: $red;

		width: 25px;
		height: 25px;
		overflow: hidden;
		text-align: center;
		line-height: 25px;

		top: 0;
		right: 0;

		color: $white;

		@include translate(50%, 50%);
	}

	&---tooltip {
		position: absolute;
		left: 50%;
		top: 0;
		padding: 0.75em 0.9em;
		text-align: center;
		white-space: nowrap;
		background: rgba($green, 0.9);
		@include translate(-50%, -120%);
		font-size: small;
		color: $white;
		z-index: 1035;
		display: none;
		//pointer-events: none;

		&:after {
			content: '';
			position: absolute;
			bottom: 1px;
			left: 50%;
			width: 0;
			height: 0;
			border: 0.575em solid transparent;
			border-top-color: $green;
			border-bottom: 0;
			margin-left: -0.575em;
			margin-bottom: -0.575em;
			@include opacity(0.9);
		}
	}
	// loader

	&--spinner {
		margin-top: -10px;
		width: 40px;
		height: 40px;
		text-align: center;
		font-size: 10px;
		display: none;
	}

	&--spinner.active {
		display: block;
	}

	&--spinner > div {
		@include bg-opacity($green, 0.25);
		height: 100%;
		width: 5px;
		//border:1px solid $white ;

		display: inline-block;

		-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
		animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}

	&--spinner .rect2 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
		@include bg-opacity($green, 0.5);
	}

	&--spinner .rect3 {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
		@include bg-opacity($green, 1);
	}

	&--spinner .rect4 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
		@include bg-opacity($green, 0.5);
	}

	&--spinner .rect5 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
		@include bg-opacity($green, 0.25);
	}

	@-webkit-keyframes sk-stretchdelay {
		0%,
		40%,
		100% {
			-webkit-transform: scaleY(0.4);
		}
		20% {
			-webkit-transform: scaleY(1);
		}
	}

	@keyframes sk-stretchdelay {
		0%,
		40%,
		100% {
			transform: scaleY(0.4);
			-webkit-transform: scaleY(0.4);
		}
		20% {
			transform: scaleY(1);
			-webkit-transform: scaleY(1);
		}
	}

	/// white

	&--spinner.white > div {
		@include bg-opacity($white, 0.25);
		border: 1px solid $white;
	}

	&--spinner.white .rect2 {
		@include bg-opacity($white, 0.5);
	}

	&--spinner.white .rect3 {
		@include bg-opacity($white, 1);
	}

	&--spinner.white .rect4 {
		@include bg-opacity($white, 0.5);
	}

	&--spinner.white .rect5 {
		@include bg-opacity($white, 0.25);
	}

	/// supliers

	&---traking {
		width: 120px;
		display: inline-block;
		height: 50px;

		background-size: contain;
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: transparent;
		background-image: $icon-reset;

		@include media-breakpoint-down('xs') {
			width: 80px;
			height: 35px;
		}

		&.null {
			display: none !important;
		}

		&.trivago {
			background-image: url(../../../../storage/images/traking/trivago.svg);

			&.white {
				background-image: url(../../../../storage/images/traking/trivago-white.svg);
			}

			&.left {
				background-image: url(../../../../storage/images/traking/trivago-left.svg);
			}
		}
		&.google {
			background-image: url(../../../../storage/images/traking/google.svg);

			&.white {
				background-image: url(../../../../storage/images/traking/google-white.svg);
			}
			&.left {
				background-image: url(../../../../storage/images/traking/google-left.svg);
			}
		}
		&.kayak {
			background-image: url(../../../../storage/images/traking/kayak.svg);
			&.white {
				background-image: url(../../../../storage/images/traking/kayak-white.svg);
			}
			&.left {
				background-image: url(../../../../storage/images/traking/kayak-left.svg);
			}
		}
		&.tripadvisor {
			background-image: url(../../../../storage/images/traking/tripadvisor.svg);
			&.white {
				background-image: url(../../../../storage/images/traking/tripadvisor-white.svg);
			}
			&.left {
				background-image: url(../../../../storage/images/traking/tripadvisor-left.svg);
			}
		}
		&.idealo {
			background-image: url(../../../../storage/images/traking/idealo.svg);
			&.white {
				background-image: url(../../../../storage/images/traking/idealo-white.svg);
			}
			&.left {
				background-image: url(../../../../storage/images/traking/idealo-left.svg);
			}
		}

		&.right-pos {
			background-position: right center;
		}
	}

	&--sidebar-messages {
		.utils--bubble {
			top: -20px !important ;
		}

		&:empty {
			display: none;
		}

		position: relative;
		width: 94%;
		display: block;
		height: auto;
		background-color: rgba($green, 0.75);

		p {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			display: block;
			color: $white;
			padding: 10px;
			padding-left: 50px;
		}

		&.danger {
			color: $white;
			background-color: rgba($red, 0.9);

			background-size: 30px 30px;
			background-position: left 10px center;
			background-repeat: no-repeat;
			background-image: $alert-danger;
			padding-left: 40px;
		}

		&.ssl-security {
			color: $white;
			background-color: rgba($green, 0.9);
			background-size: 60px 60px;
			background-position: left 10px center;
			background-repeat: no-repeat;
			background-image: $icon-ssl-lock;
			p {
				padding-left: 80px;
			}
		}

		&.success {
			color: $white;
			background-color: rgba($green, 0.9);
			background-size: 30px 30px;
			background-position: left 10px center;
			background-repeat: no-repeat;
			background-image: $alert-success;
			padding-left: 40px;
		}

		&.light {
			background-color: $white;
			&:before {
				display: none;
			}
		}

		margin: 2.5% auto;
		//min-height: 80px;
		color: $complementary;
		text-align: center;
		padding-bottom: 10px;
		text-align: left;
		margin-bottom: 0;

		&:before {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent $white transparent;
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			margin-left: -5px;
		}
	}

	&--banner {
		background-color: rgba($red, 0.75);
		width: auto;
		height: 30px;
		color: $white;
		line-height: 30px;
		position: relative;
		white-space: nowrap;
		font-size: 80%;
		padding-right: 0.75em;
		padding-left: 0.75em;

		@include media-breakpoint-down('xs') {
			font-size: $font-smaller;
		}

		&.timer {
			padding-left: 1.75em;
			background-image: $icon-room-timer;
			background-size: 1.75em 1.75em !important;
			background-position: left center !important;
			background-repeat: no-repeat;
		}

		&.opaquerate {
			padding-left: 1.9em;
			background-image: $icon-money-symbol;
			background-size: 1.9em 1.9em !important;
			background-position: left center !important;
			background-repeat: no-repeat;
			background-color: rgba($green, 0.75);
		}
	}

	&---pop-messages {
		position: fixed;
		display: block;
		z-index: 30;
		bottom: 80px;
		right: 10px;
		width: 300px;
		height: auto;
		pointer-events: none;

		&.big {
			width: 420px;
			bottom: 0.5rem;

			@include media-breakpoint-down('sm') {
			}
			@media (max-width: 430px) {
				width: calc(100% - 20px);
			}
		}

		@include media-breakpoint-down('sm') {
			bottom: 0;
		}

		input[type='radio'] {
			display: none;
		}

		input[type='radio']:checked + div.message---bubble {
			// animation: fadeOutRight 1s forwards;
			display: none;
		}

		.message---bubble {
			width: 100%;
			height: auto;
			background-color: rgba($green, 0.9);
			color: $white;
			padding: 1.15em 0.75em;
			margin-bottom: 1.75em;
			position: relative;
			padding-left: 50px;
			letter-spacing: 1px;
			padding-right: 20px;
			font-size: 0.875em;

			@include media-breakpoint-down('sm') {
			}

			// out animation

			b {
				border-bottom: 1px solid $white;
				font-weight: normal;
			}

			background-size: 30px 30px;
			background-position: left 10px center;
			background-repeat: no-repeat;

			&.bolt {
				background-image: $icon-bolt;
			}
			&.sun {
				background-image: $icon-sun;
			}

			&.clear-bg {
				background: none;
				padding-left: 1.75rem;
				padding-top: 1.75rem;
				text-align: center;
			}

			a.btn--link {
				display: block;
				background-color: $dark;
				pointer-events: all;
				padding: 0.5rem 0.75rem;
				margin: 0.5rem auto;
				margin-bottom: 0;
				width: 120px;
				text-align: center;
			}

			&:before {
				content: '';
				width: 0px;
				height: 0px;
				position: absolute;
				border-left: 10px solid transparent;
				border-right: 10px solid $green;
				border-top: 10px solid $green;
				border-bottom: 10px solid transparent;
				right: 19px;
				bottom: -19px;
				@include opacity(0.9);
			}

			&.warning {
				background-color: rgba($warning, 0.9);
				pointer-events: all;
				span {
					color: rgba($dark, 1);
				}
				&:before {
					border-right: 10px solid $warning;
					border-top: 10px solid $warning;
				}
			}

			label {
				position: absolute;
				right: 5px;
				top: 5px;
				width: 20px;
				height: 20px;
				display: block;
				cursor: pointer;
				pointer-events: all;
				text-align: left;

				&:before {
					content: '\00d7';
					font-size: 1.5em;
					line-height: 0;
					position: absolute;
					width: 100%;
					height: 100%;
					overflow: hidden;
					text-align: center;
					line-height: 20px;

					/// override from bootstrap
					color: $white;
					opacity: 1 !important;
					text-shadow: none !important ;
				}
			}

			//	input:checked + div.bolt {  background-color: red; }

			// end pop---messages
		}
	}

	&---overlay {
		position: fixed;
		background: rgba($black, 0.75);
		width: 100%;
		height: 100%;
		z-index: 999999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;

		&.active {
			display: block;
		}

		&:before {
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-image: url($base-64-background);
			@include opacity(0.15);
		}

		&---message {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: $white;
			padding: 1.75em 2.75em;

			padding-left: 120px;
			padding-bottom: 90px;

			background-image: $icon-alert-bell;

			&.phone-icon {
				background-image: $icon-alert-phone;
			}

			background-repeat: no-repeat;
			background-size: 60px 60px;
			background-position: left 30px center;

			&.pb {
				padding-bottom: 60px !important;
			}

			p {
				position: relative;
				margin-top: 2rem;
				margin-bottom: 2rem;

				@include media-breakpoint-down('md') {
					margin-top: 0.5rem !important;
				}
			}

			&.text-content {
				overflow: hidden;
				padding: 0.75em;
				max-height: 80vh;
				overflow-y: auto;
				background: $white;

				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba($dark, 0.15);
					box-shadow: inset 0 0 6px rgba($dark, 0.15);
					background-color: $white;
				}

				&::-webkit-scrollbar {
					width: 6px;
					background-color: $white;
				}

				&::-webkit-scrollbar-thumb {
					background-color: rgba($green, 0.25);
					border-radius: 4px;
				}
			}

			a.btn {
				background-color: $dark;
				padding: 1.25em 2.75em !important;
				position: absolute;
				display: block;
				bottom: 0;
				left: 50%;
				@include translate(-50%, -50%);

				background-repeat: no-repeat;
				background-size: 30px 30px;
				background-position: center center;

				&--link {
					padding: 0.5em 2.75em !important;
					white-space: nowrap;
					@include media-breakpoint-down('md') {
						margin-top: 0 !important;
					}
				}

				&--reset {
					background-image: $icon-reset;
				}
			}

			@include media-breakpoint-down('md') {
				background-position: top 20px center;
				padding: 100px 2.75em 80px 2.75em;
			}

			@include media-breakpoint-down('xs') {
				width: 90%;

				padding: 100px 1.75em 100px 1.75em;
			}

			.btn-plus {
				position: absolute;
				@include transform(rotate(45deg));
				cursor: pointer;
				z-index: 100;
				top: 20px;
				right: 20px;
				opacity: 1;
				&:hover {
					&:after,
					&:before {
						background-color: $red;
					}
				}
			}

			/* To style the document scrollbar, remove `.custom-scrollbar` */


			///---- ROOM TILE
			/// 
			/// 
			/*
			.utils--banner {

				padding-top: 0.75rem ;
				padding-bottom: 0.75rem ;
			
			}
			*/
			.tile--room {
				border-left: 0;
				border-right: 0;
				border-top:0 ;
				height: auto; 
				margin-top: -1rem !important;
				position: relative;
				display: flex;
				flex-wrap: wrap;

				.tile--room---details, .tile--room---offer, .tile--room---action {

					position: relative;
					width: 100% !important;			
					flex: 1 1 100%;
				}

				.tile--room---action { height: 80px !important; }
				
			}

			

			.tile--room---details {

				//-- border

				.tile--room---name::after {

					width: calc(100% + 1px);
					min-width: calc(100% + 1px);
					max-width: calc(100% + 1px);
					height: 0.25rem;
					top:auto;
					bottom: 0;
					left:0;

				}

				ul, ul li { list-style:none; position: relative;}

				ul li {

					&:nth-last-child(-n+3) {
						/*declarations*/
						display: none !important;
					}

				}


				
			}

			.tile--room---offer {
				
				border-top:1px solid $gray-500;
				border-bottom:1px solid $gray-500;
				
				@include media-breakpoint-down("md") { 

					margin-top:-1px !important;
					margin-bottom:0 !important;
					padding-bottom:  $grid-gutter-width !important;
					//border-color:red !important;

					.utils--banner {
						
						top:0 !important;
						
					
					}
				}
				//border:4px solid black;

				//---> MEDIA MD properties
				ul {
					&::after{
						top: 0;
						height: 100%;
						width: 1px;
						left: 50%;
						margin-top: -0px;
						margin-left: -0.5px;
					}

					
				}

				li {
				&.values {
					font-size: 1.765em;
					padding-top: 0.5em !important;
					padding-right: $grid-gutter-width * 2.5 !important;
				}
				&.description {
					text-align: right;
					position: relative;
					padding-right: 3em !important;
					margin-top: -4px;
					font-size: 0.8265em;

					&:after {
						position: absolute;
						content: "";
						display: block;
						width: 1.5em;
						height: 2px;
						display: block;
						background-color: $green;
						top: 50%;
						right: $grid-gutter-width * .5 ;
						z-index: 5;
						margin-top: -1px;
					}
				}

			}



			}
			
			@include media-breakpoint-down("md") { 


				.tile--room---options { 
					border-bottom:0 !important
				}
			 }



			.tile--room---prices { 
			
				&::after {  height: 150% !important; z-index: -1; opacity: .85;}
			}
			.tile--room---action {
				background-color: $white;

				.button--link {
					display: flex;
					//width: 90% !important;
					position: relative !important;
					align-items: center;
					left:auto;
					top:auto;
					transform: translate(0,0) !important;
					text-align: center !important;
				}

			}

			.utils--banner {

				transform: translateY(-50%) !important;
				left:auto;
				top:0;
				right: 0.5rem;

			}


		}

		// overlay
	}

	&---outher-box {
		position: relative;
		width: 100%;
		height: auto;

		&.expanded {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	&--icons-container {
		min-height: 38px !important;
		// i dont know why ( again )
		margin-top: -10px;

		padding: 0.5em 0.75em;
		.pt-icon {
			margin-right: $grid-gutter-width / 2;
		}
	}

	&--transition {
		height: 100vh;
		min-height: 100vh;
		max-height: 100vh;
		position: relative;
		overflow: hidden;
		display: block;

		&---center {
			position: absolute;
			top: 10%;
			left: 50%;
			width: 60%;

			@include media-breakpoint-down('sm') {
				width: 90%;
			}

			@include translate(-50%, 0);

			color: $dark;
			font-weight: normal;
			padding: 2em;

			.utils--spinner {
				margin: 0 auto;
				height: 60px;
				width: 100px;

				> div {
					width: 8px;
				}
			}

			img {
				height: auto;
				margin: 0 auto !important ;

				@include media-breakpoint-down('xs') {
					width: 80%;
				}
			}

			p {
				margin-top: $grid-gutter-width * 1.25;
				border-top: 4px solid $gray-500;
				border-bottom: 4px solid $gray-500;
				padding: $grid-gutter-width / 2;
			}
		}

		&---message {
			p {
				padding-left: 80px;
				background-size: 35px 35px;
				background-position: left 25px center;
				background-repeat: no-repeat;
			}

			&.error p {
				background-image: $alert-warning-red;
			}

			&.success p {
				background-image: $alert-success-green;
			}
		}
	}

	&---commercial {
		h4 {
			background-color: $gray-100;
			font-weight: 900;
			text-transform: none;
		}

		figure {
			width: 100%;
			text-align: center;
		}
		img {
			max-width: 400px;
		}
	}

	&---bg {
		&--warning,
		&.warning {
			background-color: $yellow !important;
			color: $dark;
		}
		&--success,
		&.success {
			background-color: $green !important;
			color: $white;
		}
		&--danger,
		&.danger {
			background-color: $red !important;
			color: $white;
		}
	}

	// end utils
}

.utils--pop-info {
	p {
		font-weight: bolder;
		margin-bottom: 0.75em;
		padding: 0.75em;
		border-bottom: 1px solid $gray-500;
		display: block;
		width: 100%;
		text-transform: uppercase;
	}

	padding-bottom: 1em;
}

.utils--icons-summary {
	text-align: center;

	.pt-icon {
		margin-right: $grid-gutter-width / 2 !important ;
	}
}

.modal {
	background-color: rgba($black, 0.75);
	&:before {
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url($base-64-background);
		//  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPgo8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI4IiBmaWxsPSIjNDAzYzNmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDBMOCA4Wk04IDBMMCA4WiIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiMxZTI5MmQiPjwvcGF0aD4KPC9zdmc+");
		@include opacity(0.45);
	}
}

.util---add-shadow {
	> :first-child {
		@extend %drop-shadow;
	}
}

.util---back-link {
	padding-left: 35px !important;

	background-image: $btn-arrow-left;
	background-repeat: no-repeat;
	background-size: 10px 10px;
	background-position: left 10px center;
}

.util----body-widget {
	background-color: $gray-100;

	padding-top: 3em;

	padding-bottom: 1.75em;
	margin-bottom: $grid-gutter-width / 2;
	text-align: center;

	img {
		height: 45px;
		margin-top: -25px;
	}

	@include media-breakpoint-down('md') {
		padding-bottom: 1.5em;
	}

	@include media-breakpoint-down('xs') {
		padding-bottom: 2.5em;
	}
}

.no-borders {
	border: 0 !important;
}

.no-md-border-bottom {
	@include media-breakpoint-down('md') {
		border-bottom: 0 !important;
	}
}

.top--space {
	margin-top: $menu-link-height-lg + 8px;

	@include media-breakpoint-down('md') {
		margin-top: 48px;
	}
}

.no-border {
	border: none !important ;
}

.relative {
	position: relative;
}

.separator {
	display: block;
	width: 100%;
	height: 40px;
	position: relative;

	&.line-forms {
		// fix only for forms
		margin: auto ($grid-gutter-width / 2);
		margin-top: ($grid-gutter-width / 2) * -1;

		&:before {
			content: '';
			position: absolute;
			background-color: $gray-500;
			width: calc(100% - 20px);
			height: 2px;
			top: calc(50% - 1px);
			left: 0;
		}
	}
}
