.component---loader {
	position: fixed;
	background: rgba($black, 0.75);
	width: 100%;
	height: 100%;
	z-index: 999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;

	&.active {
		display: block;
	}

	&:before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-image: url($base-64-background);
		//background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPgo8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI4IiBmaWxsPSIjNDAzYzNmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDBMOCA4Wk04IDBMMCA4WiIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiMxZTI5MmQiPjwvcGF0aD4KPC9zdmc+");
		@include opacity(0.15);
	}

	&---container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: transparent;
		padding: 2em 2em;
		background-repeat: no-repeat;
		color: $dark;
		/* To style the document scrollbar, remove `.custom-scrollbar` */

		.utils--spinner {
            div {
				border-color: transparent;
                width: 10px;
            }
    
            height: 60px;
            width: 100px;
            border: none !important ;
		}
	}
	//end modal
}
