.component---image-reference
 {
    list-style: none;
    padding-left: 0;
    margin-top:3em;

    li {
        height: 80px;
        margin: 0;
        margin-bottom: 0.75em;

        a { 
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            color: $dark;
            padding-left: 88px;

            figure {

                position: absolute;
                width:80px;
                height: 80px;
                top:0;
                left:0;

                img { width: 100%; height: 100%; }

            }

            h6, span {
                display: block;
                width: 100%;
                margin:0; padding: 0;
                height: 40px;
                line-height: 40px;
                overflow: hidden;
                white-space: nowrap;
                padding-left: 0.75em !important;

            }

            span { 

                border-top: 1px solid $gray-500;

            }



        }


    }
}

