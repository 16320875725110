$height : 120px;
.component---help-center {
	position: fixed;
	width: 100%;
	height: $height;
	z-index: 1040;

	display: none;
	top: 64px;
    pointer-events: none;
    
    @include transform(translateX(100%));
    
    @include media-breakpoint-down("md") {

        top: 48px;

    }

    @include media-breakpoint-down("sm") {

        height: 120px;

    }


	&.active {
       
        display: block;
        @include transform(translateX(0%));
	}

	.container {
		position: relative;
		height: 100%;
        overflow-x: visible;
        
	}

	&--message {
		color: $white;
		height: 100%;
		width: 50%;
		position: absolute;
		top: 0;
		right: 0;
		padding: 1em;
        overflow-x: visible;
        background-image: $icon-help-center;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 76px 76px;
        padding-left: 88px;
        pointer-events: initial;

        .btn--close {
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            position: absolute;
            top: 0.5em;
            right: 0.5em;
       
                display: block;
                cursor: pointer;
        
            &:before {
                width: 100%;
                height: 100%;
                display: block;
                content: "×";
                font-size: 1.5em;
                font-weight: 100;
                color: $white;
                position: absolute;
            }
    
            &:hover {
                &::before {
                    @include transition(all 0.3s ease);
                    color: $gray-500;
                }
            }
        }


        @include media-breakpoint-down("md") {
            width: 90%;
        }

        @include media-breakpoint-down("sm") {
            width: 100%;
            background-position: left 10px center;
        }

        p { margin-bottom: 0; }

        a { 
            
            color: $white; 
            position: relative;
            padding-left: 0.25em;
            padding-right: 0.25em;
            z-index: 2;

            &:before {

            content: "";
			width: 100%;
			height: 2px;
			bottom: -2px;
			left: 0;
			position: absolute;
			display: inline-block;
            background-color: $white;
            white-space: nowrap;
            z-index: -1;
            @include transform( translateY(0%) );
            }

            &:hover {


               
                &:before {
                    @include transition(all 0.3s ease);
                    height: calc( 100% + 4px );
                    

                }


            }
        
        }

		&:after {
			content: "";
			display: block;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			display: block;
			@include opacity(0.95);
			@extend %gradient-logo;
			z-index: -2;
		}

		&:before {
			position: absolute;
			right: 100%;
			top: 0;
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;

			@include opacity(0.95);

			border-width: 0 calc( #{$height} / 2.1) #{$height} 0;
			border-color: transparent #00a3a6 transparent transparent;

            z-index: -3;
            
            @include media-breakpoint-down("sm") {

                display: none;
            }
		}
    }
    



   

	//end help center
}
