$nbp-height : 50px ;
.nav---voucher {

	background-color: $secundary;
	color: $white;
    height: 100%;
    padding-top: 0!important;
    padding-bottom: 0!important;
    min-height: $nbp-height;

	ul {
        min-height: $nbp-height;
    }

   	a {
		color: $white;
		overflow: hidden;
		white-space: nowrap;
        font-family: $font-family-titles;
        height: 100%;
		// text-transform: uppercase;
        //calc(0.75rem + 0.6vw);
        margin-top:0;
        margin-bottom:0;
        font-size: 1em;
        padding: 0 1.5em !important;
        line-height: $nbp-height;
    }
    
	li {
		// border: 1px solid blue ;
		position: relative;
        z-index: 2;
	>	a {
			&:before {
				content: "";
				width: 30px;
				height: 100%;
				display: inline-block;
				position: absolute;

				@include transform(skew(0deg));
				top: 0;
				left: 0;

				//     background-image: $btn-arrow-right-white;
				background-size: 30%;
				background-position: right center;
				background-repeat: no-repeat;
			}

			background-size: 30px 30px;
			background-repeat: no-repeat;
			background-position: left 45% center;
            height: 100%;
            
		}

		&:hover {
			a {
                color: $green;
                
				&:before {
					width: calc(100% + 2px);
					height: 4px;
					top: auto;
					bottom: 0;
					background-color: $green;
					@include transform(skew(-20deg));
					left: -10px;
				}
			}

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				// right: $grid-gutter-width / 2 ;
				background-color: $white;
				@include transform(skew(-20deg));
				z-index: -1;
			}
		}

		&:before {
			content: "";
			width: 1px;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			// right: $grid-gutter-width / 2 ;
			background-color: $white;
			@include transform(skew(-20deg));
		}

		&:last-child:before {
			display: none;
		}

		&.disabled {
			pointer-events: none !important;
		}

		
    }
    

    .dropdown-menu {
        padding: 0;
        margin: 0;
        border: 0 solid transition !important;
        border: 0 solid rgba(0, 0, 0, 0.15);
        border-radius: 0;
     //   -webkit-box-shadow: none !important;
    //    box-shadow: none !important;
        background-color: rgba($white, 1);
		margin-left: -0.75em ;
		
		@extend %drop-shadow ;


        .nav-link {
            border-bottom: 1px solid $gray-100;

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    
    @include media-breakpoint-up("lg") {
        li:hover .dropdown-menu {
            display: block;
        }

    }


}
