.swiper-container, .swiper-container-groups {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 50vh;
	min-height: 360px;
	max-height: 520px;
	background: $body-color;

	@include media-breakpoint-down("md") {
		height: 30vh;
		min-height: 320px;
	}

	@include media-breakpoint-down("sm") {
		min-height: 260px;
	}

	@include media-breakpoint-down("xs") {
		min-height: 210px;
	}

	&---quantity {
		width: auto;
		height: auto;
		padding: 1em;
		position: absolute;
		bottom: 25px;
		background: rgba($black, 0.5);
		color: $white;
		z-index: 100;
	}

	.component---bestdeal-tag {

		z-index: 10000;

		bottom: auto;

		top:3em;

	}
}


.swiper-slide {
	background-position: center;
	background-size: cover;
	text-align: center;
	font-size: 2rem;
	color: $white;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide-caption {
	width: auto;
	text-align: left;
	left: 10px;
	position: absolute;
	margin-top: -150px;
	padding: 2rem;
	visibility: hidden;
	top: 80px;
	left: 0%;
	right: auto;
	bottom: auto;
	width: 65%;
	padding: 0 !important;
	margin: 0;
//	margin-top: -120px;

	&:before {
		@extend %block-before-after;
		@extend %gradient-logo;
		@include bg-opacity($black, 0.45);
		@include opacity(0.9);
	}

	// need to see how manage small devices sm and down

	@include media-breakpoint-down("lg") {
		width: 80%;
	}

	@include media-breakpoint-down("md") {
		width: 80%;
		margin-top: -45px;
	}

	@include media-breakpoint-down("sm") {
		width: 100%;
		top: auto;
		bottom: 10px;
	}

	@include media-breakpoint-down("xs") {
		width: 100%;
		top: auto;
		bottom: 10px;
		text-align: center;
	}

	h5 {
		text-align: left;
		padding: 1.25rem 1.75rem 1.25rem 1.75rem !important;
		margin: 0;
		text-align: right;
		@include font-size(1.15em);
		font-style: normal;
		font-weight: 900;
		letter-spacing: 1px;
		position: relative;
		text-shadow: 0px 4px 3px rgba($dark, 0.5), 0px 8px 13px rgba($dark, 0.15), 0px 18px 23px rgba($dark, 0.15);

		@include media-breakpoint-down("md") {
			@include font-size(0.865em);
		}

		@include media-breakpoint-down("sm") {
			text-align: right;
			padding: 0.75rem !important;
			letter-spacing: 0px;
			font-weight: 400;
			@include font-size($h2-font-size);
			max-height: 70px;
		}

		a {
			color: $white;
			position: relative;
			white-space: nowrap;

			&:hover:before {
				@include transition(all 0.3s ease);
				background: $dark;
			}
			&:before {
				content: "";
				display: block;
				width: 102%;
				height: 4px;

				background: $white;

				position: absolute;
				bottom: -6px;
				left: -1%;

				@include media-breakpoint-down("sm") {
					height: 2px;
					bottom: -2px;
				}
			}
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	@include opacity(0.45);

	&:focus {
		outline: none !important ;
		@extend %no-drop-shadow;
	}
}


.swiper-container-groups { 
	height: 100% !important;
	min-height: 100% !important;
	max-height: 100% !important;

	.swiper-slide {


		
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
		position: relative;

		img { 
			position: absolute;
			/*
			top: 50%;
			left: 0;
			@include translate(0, -50%);
			width: 100%;
			*/

			top: 0;
			left: 0;

			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		
		}
	}

	.swiper-info-container {

		background-color: rgba($dark, 0.75);
		color: $red;
		position: absolute;
		left:0;
		bottom: 0;
		height: 1.25rem;
		width: 100%;

		z-index: 99999;
		text-align: center;

		.swiper-pagination {
			width:100%;
			font-size: 75%;
			height: 100%;
			color: $white;
			display: table-cell;
			vertical-align: middle;
			position: absolute;
			bottom: -0.5px;
			
		}

		.swiper-button-prev, .swiper-button-next {
			
			width: 30%;
			height: .75rem;
			color: $white !important;
			z-index: 999999;
			background-size: 22px 22px;
		}
		.swiper-button-prev { background-position: center right;}
		.swiper-button-next {  background-position: center left; }


	}
}




.slider-behavior {
	position: absolute;
	z-index: 50;
	background-color: rgba($green, 0.5);
	width: 55px;
	height: 40px;
	display: block;
	border: 0 !important;
	top: 6px;
	right: 10px;

	background-size: 100%;
	background-position: 50%;
	background-repeat: no-repeat;
	background-color: transparent;
	background-image: $icon-slide-horizontal;
	@include opacity(0.45);
	filter: drop-shadow(0px 3px 3px rgba($dark, 0.5));

	&:hover {
		@include transition(all 0.3s ease);
		@include opacity(1);
	}

	@include media-breakpoint-down("sm") {
		display: none;
	}
}

/**/

.gallery-top {
	//	border: 4px solid $dark;
	height: 35vh;
	height: 360px !important;
	min-height: 360px !important;
	max-height: 360px !important;

	background-color: rgba( $dark, 0.9 );

	overflow: hidden !important;

	@include media-breakpoint-down("lg") {
		height: 300px !important;
		min-height: 300px !important;
		max-height: 300px !important;
	}

	@include media-breakpoint-down("md") {
		height: 280px !important;
		min-height: 280px !important;
		max-height: 280px !important;
	}

	@include media-breakpoint-down("sm") {
		height: 280px !important;
		min-height: 280px !important;
		max-height: 280px !important;
	}

	.swiper-lazy-loaded {
		z-index: 5 !important;
	}
}

.swiper-slide {
	img {
		position: absolute;
		z-index: -1;
		left: -100vw;
		border: none;
	}

	.utils--spinner {
		div {
			border-color: transparent;
			width: 10px;
		}

		height: 60px;
		width: 100px;
		border: none !important ;
	}

	&.swiper-slide-active {
		//show in loaded image
		.utils--spinner {
			display: block;
		}
	}

	&.swiper-lazy-loaded {
		//hide in loaded image
		.utils--spinner {
			display: none;
		}
	}

	&.no-image {
		@extend %no-image;
	}
}

.gallery-thumbs {
	height: 35vh;
	height: 360px !important;
	min-height: 360px !important;
	max-height: 360px !important;

	background-color: rgba( $dark, 0.9 );

	@include media-breakpoint-down("lg") {
		height: 300px !important;
		min-height: 300px !important;
		max-height: 300px !important;
	}

	@include media-breakpoint-down("md") {
		border-top: 0;

		height: 280px !important;
		min-height: 280px !important;
		max-height: 280px !important;
	}

	@include media-breakpoint-down("sm") {
		border-top: 0;
		height: 80px !important;
		min-height: 80px !important;
		max-height: 80px !important;
	}
}

.gallery-thumbs {
	background-color: $dark;

	border-left: 4px solid $white;

	@include media-breakpoint-down("sm") {
		border-left: 0;
		border-top: 8px solid $white;
	}
}
.gallery-thumbs .swiper-slide {
	width: auto;
	height: auto;
	opacity: 0.45;
	position: relative;
	cursor: pointer;

	&.no-image {
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: $icon-camera;
		background-color: rgba($gray-600, 0.5);
		&:after,
		&:before {
			display: none;
		}
	}
}
.gallery-thumbs .swiper-slide-thumb-active,
.gallery-thumbs .swiper-slide-active {
	opacity: 1;
	cursor: normal;

	border-top: 4px solid $white;
	border-bottom: 4px solid $white;

	@include media-breakpoint-down("sm") {
		border: 0;
		border-left: 8px solid $white;
		border-right: 8px solid $white;
	}
}

.swiper-button-next--hotel,
.swiper-button-prev--hotel {
	background: $black;

	width: 40px !important;
	height: 120px !important;
	top: 20px !important;
	cursor: pointer;
}

.swiper-button-next--hotel {
	right: -2px !important;
}
.swiper-button-prev--hotel {
	left: -2px !important;
}

.dummy {
	position: absolute;
	z-index: 999999;
	width: 40px;
	height: 100%;

	display: block;
	background-color: rgba($dark, 0.75);
	pointer-events: none;

	&:before {
		content: " ";
		width: 100%;
		height: 100%;
		z-index: 2;
		position: absolute;
		display: block;
		top: 0;
		right: 0;

		background-size: 20px 20px;
		background-repeat: no-repeat;
		background-position: center center;

		@include opacity(0.45);
	}

	&.next {
		right: 0;
	}

	&.next:before {
		background-image: $btn-arrow-right-white;
	}

	&.prev:before {
		background-image: $btn-arrow-left-white;
	}
}

/**/

.expanded {
	.slider-behavior {
		background-image: $icon-slide-vertical;
		width: 50px;
	}

	.gallery-thumbs {
		border-top: 8px solid $white !important;
		height: 100px !important;
		min-height: 100px !important;
		max-height: 100px !important;
		border-left: 0 !important;

		@include media-breakpoint-up("md") {
			border-top: 8px solid $white !important;
			height: 100px !important;
			min-height: 100px !important;
			max-height: 100px !important;
		}
	}

	.gallery-thumbs .swiper-slide-thumb-active,
	.gallery-thumbs .swiper-slide-active {
		opacity: 1;
		cursor: normal;

		border: 0;
		border-left: 8px solid $white;
		border-right: 8px solid $white;

		@include media-breakpoint-down("sm") {
			border: 0;
			border-left: 8px solid $white;
			border-right: 8px solid $white;
		}
	}

	.gallery-top {
		//	border: 4px solid $dark;
		height: 40vh;
		height: 420px !important;
		min-height: 420px !important;
		max-height: 420px !important;

		@include media-breakpoint-down("lg") {
			height: 360px !important;
			min-height: 360px !important;
			max-height: 360px !important;
		}
	
		@include media-breakpoint-down("md") {
			height: 320px !important;
			min-height: 320px !important;
			max-height: 320px !important;
		}

	}
}

/*
.swiper-slide {
  .utils--spinner {
    display: none !important;
  }
}
*/

.swiper-lazy-loaded {
	.utils--spinner {
		display: none !important;
	}
}

.swiper-lazy-loading {
	.utils--spinner {
		display: block !important;
	}
}
