.tile--room-header {
	&---container {
		.null {
			display: none;
		}

		.header-content {
			position: relative;
			height: auto;
			display: block;
			background-color: $gray-100;
			height: 116px;
			

			figure {
				background-color: $dark;
				width: 260px;
				height: 100%;
				position: absolute;
				overflow: hidden;
				margin: 0;
				top: 0;
				left: 0;

			
				
				&:after {
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 116px (116px / 3);
					border-color: transparent transparent $gray-100 transparent;

					top: 0;
					right: 0;
				}

				> img {
					position: absolute;
					top: 50%;
					left: 0;
					@include translate(0, -50%);
					width: 100%;
				}
			}

			h2 {
				padding: 0.25em 0.75em;
				padding-left: 270px;
				text-transform: none;
				font-weight: bolder;
				font-size: 1.5em;
				margin: 0;

				.btn-plus {
					display: none !important;
				}

				@include media-breakpoint-down("sm") {
					padding-right: 50px;
				}
			}

			table {
				background-color: $gray-100;

				//background-color: rgba( $green, 0.75 );
				width: 100%;

				tr td:first-child {
					width: 260px;
				}

				td {
					vertical-align: middle;
					// overflow: hidden;
					white-space: nowrap;
					text-align: left;
					padding: 0;
					padding-left: $grid-gutter-width / 2;
				}

				tr td:last-child {
					text-align: center;
					min-width: 260px;
					max-width: 260px;
					width: 260px;
				}
			}

			.btn {
				width: auto;
				text-align: center;
				color: $white;
				border: none;
				background-color: $dark;
				// text-transform: uppercase;
				display: inline-block;
				font-family: $font-family-titles;
				//font-weight : 300;
				letter-spacing: 1px;
				text-align: center;
				padding-left: 30px;
				padding-right: 30px;
				font-size: 1em;

				@include transition(all 0.3s ease);

				&:hover {
					@include transition(all 0.3s ease);
					background-color: $green;
				}
			}

			ul.amenities {
				list-style: none;
				display: table;
				margin: 0 auto;
				margin-top: -30px !important;

				text-align: center;

				li {
					display: table-cell;
					vertical-align: middle;
					width: 30px;
					height: 30px;
					position: relative;
					margin-right: 4px;
					padding: 0.25em !important ;

					&:last-child {
						border-right: 0;
					}
					text-align: center;

					&:hover,
					&:focus {
						span.utils---tooltip {
							display: block;
						}
					}

					i {
						width: 22px;
						height: 22px;
					}
				}
			}

			@include media-breakpoint-down("md") {
				height: 100px;
				padding-bottom: 0 !important;
				position: relative;
				border: 1px solid $gray-100;
				overflow: hidden;
				@include transition(max-height 0.3s ease);
				@include transition(height 0.3s ease);

				figure {
					height: 100px;
					width: 190px;
				}

				ul.amenities {
					margin-top: 0px !important;
				}

				h2 {
					padding-left: 0.75em;

					z-index: 2;
					position: absolute;
					background-color: rgba($dark, 0.75);
					color: $white;
					width: 100%;
					top: 90px;
					@include translate(0, -101%);
					overflow: hidden;

					.btn-plus {
						position: absolute;
						right: 30px;
						display: block !important;
						top: 50%;
						@include translate(0, -50%);
						z-index: 10;
						cursor: pointer;
						border-radius: 100%;

						&:before,
						&:after {
							@include transition(all 0.3s ease);
						}
					}
				}

				&.active {
					height: auto;

					padding-bottom: 0;

					h2 .btn-plus {
						&:before,
						&:after {
							@include transform(rotate(135deg));
						}
						//  @include transform( rotate(135deg) translate(25%,75%) );
					}
				}

				table {
					background: rgba($dark, 0.75);
					margin-top: 100px;
					color: $white;
					margin-bottom: 0;

					.amenities-container {
						position: absolute;
						top: 5px;
						z-index: 3;
						overflow: visible;

						text-align: right;
						right: 10px;
						ul {
							float: right;
							margin: 4px;
						}
					}

					tr td:first-child {
						width: 0;
						display: none;
					}

					td {
						display: block;
						width: 100% !important;
						max-width: 100% !important;
						padding: 0.5em;

						.btn {
							margin: 0.5em auto !important;
						}
					}
				}
			}

			// end header
		}

		.no-image {
			figure.null {
				display: block !important;
				background-color: $gray-400;
				img {
					display: none;
				}
			}
		}

		.columns {
			margin-top: $grid-gutter-width / 2 !important;

			background-color: $gray-500;

			.row {
				.col,
				[class*="col-"] {
					border-left: 1px solid $white;

					padding: 0.45em 0.75em !important ;

					font-weight: bolder;
				}

				&:first-child > div:first-child {
					border-left: 0;
				}
			}

			@include media-breakpoint-down("md") {
				display: none !important ;
			}
		}
	}
	// end container

	&---info {
		@include media-breakpoint-down("sm") {
			position: absolute;
			height: 100% !important;
			min-height: 100% !important;
			max-height: 100% !important;
			width: 100%;
			min-width: 100%;
			max-width: 100%;

			@include transition(all 0.3s ease);
			top: 0;
			left: 50%;
			@include translate(250%, 0%);

			&.active {
				@include transition(all 0.3s ease);
				@include translate(-50%, 0%);
				z-index:9999;
			}

			&:before {
				content: "";
				position: absolute;
				width: 100vw;
				height: 100%;
				background-color: $gray-100;
				top: 0;
				left: 0;
				@include translate(0%, 0%);
				z-index: -1;
			}

			.amenities-container {
				position: absolute;
				top: 50%;
				left: 50%;

				@include translate(-50%, -50%);
			}

			a.btn-info-mobile {
				position: absolute;
				width: 25px;
				height: 25px;
				display: block;
				background-color: $dark;
				border-radius: 100%;
				top: 50%;
				left: 30px;
				@include translate(0, -50%);

				&:before {
					position: absolute;
					content: "";
					width: 100%;
					height: 100%;
					background-size: contain;
					background-position: 50%;
					background-repeat: no-repeat;
					background-color: transparent;
					background-image: $icon-info-letter;
					display: block;
					z-index: 2;
					top: 0;
					left: 0;
				}
			}

			a.btn {
				visibility: hidden;
				/*
				position: relative; 
				top:50%;
				left:-25vw;
				@include translate(-100%, -50%);
				width:30px !important;
				max-width: 30px !important;
				min-width: 30px !important;
				height: 30px !important;
				min-height: 30px !important;
				max-height: 30px !important;
				display: block !important;
				white-space: nowrap;
				overflow: hidden;
				text-indent: 100px !important;
				border-radius: 20px;
				border:0;

				
				*/
			}
		}
	}
	// end
}

$image-width: 260px;


.tile--room-header---small {
	&---container {
		background-color: $gray-100;
		position: relative;
		z-index: 1;

		&:before {
			position: absolute;
			z-index: 1;
			content: "";
			width: 10px;
			height: 100%;
			display: block;
			background-color: rgba($dark, 0.75);
		}

		&:after {
			position: absolute;
			width: 0;
			height: 0;
			content: "";
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 10px solid $dark;
			top: 50%;
			left: 10px;
			z-index: 1;
			@include translate(0, -50%);
			@include opacity(0.75);

			display: none;
		}

		&.no-image { 
		
				&:after { display:block !important; }
		}

		&.has-image {


			height: 100px;

		}
		
		h2 {
			font-weight: 900;
			margin-bottom: 0;
			padding-left: 280px;
			//20px;
			position: relative;
			padding-top: 1em;
			padding-bottom: 1em;
			text-shadow: rgba(0, 0, 0, 0.1) 0.18px 1.25px;
			@include media-breakpoint-down("md") {
			padding-right: 1.15em;
			}

		}
		
		figure {
			background-color: $dark;
			width: 280px;
			height: 100%;
			position: absolute;
			overflow: hidden;
			margin: 0;
			top: 0;
			left: 0;
			
			&:after {
				position: absolute;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 116px (116px / 3);
				border-color: transparent transparent $gray-100 transparent;
				z-index: 1;
				top: 0;
				right: 0;
			}

			> img {
				position: absolute;
				top: 50%;
				left: 0;
				@include translate(0, -50%);
				width: 100%;
			}


			
		}

		
		&.has-image {

			figure {
		
				&:after {

					border-width: 0 0 140px (140px / 3);
					
				}}
		}


		ul.room-details {

			position:absolute;
			width:100%;
			overflow: hidden;
			width: auto;
			height: 24px;
			bottom: 0.25rem;
			list-style: none;
			z-index: 10;
			font-size: 75%;
			padding-left: 264px !important;
			pointer-events: none;


			li {
				display: inline-block;
				list-style: none;
				width: auto;
				border:1px solid $gray-500;
				padding: 0 !important;
				margin-left: 0.25rem;

				span { 
					displa:table-cell; 
					margin: 0; 
					padding-left: 0.5rem; 
					padding-right: 0.5rem; 
				}
				span.label { border-right:1px solid $gray-500; }
				span.value { border:0 !important ; margin-left: -1px !important; }
				margin-bottom: 0 !important;
				pointer-events: none;
			}
		 }

		&.no-image {
		
			figure, image { display: none !important;} 

			ul.room-details { padding-left: 20px !important; }

	     	h2 { padding-left: 20px; } }

		 
	  
		//--- end figure

		.btn-plus {
			right: $grid-gutter-width;
			z-index: 99999;
			position: absolute;
			top: 50%;
			@include translate(0, -50%);

			&:after,
			&:before {
				@include transition(all 0.3s ease);
				@include transform(rotate(0deg));
			}

			&.active {
				&:after,
				&:before {
					@include transition(all 0.3s ease);
					@include transform(rotate(135deg));
				}
			}

			@include media-breakpoint-up("md") {
				display: none !important;
			}
		}

		.title {
			position: relative;

			&:before {
				position: absolute;
				width: 0;
				height: 0;
				content: "";
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 10px solid $white;
				top: 50%;
				left: 0;
				z-index: 1;
				@include translate(0, -50%);
				display: none;
			}
		}

		ul.amenities {
			list-style: none;
			display: table;
			margin: 0 auto;
			text-align: center;

			padding: 0;
			margin-bottom: 0.25em;

			li {
				display: table-cell;
				vertical-align: middle;
				width: 40px;
				height: 30px;
				position: relative;
				margin-right: 4px;
				padding: 0.25em !important ;

				&:before {
					position: absolute;
					width: 1px;
					height: 80%;
					content: "";
					background-color: $gray-500;

					top: 50%;
					right: -0.5px;
					z-index: 1;
					@include translate(0, -50%);
				}

				&:last-child:before {
					display: none;
				}
				text-align: center;

				&:hover,
				&:focus {
					span.utils---tooltip {
						display: block;
					}
				}

				i {
					width: 22px;
					height: 22px;
				}
			}
		}
	}
}

//----- modal 

$fh : 20rem; // 20rem = 320px

.utils---overlay---message {

	&.has-media {

		max-width: 800px;

		.messages---room-header-content {
			padding-top: ($fh + 1rem) !important;

		h2.group-title { 
			position: absolute;
			top:1rem;
			z-index: 50;
			
		}
		}
	}

	ul { margin-left: 1rem !important; margin-right: 1rem !important; }

	.messages---room-header-content {
		position: relative;

		figure {
			position: absolute;
			top:0;
			left:0;
			width:100%;
			height: #{$fh};
			width: 100%;
			overflow: hidden;


			img { 
				position: absolute;
				top: 0;
				left: 0;
	
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;

			}

			.swiper-container-groups {

				height:100%;
				width: 100%;

				.swiper-info-container { 
					
					height: 3rem;
					line-height: 3rem;
				
				}

				.swiper-slide {

					small { 
						position: absolute; 
						background-color: rgba( $white, .75) ;
						color: $dark ;
						padding: 0.25rem 1.5rem ;
						bottom: 2.99rem;
						right: 0;
						font-size: .865rem;
						//-webkit-transform: translateX(-50%);
						//transform: translateX(-50%);
					}
					img {

						object-fit: contain;
					}

				}

				
				.swiper-button-next, .swiper-button-prev {
					
					height: 3rem;
					width: 3rem;
					
				 }

			}
		}



	}

}

///------>
$rh: 6rem;
$iw: 18rem;
$bv : 0 0 $rh ($rh/3);
///------>

.header-room {
	&---container {

		position: relative ;
		height: #{$rh};
		background-color: $gray-100;
		z-index: 4;

		@include media-breakpoint-down("sm") {
			&.has-media {
			margin-bottom:($rh + 2.5rem);
		}
		}


		.media {

			width: #{$iw} !important;
		//	overflow: hidden;
			position: relative;


			&.no-image {
			
				width:10px !important;
				background-color: $dark;
				//@include opacity(0.75);
				padding: 0 !important;
				position: relative;
				margin-right: 10px;

				&:after {
					position: absolute;
					width: 0;
					height: 0;
					content: "";
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
					border-left: 10px solid $dark;
					top: 50%;
					left: 10px;
					z-index: 9;
					@include translate(0, -50%);
					display: block;
					
				}

				figure { display:none !important; }


				


			}


			@include media-breakpoint-down("sm") {
			
				width:5px !important;
				background-color: $dark;
				//@include opacity(0.75);
				padding: 0 !important;
				position: relative;
				margin-right: 10px;
				z-index: 10;

				&:after {
					position: absolute;
					width: 0;
					height: 0;
					content: "";
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					border-left: 5px solid $dark;
					top: 50%;
					left: 5px;
					z-index: 9;
					@include translate(0, -50%);
					display: block;
					
				}

			   }
			

			

			figure {
				background-color: $dark;
				width: 100% !important;
				height: 100% !important;
				position: absolute;
				overflow: hidden;
				margin: 0;
				top: 0;
				left: 0;

				&.null {
					display:none;
				}
				&:before { @include transition(all 0.3s ease); opacity: 0; 
				
					position: absolute;
					content: "";
					width: 100%;
					height: 100%;
					display: flex;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white;
					font-size: 2.45rem;
					font-weight: 600;
					background-color: rgba($color: $yellow, $alpha: .75);
					z-index: 1;
					top: 0;
					text-rendering: optimizeLegibility;
					right: 0;
					background-image: $icon-pictures-gallery-dark;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 12.5%;
				}

				&:hover {

					&:before {

						
						@include transition(all 0.3s ease);
						
						opacity: 1;
					}

				}
				

				&:after {
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: $bv;
					border-color: transparent transparent $gray-100 transparent;
					z-index: 2;
					top: 0;
					right: 0;
				}
	
				> img {
					position: absolute;
					top: 50%;
					left: 0;
					@include translate(0, -50%);
					width: 100%;
				}



				@include media-breakpoint-down("sm") {
					//display:none !important;
					top:100%;
					width: 100vw !important;
					height: 120px !important;
					opacity: 1;
					z-index: 10;

					border-bottom: 5PX solid $gray-100;

					&:after { 

						display: none;
					}

				   }


			}


		}

		h2 {

			font-weight: 900;
			margin-bottom: 0;
			position: relative;
			text-shadow: rgba(0, 0, 0, 0.1) 0.18px 1.25px;
			@include media-breakpoint-down("md") {
			padding-right: 1.15em;
			}
			width: 100%;


		}

		ul.room-details {
	
			position:relative;
			overflow: hidden;
			width: 100%;
			list-style: none;
			z-index: 10;
			font-size: 75%;
			pointer-events: none;
			padding: 0 !important;
			white-space: nowrap;

			@include media-breakpoint-down("md") {
				 display:none;
				}


			li {
				display: inline-block;
				list-style: none;
				width: auto;
				border:1px solid $gray-500;
				padding: 0 !important;
				margin-right: 0.25rem;

				&:last-child { margin-right:0; }

				span { 
					displa:table-cell; 
					margin: 0; 
					padding-left: 0.5rem; 
					padding-right: 0.5rem; 
				}
				span.label { border-right:1px solid $gray-500; }
				span.value { border:0 !important ; margin-left: -1px !important; }
				margin-bottom: 0 !important;
				pointer-events: none;
			}
		 }



		 ul.amenities {
			list-style: none;
			display: table;
			margin: 0 auto;
			text-align: center;

			padding: 0;
			margin-bottom: 0.25em;

			li {
				display: table-cell;
				vertical-align: middle;
				width: 40px;
				height: 30px;
				position: relative;
				margin-right: 4px;
				padding: 0.25em !important ;

				&:before {
					position: absolute;
					width: 1px;
					height: 80%;
					content: "";
					background-color: $gray-500;

					top: 50%;
					right: -0.5px;
					z-index: 1;
					@include translate(0, -50%);
				}

				&:last-child:before {
					display: none;
				}
				text-align: center;

				&:hover,
				&:focus {
					span.utils---tooltip {
						display: block;
					}
				}

				i {
					width: 22px;
					height: 22px;
				}
			}
		}


		.btn-plus {
			right: $grid-gutter-width;
			z-index: 99999;
			position: absolute;
			top: 50%;
			@include translate(0, -50%);

			&:after,
			&:before {
				@include transition(all 0.3s ease);
				@include transform(rotate(0deg));
			}

			&.active {
				&:after,
				&:before {
					@include transition(all 0.3s ease);
					@include transform(rotate(135deg));
				}
			}

			@include media-breakpoint-up("md") {
				display: none !important;
			}
		}
		
	}
}

.room-gallery {

	//.swiper-btn-expand { display: none;  }
	cursor: pointer;
	.swiper-btn-expand { 

		width: 1rem;
		height: 1rem;
		position: absolute;
		z-index: 10;
		display: block;
		background-color: rgba( $white, .05);
		top:0.25rem;
		left:0.25rem;
		cursor: pointer;
		
		opacity: 0.25;
		-webkit-transition: 300ms opacity;
		-o-transition: 300ms opacity;
		transition: 300ms opacity;
		mix-blend-mode: difference;
	
}



&:hover { 
	
	.swiper-btn-expand { 

		opacity: 1;

	}
 }


}

