.popover .close {
    position: absolute;
    top: 7.5px;
    right:15px;
}

.popover-header {
  // 
}

.component---popover-container {  
    @extend %drop-shadow-popups;
    .popover-header {  padding-right:60px; }

    .popover-body {
     //   min-height: 65px;
        padding-bottom: 1em;
      }
}