.modal-3d-payment {

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    
        position: fixed;
      background: rgba(0,0,0, 0.75);
      width: 100%;
      height: 100%;
      z-index: 999999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      
      
  }
  
  .modal:before {
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPgo8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI4IiBmaWxsPSIjNDAzYzNmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDBMOCA4Wk04IDBMMCA4WiIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiMxZTI5MmQiPjwvcGF0aD4KPC9zdmc+");
          opacity:0.15;
          z-index:-1;
      }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: white;
   /* margin: 15% auto;  15% from the top and centered */
    padding: 1em;
    border: 0;
    
    max-width: 560px; /*  Could be more or less, depending on screen size */
    min-width: 410px; /*  Could be more or less, depending on screen size */
  
    
    overflow: auto; /* Enable scroll if needed */
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);  
  }
  
  .modal-header { position: absolute; top:10px; right:10px; z-index:4; width:100%; }
  
  .modal-body{
      
      overflow: auto;
  
      width:100%;
      height:100%;
  }
  
  .embed-responsive-proportions::before{padding-top:94%}
  
  @media only screen and (max-width: 560px) {
  
  .modal-content {	 
  
   width: 94%;
   min-width: 94%;
   max-width:94%;
   
  }
  
  
  
  
  }
  
  
  @media only screen and (max-width: 419px) {
  
  .embed-responsive-proportions::before{padding-top:100% !important;}
  
  }
  
  
  @media only screen and (max-width: 379px) {
  
  .embed-responsive-proportions::before{padding-top:120% !important;}
  
  }
  
  
  @media only screen and (max-width: 340px) {
  
  .embed-responsive-proportions::before{padding-top:140% !important;}
  
  }
  
  .modal-body iframe 
   {
       padding-top:5px;
   }
    
  
  /*
  .modal:before {  backgorund-image:none;  display:none !important;  }
  */
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 24px;
    font-weight: bold;
    position:absolute;
    right:0;
    top:-10px;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }






}