.wpcc-container {
	opacity: 1;
}
.wpcc-transparency-5 {
	opacity: 0.95;
}
.wpcc-transparency-10 {
	opacity: 0.9;
}
.wpcc-transparency-15 {
	opacity: 0.85;
}
.wpcc-transparency-20 {
	opacity: 0.8;
}
.wpcc-transparency-25 {
	opacity: 0.75;
}
.wpcc-container {
	border-style: solid;
	border-width: 0;
	box-sizing: border-box;
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	font-family: inherit;
	font-size: 16px;
	line-height: 1.5em;
	-webkit-transition: opacity 1s ease;
	-moz-transition: opacity 1s ease;
	-ms-transition: opacity 1s ease;
	-o-transition: opacity 1s ease;
	overflow: hidden;
	position: fixed;
	transition: opacity 1s ease;
	z-index: 9999;
}
@media print {
	.wpcc-container {
		display: none;
	}
}
.wpcc-pushdown-wrap {
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height 1s;
	-moz-transition: max-height 1s;
	-ms-transition: max-height 1s;
	-o-transition: max-height 1s;
	transition: max-height 1s;
}
.wpcc-pushdown {
	margin: 0 !important;
	position: static;
}
.wpcc-invisible {
	opacity: 0;
}
.wpcc-fontsize-tiny {
	font-size: 12px;
}
.wpcc-fontsize-small {
	font-size: 14px;
}
.wpcc-fontsize-large {
	font-size: 18px;
}
.wpcc-border-thin {
	border-width: 1px;
}
.wpcc-border-normal {
	border-width: 3px;
}
.wpcc-border-thick {
	border-width: 5px;
}
.wpcc-float {
	flex-direction: column;
	max-width: 100%;
	padding: 1.5em;
}
.wpcc-float.wpcc-padding-small {
	padding: 1em;
}
.wpcc-float.wpcc-padding-large {
	padding: 2em;
}
.wpcc-banner {
	flex-direction: row;
	margin: 1em;
	padding: 1em;
}
.wpcc-banner.wpcc-padding-small {
	padding: 0.5em 0.5em 0.5em 1em;
}
.wpcc-banner.wpcc-padding-large {
	padding: 1.5em;
}
.wpcc-banner.wpcc-margin-none {
	margin: 0;
}
.wpcc-banner.wpcc-margin-small {
	margin: 0.5em;
}
.wpcc-banner.wpcc-margin-large {
	margin: 1.5em;
}
.wpcc-padding-none {
	padding: 0;
}
.wpcc-padding-none.wpcc-float .wpcc-message {
	margin: 1em;
}
.wpcc-padding-none.wpcc-banner .wpcc-message {
	margin: 0.8em 1em;
}
.wpcc-padding-none.wpcc-banner .wpcc-btn {
	height: 100%;
	padding: 0.9em 1.5em;
}
.wpcc-btn,
.wpcc-privacy {
	cursor: pointer;
}
.wpcc-privacy {
	display: inline-block;
	opacity: 0.85;
	text-decoration: underline;
}
.wpcc-privacy:hover {
	opacity: 1;
}
.wpcc-privacy:active,
.wpcc-privacy:visited {
	color: initial;
}
.wpcc-btn {
	display: block;
	font-size: 0.9em;
	font-weight: 700;
	padding: 0.4em 0.8em;
	text-align: center;
	white-space: nowrap;
	-webkit-transition: background-color 0.2s ease;
	-moz-transition: background-color 0.2s ease;
	-ms-transition: background-color 0.2s ease;
	-o-transition: background-color 0.2s ease;
}
.wpcc-btn:hover {
	text-decoration: none;
}
.wpcc-top {
	top: 1em;
}
.wpcc-top.wpcc-margin-none {
	top: 0;
}
.wpcc-top.wpcc-margin-small {
	top: 0.5em;
}
.wpcc-top.wpcc-margin-large {
	top: 1.5em;
}
.wpcc-left {
	left: 1em;
}
.wpcc-left.wpcc-margin-none {
	left: 0;
}
.wpcc-left.wpcc-margin-small {
	left: 0.5em;
}
.wpcc-left.wpcc-margin-large {
	left: 1.5em;
}
.wpcc-right {
	right: 1em;
}
.wpcc-right.wpcc-margin-none {
	right: 0;
}
.wpcc-right.wpcc-margin-small {
	right: 0.5em;
}
.wpcc-right.wpcc-margin-large {
	right: 1.5em;
}
.wpcc-bottom {
	bottom: 1em;
}
.wpcc-bottom.wpcc-margin-none {
	bottom: 0;
}
.wpcc-bottom.wpcc-margin-small {
	bottom: 0.5em;
}
.wpcc-bottom.wpcc-margin-large {
	bottom: 1.5em;
}
.wpcc-float .wpcc-message {
	display: block;
	margin-bottom: 1em;
}
.wpcc-banner {
	align-items: center;
}
.wpcc-banner.wpcc-top {
	left: 0;
	right: 0;
	top: 0;
}
.wpcc-banner.wpcc-bottom {
	left: 0;
	right: 0;
	bottom: 0;
}
.wpcc-banner .wpcc-message {
	display: block;
	flex: 1 1 auto;
	margin-right: 1em;
	max-width: 100%;
}
.wpcc-float .wpcc-compliance {
	flex: 1 0 auto;
}
.wpcc-corners-small {
	border-radius: 5px;
}
.wpcc-corners-normal {
	border-radius: 7px;
}
.wpcc-corners-large {
	border-radius: 10px;
}
.wpcc-corners-round .wpcc-btn {
	border-radius: 4px;
}
.wpcc-corners-round.wpcc-margin-none,
.wpcc-corners-round.wpcc-padding-none .wpcc-btn {
	border-radius: 0;
}
.wpcc-corners-round.wpcc-margin-none {
	border-radius: 0;
}
@media screen and (max-width: 414px) and (orientation: portrait),
	screen and (max-width: 736px) and (orientation: landscape) {
	.wpcc-top {
		top: 0 !important;
	}
	.wpcc-bottom {
		bottom: 0 !important;
	}
	.wpcc-banner,
	.wpcc-float,
	.wpcc-left,
	.wpcc-right {
		left: 0 !important;
		margin: 0 !important;
		right: 0 !important;
	}
	.wpcc-banner {
		align-items: unset;
		flex-direction: column;
	}
	.wpcc-corners-round {
		border-radius: 0 !important;
	}
	.wpcc-float {
		max-width: none;
	}
	.wpcc-message {
		margin-bottom: 1em;
		@include media-breakpoint-down("sm") {
			width: 100%;
		}
	}


	.wpcc-banner.wpcc-padding-small {
		padding: 0.5em;
	}
	.wpcc-padding-small .wpcc-message {
		margin-bottom: 0.5em;
	}
}

/// we already have the link inside the text
a.wpcc-privacy {
	display: none !important;
}

.wpcc-btn {
	font-weight: 100;
	text-transform: uppercase;
	padding-left: 1.25em !important;
	padding-right: 1.25em !important;
  	font-size: 0.895rem;
  	max-width: 240px;
  	margin: 0 auto;
}
