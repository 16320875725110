.slider-container {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 190px;
	min-height: 150px;
	max-height: 190px;
	background: $body-color;

	@include media-breakpoint-down("md") {
		height: 150px;
		min-height: 110px;
		max-height: 150px;
	}

	@include media-breakpoint-down("sm") {
		height: 125px;
		min-height: 100px;
		max-height: 125px;
	}

	//visibility: hidden;

	.swiper-slide {
		background: $dark;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: inline;
		position: relative;
		text-align: left;
		padding: 0;
		margin: 0;
		overflow: hidden;
		z-index: 1;
		// truspilot problem
		//max-width: 400px;

		h3 {
			position: absolute;
			@include bg-opacity($black, 0.45);
			color: $white;
			width: 100%;
			padding: 1em;
			bottom: 0;
			font-family: $font-family-titles;
			font-size: 1em;
			font-weight: bolder;
			//    text-transform: uppercase;
			letter-spacing: 0.75px;
			text-align: left;
			margin: 0;
			display: block;
			@include transition(all 0.3s ease);
			z-index: 10;

			span {
				display: block;
				width: 100%;
				font-weight: normal;
				font-size: $font-smaller;
				letter-spacing: 1px;
				text-transform: none;
			}
		}

		figure {
			position: relative;
			width: 100%;
			height: 100%;
			display: block;
			overflow: hidden;
			&.no-image {
				@extend %no-image;
				background-size: 100%;
			}

			> img {
				position: absolute;
				z-index: -1;
				left: 0;
				//margin-left:-100% ;
				@include transition(all 0.3s ease);
				top: 50%;
				left: 50%;
				position: relative;

				@include opacity(0);
				@include transform(translate(-50%, -50%) scale(1.15));
				// always will be landscape format ( more width that height )
				width: 100%;
			}

			figcaption {
				width: 100%;
				@include bg-opacity($black, 0.45);
				font-family: $font-family-titles;
				position: absolute;
				bottom: 10%;
				margin: 0;
				color: $white;
				line-height: 0.75em;
				overflow: hidden;
				margin-left: 100%;
				z-index: 2;

				@include transition(all 0.3s ease);

				h2 {
					font-size: 0.5em;
					font-weight: 400;
					letter-spacing: 0.5px;
					text-align: center;
				}

				.name {
					display: table-cell;
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
					text-align: center;
					top: 10px;
					position: relative;
					padding-top: 1rem;
					padding-bottom: 1rem;
				}

				.price {
					display: table-cell;
					width: 35%;
					background: $green;
					position: relative;
					text-align: right;
					vertical-align: middle;
					font-family: $font-family-numbers;
					font-weight: 800;
					padding-right: 8px;
					padding-top: 0.45rem;
					padding-bottom: 0.45rem;

					&:before {
						content: "";
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 0 90px (40px / 1.5);
						border-color: transparent transparent $green transparent;
						position: absolute;
						left: (-39px / 1.5);
						top: 0;
					}

					small {
						font-family: $font-family-sans-serif;
						font-size: 45%;
						position: absolute;
						display: block;
						z-index: 1;
						top: 50%;
						@include translate(8px, -50%);
					}
				}

				.component--category {
					margin: 0 auto;

					position: relative;
					top: -5px;

					font-size: 60%;
				}
			}
		}

		&:hover,
		&.active {
			h3 {
				bottom: 70%;
				padding: 0.25em;
				padding-left: 0.75em;
				overflow: hidden;
				white-space: nowrap;
				font-size: 60%;

				span {
					display: inline-block;
					width: auto !important ;
					margin-left: 0.25em;
					font-size: 80%;
				}
			}

			figure figcaption {
				margin-left: 0;
				display: table;
			}
			figure img {
				@include transform(translate(-50%, -50%) scale(1));
				@include opacity(1);
			}
		}

		&.popular {
			h3 {
				font-size: 55%;
			}
		}
	}
}

.hidden {
	visibility: hidden;
}
/* Individual effects */
