.component---micro-banner {
	$height: 62px;
	position: fixed;
	width: auto;
	//display: none;
	bottom: 0;
	right: 0;
	height: $height;
	padding: 0.5em;
	z-index: 1033;
	text-align: right;
	background-color: rgba($yellow, 0.9);
	color: $dark;
	pointer-events: none;

	$angle: calc(#{$height} / 1.5);

	/*
	-webkit-box-shadow: -3px -3px 0px 0px rgba($dark, 0.23);
	-moz-box-shadow:    -3px -3px 0px 0px rgba($dark, 0.23);
	box-shadow:         -3px -3px 0px 0px rgba($dark, 0.23);
	*/

	background-image: $icon-booking-dark;

	background-repeat: no-repeat;
	background-position: right 10px center ;
	background-size: 42px;
	padding-right: 70px;

	&:after, &:before {
		content: "";
		position: absolute;
		height: 0;
		width: 0;
		z-index: -1;
		right: 100%;
		top: 50%;
		@include translate(0, -50%);
		border-style: solid;
		border-width: 0 0 $height $angle;
		border-color: transparent transparent $yellow transparent;
		opacity: 0.9;

		


		// only firefox
		@media screen and (min--moz-device-pixel-ratio: 0) {
			right: calc(100% - 1px) !important;
		}

		@include media-breakpoint-down("md") {
			//	border-width: 48px calc(48px / 2.1) 0 0;
		}
	}

	&:before {

		border-width: 0 0 $height $angle;
		border-color: transparent transparent $dark transparent;
		opacity: 0.23;
		z-index: -2;
		margin-top: -3px;
		right: calc( 100% + 3px );
		display: none;

	}

	@include media-breakpoint-down("md") {
	//	display: block;
	pointer-events: all;
	cursor: pointer;

	}

	strong {
		font-weight: bolder;
		display: block;
		width: 100%;
		font-size: 1.25em;
        @extend %text-shadow;
        padding-left: 20px;
	}

	span {
		display: block;
		width: 100%;
		font-size: $font-smaller;
		position: relative;
		padding-right: 30px;
		margin-top:-6px;

		&:before {
			content: "";
			width: 20px;
			height: 2px;
			position: absolute;
			background-color: $green;
			display: block;
			top: 50%;
			right: 0;
			@include translate(0%, -50%);
		}
	}
}
