.component---phrases {
	width: 100%;
	height: 150px;
	background-color: $gray-200;
	width: 100%;

	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-down("md") {
		height: 130px;
	}

	@include media-breakpoint-down("sm") {
		height: 100px;
	}

	@include media-breakpoint-down("xs") {
		height: 80px;
	}

	div {
		h3 {
			padding: 1em 3em;
			//  border: 5px solid $white;
			font-size: calc(1vw + 1vh + 0.75vmin);

			color: $white;
			font-weight: bolder;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: $grid-gutter-width / 2;

			text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
		}
		// #4390EE
		// #CA4040
		// #FF8604
		&.color-1 {
			background-color: #4390ee;
		}
		&.color-2 {
			background-color: #ca4040;
		}
		&.color-3 {
			background-color: #6e5773;
		}
		&.color-4 {
			background-color: #ff8604;
		}
		&.color-5 {
			background-color: #6d3580;
		}

		/*
    text-shadow: 
    0 1px 0 #fff, 
    0 2px 0 #c9c9c9, 
    0 3px 0 #f8f8f8, 
    0 4px 0 #b9b9b9, 
    0 5px 0 #fff, 
    0 6px 1px rgba(0,0,0,.1), 
    0 0 5px rgba(0,0,0,.1), 
    0 1px 3px rgba(0,0,0,.3), 
    0 3px 5px rgba(0,0,0,.2), 
    0 5px 10px rgba(0,0,0,.25), 
    0 10px 10px rgba(0,0,0,.2), 
    0 20px 20px rgba(0,0,0,.15);
    */
	}
}
