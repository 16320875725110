.account {

     
    .bookings {
        
        &---lists {

            color: $dark;
            padding:1rem;

            .booking {

                border-bottom:1px solid $gray-500;
                height:80px;
                overflow: hidden;


                .status {

                    width: .75rem;
                    height: .75rem;
                    border-radius: 100%;
                    background-color: $gray-500;
                    display: inline-block;
                    margin-right: 0.75rem;
                    &:before { content: ''; }
                    position: relative;

                   
                }


            }

            button {

                color: $green;
                width:100%;
                height: 100%;
                position: relative;
                text-align: left;
                padding-right: 3.2rem !important;
                padding-left: 3rem !important;
                font-weight: normal;

                strong { font-weight: 500;}

                &::before {

                    content:"";
                    display: inline-block;
                    width: .75rem;
                    height: .75rem;
                    border-radius: 100%;
                    background-color: $gray-500;
                    display: inline-block;
                    margin-right: 0.75rem;
                    position: absolute;
                    left:1rem;
                    @extend %vertical-top-middle;

                }


                &.status-cancelled { &::before { background-color: $red; } }
                &.status-inprocess { &::before { background-color: $green; } }
                &.status-done { &::before { background-color: $blue; }}



                .btn-plus {

                    position: absolute;
                    
                    @include transition(all 0.3s ease);
                    @extend %rotate-vertical-top-middle;
                    right: 1rem;
                }

                &.collapsed {

                    color: $dark;

                    .btn-plus {
                               
                             @extend %vertical-top-middle;
                            }

                }

            }
            .card-body {

                border-bottom:1px solid $gray-500;

                table {
                    @include media-breakpoint-down("md") {
                    min-width: 900px !important;
                    }

                    th { background-color: rgba($gray-200, .75);}
                    td {

                        &.btn--link---container {  overflow: hidden; padding:0 !important; }

                        .button--link {

                            text-transform: none;
                            width: 100% !important;
                            height: 100%  !important;
                            overflow: hidden;
                            text-align: center;
                            display:block;
                            
                        }

                        &.text-ellipsis {

                            max-width: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .js-btn-copy {

                            @extend %vertical-top-middle;
                            position: absolute;
                            right: $grid-gutter-width/2;
                            cursor:pointer;

                        }
                        
                        width : 8.333% !important;

                        &.booking-code {
                            color:$white;
                            background-color: $green;
                            font-weight: 600;
                        }

                        position:relative;
                       .component--category {

                        @include transform(scale(0.75) );
                        position:absolute;
                        left:0;
                        bottom:-.275rem;

                       }

                        }
                        tr {
                        &.rule {
                            td {
                            min-height: 1px!important;
                            max-height: 1px!important;
                            height: 1px!important;
                            background-color: transparent;
                            margin: 0;
                            padding:0;
                            border:0 !important;
                        }
                    }

                    }

                }

            }
        }


    }

    &---profile { 

     //   border:4px solid red;

        div[class*='col'] { }

        div > strong { 

            padding: 0.25rem 0.5rem;
            display: block;
            width:auto;
            position: absolute;
           // border-left:4px solid $green ;
        
        }

        div > p {
            padding: 0.75rem 0.5rem;
            border-bottom:1px solid $gray-200;
            margin-top: 1.25rem;
            margin-bottom:0.5rem;
           
        }
    }
}