html,
body {
	height: 100%;
	width: 100%;
	margin: 0px;
	padding: 0px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	//font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	// this must to be here ( sets the values of the relative sizes on the rest of the page )
	font-family: $font-family-base;
}

body {
	background: $white;
	min-width: 320px;

	&.modal-opened {
		@include media-breakpoint-down("md") {
			overflow: hidden;
		}
	}


	&.full-modal-opened {
		
			overflow: hidden;
		
	}

}
.wrapper {
	min-height: 100%;
	/* Equal to height of footer */
	/* But also accounting for potential margin-bottom of last child */
	margin-bottom: -$footer-height;
	overflow-x: hidden;

	@include media-breakpoint-down("xs") {
		margin-bottom: -$footer-height * 2;
	}
}

.footer,
.push {
	height: $footer-height;
	@include media-breakpoint-down("xs") {
		height: $footer-height * 2;
	}
}

.footer {
	background: $dark;
	color: $white;
	margin: 0;
	padding: 0;

	div.table {
		// we set on display table so we can center the text vertical
		display: table;
		width: 100%;
		height: $footer-height;

		@include media-breakpoint-down("xs") {
			height: $footer-height * 2;
		}

		color: $white;
		margin: 0 auto;
		padding: 0;
		p {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			@include media-breakpoint-down("xs") {
				text-align: left;
				padding-left: $grid-gutter-width / 2;
				padding-right: 70px;
			}
		}
	}
}

.prices {
	font-family: $font-family-numbers;
	font-weight: 900;
}

/// --- structure 2-columns

.structure {
	&--2-columns {
		width: 100%;
		height: auto;
		position: relative;

		&---content {
			height: auto;
			position: relative;
			padding-top: $grid-gutter-width * 2;
			min-height: 25vh;

			@include media-breakpoint-down("md") {
			}
		}

		&---sidebar {
			position: relative;

			background: $white;
			border-left: 1px solid $gray-500;
			border-right: 1px solid $gray-500;
			min-height: 25vh;
			left: 0;

			.inner-sidebar {
				position: relative;
			}

			.js-close-sidebar {
				display: none !important;
			}

			@include media-breakpoint-down("2k") {
				max-width: 20% !important ;
				max-width: 20% !important ;
				left: 0;
				display: block !important;
			}

			@include media-breakpoint-down("xxl") {
				max-width: 22% !important ;
				max-width: 22% !important ;
				left: 0;
				display: block !important;

				&.order {
					max-width: 25% !important ;
					max-width: 25% !important ;
				}
			}

			@include media-breakpoint-down("xl") {
				max-width: 25% !important ;
				max-width: 25% !important ;
				left: 0;
				display: block !important;

				&.order {
					max-width: 30% !important ;
					max-width: 30% !important ;
				}
			}

			@include media-breakpoint-down("lg") {
				max-width: 25% !important ;
				max-width: 25% !important ;
				left: 0;
				display: block !important;

				&.order {
					max-width: 30% !important ;
					max-width: 30% !important ;
				}
			}

			@include media-breakpoint-down("md") {
				position: fixed;

				left: -460px;
				height: 100vh;
				min-height: 100vh;
				max-height: 100vh;
				padding-bottom: 100px !important;
				z-index: 1036;
				top: 48px;
				overflow-y: auto !important;
				border-top: 1px solid $gray-500;

				@include transition(all 0.3s ease);

				width: 75%;
				max-width: 340px !important;
				min-width: 240px !important;

				&.order {
					left: 0;
					width: 100%;
					opacity: 0;
					max-width: 100% !important;
					min-width: 100% !important;
					padding: 10vh 25vw !important ;
					background: transparent;
					background: rgba($black, 0.75);
					z-index: 10000;
					display: none !important;
					ul,
					nav {
						background: $white;
					}
					top: 0;

					.inner-sidebar {
						border: 30px solid $white;
						position: relative;
						nav {
							border: 1px solid $gray-500;
							border-bottom: 0;
						}
					}

					.js-close-sidebar {
						display: block !important;
						position: fixed;
						top: 20px;
						right: 20px;
						z-index: 99999999;
						//position: absolute;
						@include transform(rotate(45deg) scale(1.25));
						cursor: pointer;
						z-index: 100;

						opacity: 1;
						&:hover {
							&:after,
							&:before {
								background-color: $red;
							}
						}
					}

					&:before {
						content: "";
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						display: block;
						position: absolute;
						width: 100%;
						height: 100%;
						background-image: url($base-64-background);
						@include opacity(0.15);
						z-index: -1;
					}

					&.collapsing {
						left: 0;
						opacity: 0;
						display: block !important;
					}
				}

				/* To style the document scrollbar, remove `.custom-scrollbar` */

				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba($dark, 0.15);
					background-color: $white;
				}

				&::-webkit-scrollbar {
					width: 6px;
					background-color: $white;
				}

				&::-webkit-scrollbar-thumb {
					background-color: rgba($green, 0.25);
					border-radius: 4px;
				}

				&.show {
					left: 0;
					opacity: 1;
					display: block !important;
				}

				&.show ~ div {
					&:before,
					&:after {
						position: fixed;
						z-index: 1035;
						background: rgba($black, 0.75);
						width: 100%;
						height: 100%;
						display: block;
						top: 48px;
						left: 0;
						content: "";
					}

					&:after {
						background-color: transparent;
						background: url($base-64-background);
						@include opacity(0.15);
					}
				}
			}

			&.order {
				&.show ~ div {
					&:before,
					&:after {
						display: none !important;
					}
				}

				@include media-breakpoint-down("sm") {
					padding: 5vh calc(50vw - 220px) !important ;

					.inner-sidebar {
						border: 25px solid $white;
					}
				}

				@include media-breakpoint-down("xs") {
					padding: 5vh calc(50vw - 180px) !important ;

					.inner-sidebar {
						border: 20px solid $white;
					}

					@media only screen and (max-width: 380px) {
						padding: 40px calc(50vw - 154px) !important ;
						padding-bottom: 140px !important;

						.inner-sidebar {
							border: 10px solid $white;
						}
					}

					.js-close-sidebar {
						top: 10px;
						right: 10px;
					}
				}
			}

			// end sidebar
		}

		// end 2 columns
	}
}

img[src="Error.src"] {
	display: none;
}

//.container { border-left : 1px solid $gray-100;  border-right : 1px solid $gray-100 ;  }

///--- hack for ie11 wobble problem

@include only_ie10_above(
	"html",
	(
		overflow: hidden,
		height: 100%
	)
);

@include only_ie10_above(
	"body",
	(
		overflow: auto,
		height: 100%
	)
);
