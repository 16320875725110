// results init
.results {
	&--search---subtitle {
		position: relative;
		// top right bottom left
		padding: 0.75em 2.5em 0.75em 0em;
		text-transform: none;
		font-weight: 600;
		width: auto;
		display: inline-block;
		margin: 0.5em;
		margin-top: 0;
		background: $gray-100;
		&:before {
			content: "";
			display: block;
			width: 50vw;
			height: 100%;
			background: $gray-100;
			display: block;
			left: -50vw;
			top: 0;
			position: absolute;
			z-index: -1; /* to be below the parent element but it doesn't work' */
		}
	}
}
