.component---modal {
	position: fixed;
	background: rgba($black, 0.75);
	width: 100%;
	height: 100%;
	z-index: 999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;


	&.active {
		display: block;

		-webkit-animation: fadein 0.85s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.85s; /* Firefox < 16 */
        -ms-animation: fadein 0.85s; /* Internet Explorer */
         -o-animation: fadein 0.85s; /* Opera < 12.1 */
			animation: fadein 0.85s;
			
	}


	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	
	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	&:before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		//	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPgo8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI4IiBmaWxsPSIjNDAzYzNmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDBMOCA4Wk04IDBMMCA4WiIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiMxZTI5MmQiPjwvcGF0aD4KPC9zdmc+");
		background-image: url($base-64-background);
		@include opacity(0.15);
	}

	&---message {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $white;
		padding: 1.75em 2.75em;

		padding-left: 120px;
		padding-bottom: 90px;

		background-image: $icon-alert-bell;
		background-repeat: no-repeat;
		background-size: 60px 60px;
		background-position: left 30px center;
		overflow: hidden;

		&.text-content {
			overflow: hidden;
			padding: 0.75em;
			max-height: 80vh;
			overflow-y: auto;
			background: $white;

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba($dark, 0.15);
				background-color: $white;
			}

			&::-webkit-scrollbar {
				width: 6px;
				background-color: $white;
			}

			&::-webkit-scrollbar-thumb {
				background-color: rgba($green, 0.25);
				border-radius: 4px;
			}
		}

		&.large {
			overflow: hidden;
			padding: 0.75em;
			max-height: 80vh;
			max-width: 80vw;
			min-width: 50vw;
			padding-bottom: 80px;

			@include media-breakpoint-down("sm") { 

				min-width: 90vw;
				max-width: 90vw;
				max-height: 90vh;
			}

			color: $dark;

			overflow-y: auto;
			background: $white;

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba($dark, 0.15);
				background-color: $white;
			}

			&::-webkit-scrollbar {
				width: 6px;
				background-color: $white;
			}

			&::-webkit-scrollbar-thumb {
				background-color: rgba($green, 0.25);
				border-radius: 4px;
			}

			.modal---title {
				color: $dark;
			}
		}

		.modal---alert {
			min-height: 80px;
			color: $dark;
			background-color: $gray-100;
			padding-left: 80px;
			display: table;
			font-weight: 300;

			span,
			p {
				display: table-cell;
			}

			background-size: 35px 35px;
			background-position: left 25px center;
			background-repeat: no-repeat;

			&.success {
				background-color: rgba($green, 0.75);
				color: $white;
				background-image: $alert-success;
			}
		}

		.modal---footer {

			position: absolute;
			text-align: center;
			bottom:0;
			left: 0;
			background-color: $gray-100;
			
			a { 

				padding: 0.5em 1.5em;
				display: inline-block;
				background-color: rgba($dark, 1);
				color: $white;
				@include transition(all 0.3s ease);
				margin: 0.75em;
				font-weight: 300;

				&:hover {
					@include transition(all 0.3s ease);
					background-color: rgba($blue, 1);

				}

			}

		}

		.quote {
			border: 0 !important;
		}

		ul {
			width: 100%;
			li {
				width: 100%;
				border:0 !important ;
			}
		}

		a.btn {
			background-color: $dark;
			padding: 1.25em 2.75em !important;
			position: absolute;
			display: block;
			bottom: 0;
			left: 50%;
			@include translate(-50%, -50%);
			font-weight: 300;

			background-repeat: no-repeat;
			background-size: 30px 30px;
			background-position: center center;

			&--reset {
				background-image: $icon-reset;
			}
		}

		@include media-breakpoint-down("md") {
			background-position: top 20px center;
			padding: 100px 2.75em 80px 2.75em;
		}

		.btn-plus {
			position: absolute;
			@include transform(rotate(45deg));
			cursor: pointer;

			top: 20px;
			right: 20px;
			opacity: 1;
			&:hover {
				&:after,
				&:before {
					background-color: $red;
				}
			}
		}

		/* To style the document scrollbar, remove `.custom-scrollbar` */
	}

	.modal---title {
		position: relative;
		// top right bottom left
		padding: 0.75em 2.5em 0.75em 0em !important;

		text-transform: none;
		font-weight: 600;
		width: auto;
		display: inline-block;
		margin: $grid-gutter-width / 2;
		
		background: $gray-100;

		&:before {
			content: "";
			display: block;
			width: 50vw;
			height: 100%;
			background: $gray-100;
			display: block;
			left: -50vw;
			top: 0;
			position: absolute;
			z-index: -1; /* to be below the parent element but it doesn't work' */
		}
	}

	//end modal
}
