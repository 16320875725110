.paragraph {
	.green {
		color: $green !important ;
	}

	p { margin-bottom: 0.5em;}

	sup {
		line-height: 0;
		font-size: 75%;
		padding-left: 4px;
	}

	.img-small {
		width: 20%;
		height: auto;
		max-width: 120px;

		&.float-left {
			margin-right: 10px;
		}

		@include media-breakpoint-down("md") {
			width: 22.5%;
		}

		@include media-breakpoint-down("sm") {
			width: 20%;
		}
		@include media-breakpoint-down("xs") {
			width: 25%;
		}
	}

	.img-small-2 {
		width: 25%;
		height: auto;
		max-width: 150px;

		&.float-left {
			margin-right: 10px;
		}

		@include media-breakpoint-down("md") {
			width: 25%;
		}

		@include media-breakpoint-down("sm") {
			width: 30%;
		}
		@include media-breakpoint-down("xs") {
			width: 35%;
		}
	}


	h1 {
		position: relative;
		color: $white;
		z-index: 3;
		padding: 1em;
		padding-top: 0.75em;
		font-weight: bolder;

		@include media-breakpoint-down("sm") {
			padding: 0.75em;
			@include bg-opacity($secundary, 1);
		}

		&:before {
			content: "";
			display: block;
			width: 100vw;
			height: 300px;
			@include bg-opacity($secundary, 1);

			position: absolute;
			z-index: -2;
			/*
          -webkit-clip-path: polygon(0 0, 100% 0%, calc(100% - 150px) 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, calc(100% - 150px) 100%, 0% 100%);
          */
			top: -200px;
			right: $right-triangle;

			@include media-breakpoint-down("md") {
				top: -113px;
				height: 200px;
			}

			@include media-breakpoint-down("sm") {
				right: 0;
				top: 0px;
				height: 100%;
			}
		}

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			background: transparent;
			position: absolute;
			z-index: -1;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 300px $angle-triangle;
			border-color: transparent transparent $white transparent;
			top: -200px;
			right: $right-triangle;

			@include media-breakpoint-down("sm") {
				display: none;
			}
		}

		span {
			color: $complementary;
		}

		small {
			font-size: 0.75em;
		}
	}

	h2,
	h6 {
		position: relative;

		// top right bottom left
		padding: 0.75em 2.5em 0.75em 0em;

		text-transform: none;
		font-weight: 600;
		width: auto;
		display: inline-block;
		margin: 0.5em;

		background: $gray-100;
		padding-left: 0.75em !important;

		width: 100%;
		display: block;

		&:before {
			content: "";
			display: none !important ;
			width: 50vw;
			height: 100%;
			background: $gray-100;
			display: block;
			left: -50vw;
			top: 0;
			position: absolute;
			z-index: 98; /* to be below the parent element but it doesn't work' */
		}

		a {
			position: relative;
			@include translate(5%, -12.5%);
		}

		.btn-absolute {

			position: absolute !important; 
			right: $grid-gutter-width/2 !important;
			top:50%;
			@include transform(translateY(-50%));
		
		}

	}

	h2.dark-right {
		position: absolute;
		color: $white;
		z-index: 2;
		text-transform: none;
		width: auto;
		display: inline-block;
		top: 0;
		right: 0;
		@include translate(0%, -60%);

		&:after {
			position: absolute;
			width: 50vw;
			height: 100%;
			top: 0;
			left: 0;
			content: "";
			display: block;
			z-index: -1;
			background: $gray-800;
		}
	}

	&.order {
		h6 {
			font-size: 1.25em;
		}

		h2:before,
		h6:before {
			z-index: -1;
		}
	}

	// only if is inside a paragraph
	p a {
		position: relative;
		display: inline-block;
		outline: none;
		color: $complementary;
		vertical-align: bottom;
		text-decoration: none;
		white-space: nowrap;

		&::before,
		&::after {
			pointer-events: none;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-font-smoothing: antialiased;
			font-smoothing: antialiased;
		}

		font-weight: 500;
		-webkit-transition: color 0.2s;
		transition: color 0.2s;

		&:hover,
		&:focus {
			color: $white;
		}

		&::before {
			position: absolute;
			top: 0px;
			left: -5px;
			z-index: -1;
			box-sizing: content-box;
			padding: 0 5px;
			width: 100%;
			height: 100%;
			background: $green;
			content: "";
			opacity: 0;
			-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
			transition: transform 0.2s, opacity 0.2s;
			-webkit-transform: skewY(-3deg) skewX(-11deg);
			transform: skewY(-3deg) skewX(-11deg);
		}

		&:hover::before,
		&:focus::before {
			opacity: 1;
			-webkit-transform: skewY(0) skewX(0);
			transform: skewY(0) skewX(0);
		}
	}

	// be couse terms and conditions

	ol {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;

			b,
			p > b {
				position: relative;
				padding: 0.75em 2.5em 0.75em 0em;
				text-transform: none;
				font-weight: 600;
				width: auto;
				display: inline-block;
				margin: 0.5em;
				font-size: 1.35em;
				font-family: $font-family-titles;

				background: $gray-100;
				&:before {
					content: "";
					display: block;
					width: 50vw;
					height: 100%;
					background: $gray-100;
					display: block;
					left: -50vw;
					top: 0;
					position: absolute;
					z-index: 98; /* to be below the parent element but it doesn't work' */
				}
			}
		}
	}

	&.bt {
		border-top: 1px solid $gray-100;
	}

	&.bb {
		border-bottom: 1px solid $gray-100;
	}
	&--list-display {
		position: relative;
		height: 75px;
		li {
			text-align: left;
			height: 100%;
			img {
				height: 100%;
			}
		}

		.btn-plus {
			position: absolute;
			top: 50%;
			right: $grid-gutter-width;

			&:after,
			&:before {
				@include transition(all 0.3s ease);
				@include transform(rotate(135deg));
			}

			&.collapsed {
				&:after,
				&:before {
					@include transition(all 0.3s ease);
					@include transform(rotate(0deg));
				}
			}
		}

		border-bottom: 1px solid $gray-100;
	}

	span.highlight {
		color: $green;
		font-weight: 400;
	}

	/*

    h4   {


      color:$white;
      position: absolute ;
      bottom: -30px; 
      left:25%;
      z-index: 10;
      padding: 1.25rem;
      text-transform: none ;
      font-weight: 600;

              
  &:after {

      position: absolute ;
      width: 100vw;
      height: 100%;
      top:0;
      left:0;
      content: "" ;
      display: block ;
      z-index: -1;
      background: $gray-800 ;
  }

   }

   */

	&.text-columns {
		-webkit-columns: 2 320px;
		-moz-columns: 2 320px;
		columns: 2 320px;

		-webkit-column-gap: $grid-gutter-width;
		-moz-column-gap: $grid-gutter-width;
		column-gap: $grid-gutter-width;

		-webkit-column-rule: 1px dotted $gray-100;
		-moz-column-rule: 1px dotted $gray-100;
		column-rule: 1px dotted $gray-100;
	}

	&---body {
		h2 {
			margin-left: 0;
			width: auto;
			position: relative;
			display: inline-block;

			&:after {
				position: absolute;
				width: 50vw;
				height: 100%;
				top: 0;
				right: 100%;
				content: "";
				display: block;
				z-index: -1;
				background: $gray-100;
			}
		}
	}
	// end paragraph
}

.info-banner {
	background: transparent;
	position: relative;

	&:after {
		position: absolute;
		width: 100vw;
		height: 100%;
		top: 0;
		left: 0;
		content: "";
		display: block;
		z-index: -1;
		background: $gray-100;
	}

	ul,
	ul li {
		position: relative;
		list-style: none;
	}
	ul {
		li {
			padding: 1em;

			p {
				padding-left: 1em;
			}

			h4 {
				position: relative;
				background-color: $gray-800;
				color: $white;
				padding: 0.75em 1.5em;
				z-index: 2;
				text-transform: none;

				&:after {
					position: absolute;
					width: 50vw;
					height: 100%;
					top: 0;
					left: 0;
					content: "";
					display: block;
					z-index: -1;
					background: $gray-800;
				}
			}
		}
	}
}

.clean-html {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 1.15em;
		position: relative;
		font-weight: bold;
		padding: 0;
		&:before,
		&:after {
			display: none !important;
		}
		color: $dark;
		background-color: transparent;
		margin-bottom: 4px;
	}

	/*
	p { border:1px solid red; }
	ul { border:1px solid blue;  }
	ol { border:1px solid black;  }
*/

	p.promo {
		border: 4px solid $green;
		font-weight: normal;
		background-color: $green;
		color: $white;
		padding: 0.75em 0.5em;

		margin-top: 0.5em;
		margin-bottom: 0.5em;
		span {
			color: $white;
			font-weight: bold;
		}
		display: block;
		text-align: left;
		padding-left: 18px;
		position: relative;

		&:before {
			position: absolute;
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 0 10px 10px;
			border-color: transparent transparent transparent $white;
			top:50%;
			left:-4px;
			@include translate(0,-50%);
		}
	}

	// p { border:1px solid red; }


	ul,
	ol {
		margin-bottom: 1em !important;
		padding: 0;

		li {
			padding-left: 1em;
			border: 0 !important;
			position: relative;
			margin-bottom: 4px;
			list-style: none;

			&:before {
				content: "";

				position: absolute;
				display: block;
				width: 8px;
				height: 2px;
				background-color: $green;
				top: 0.65em;

				left: 0;
			}
		}
	}
}
.quote {
	border-left: 4px solid $gray-100;
	margin-top: 1em;
	padding: 0.75em;
	font-style: italic;
}

.text-blue {
	color: $blue;
}
.text-white {
	color: $white;
}
.text-dark {
	color: $dark;
}
.text-green {
	color: $green;
}
