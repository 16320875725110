// container
.component---banner-container {
	border-bottom: 1px solid transparent;

		position: fixed;
		top: 164px;
		z-index: 200;
		left: 50%;
		@include translate(-50%, 0);
		width: 100%;

	@include media-breakpoint-down("sm") {
		position: fixed;
		top: 120px;
		z-index: 200;
		left: 50%;
		@include translate(-50%, 0);
		width: 100%;
	}
}

// element
.component---banner-box {
	position: absolute;
	//pointer-events: none;
	background: rgba($green, 0.9);
	//background: rgba($dark, 0.9);
	width: 310px;

	z-index: 1000;
	//  top: -90px;
	//  right: 200px;
	right: $grid-gutter-width;
	top: -74px;

	@include media-breakpoint-down("sm") {
		right: auto;
		//
		left: 50%;
		margin-left: -155px;
		top: -60px;
	}

	color: $white;

	@extend %drop-shadow;

	h1 {
		text-align: center;
		margin-bottom: 0;
		background-color: rgba($white, 1);
		color: $green;
		padding-top: 1em;
		padding-bottom: 1em;
		font-weight: 900;
		font-size: 1.25em;
		text-transform: uppercase;

		.utils---traking {
			position: absolute;
			top: -5px;
			right: 0;
			height: 35px;
		}
	}

	> div {
		position: relative;
	}

	.box--header {
		//  border-bottom: 1px solid white ;
		min-height: 60px;
		overflow: hidden;

		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: none;

			background-size: 12px 12px;
			background-position: 95% center;
			background-repeat: no-repeat;
			opacity: 1;
			background-image: $icon-close-white;

			pointer-events: all;

			&.collapsed {
				background-size: 16px 16px;
				background-image: $btn-arrow-down-white;
			}

			&.active {
				display: block;
			}
		}
	}

	.box--body {
		padding: 0.75em;
		border-bottom: 4px solid white;
		
		> div {
			//padding-top: 0.75em;

			strong:after {
				content: ": ";
				display: inline-block;
			}

			font-size: $font-smaller;
		}

		h4 {
			font-family: $font-family-base;
			text-align: left;
			font-size: 1em !important;
			text-transform: none;
			font-weight: normal;
			letter-spacing: 1px;
		}

		padding-bottom: 1.5em;
	}

	.box--footer {
		min-height: 30px;
		padding: 1em;

		@include media-breakpoint-down("sm") {
			padding: 0.5em;
		}
	}

	.best-deal-container {
		height: 100%;
		position: absolute;
		width: 100%;
		text-align: center;
		top: 0;
		right: 0;

		&.active {
			@include transition(all 0.3s ease);
		}

		> div {
			display: inline-block !important;
			width: 100% !important;
		}

		.best-deal-price {
			width: 100%;
			span {
				display: inline-block !important;
				width: auto !important;
				font-weight: bolder !important;
				font-size: 2em;
				@extend %text-shadow;
			}

			span.price {
				font-family: $font-family-numbers;
			}
			span.currency {
				margin-right: 4px;
				padding-left: 0.25em;
				display: none;
			}
		}

		.best-deal-info {
			position: relative;
			position: absolute;
			bottom:0;
			right: 0;
			span {
				height: 20px;
				display: inline-block;
				font-size: $font-xs ;
				font-weight: 300;
				padding-left: 1em !important;
				padding-right: 0.5em !important;
				//border-right: 1px solid $white;
				text-align: right;


				&:first-child {
					position: relative;
					&:before {
						content: "";
						width: 1px;
						height: 60%;
						position: absolute;
						background-color: $white;
						display: block;
						top: 50%;
						right: -6px;
						@include transform(translate(0%, -50%) rotate(25deg));
					}
				}
			}
		}
	}

	.utils--spinner {
		position: absolute;
		left: 50%;
		top: 50%;

		@include translate(-50%, -25%);

		> div {
			border:0;
			background-color: $white;
		}
	}
}
