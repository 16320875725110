.panels .card,
.panels .card:last-child .card-header {
//	border: none;
	width: 100% !important;
}

.faq {
	.card {

		&.active {
			.btn-plus {
        @include transition(all 0.3s ease);
        @include transform(rotate(135deg) translateY(-50%));
      }
		}
	}
}

.panels {
	.card-header {
		border-bottom : 1px solid $gray-200 ;  
		background: transparent;
		padding: 1rem ;
		position: relative;
	}

	.card-body { padding: 1rem ; }

	.btn-plus {
		position:absolute;
		right: $grid-gutter-width / 2;
		@extend %vertical-top-middle ;

	}

	.btn {
		width: 100%;
		font-weight: bold;
		color: $primary;
		padding: 0;
		text-align: left;
	}

	.btn-link:hover,
	.btn-link:focus {
		text-decoration: none;
	}

	li + li {
		margin-top: 10px;
	}
}

.faq {
	h2 {
		margin-top: 1em;
	}
}
