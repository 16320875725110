.tile--google-maps {
	$goole-maps-tile-width: 250px;

	width: $goole-maps-tile-width;
	background: $white;
	padding: 1em;
	// @include transform( translate(-50%, 1%)) ;
	@extend %drop-shadow;
	@extend %user-select;

	&---container {
		display: block;
		width: 100% !important;
		height: 100%;

		.header-content {
			position: relative;
			height: auto;
			display: block;
			background-color: $dark;

			figure {
				background-color: rgba($dark, 0.75);
				width: 100%;
				padding-top: 45%; /* 16:9 Aspect Ratio  56.25%; */
				position: relative; /* If you want text inside of it */
				overflow: hidden;
				margin: 0;
				@extend %no-image;
				background-size: cover !important;
				z-index: 4;

				> img {
					position: absolute;
					top: 50%;
					left: 0;
					@include translate(0, -50%);
					width: 100%;
					border: 0;
					z-index: 5;
				}

				&:after {
					right: 0;
				}
				&:after {
					left: 0;
				}
			}

			// end header
		}

		h2 {
			text-align: center;
			background: rgba($dark, 1);
			color: $white;
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			margin: 0;
			font-size: 1.5em;
			font-weight: normal;
			width: 100%;

			span {
				display: block;
			}

			.component--category {
				font-size: 80%;
			}
		}

		.body-content {
			color: $gray-600;
			padding: 0.5em;
			font-family: $font-family-base;
			font-size: 1.25em;
			margin: 0;
			padding-bottom: 0;
			display: none;

			p {
				margin: 0;
			}

			p:before {
				content: "—";
				color: $green;

				margin-right: 8px;
				padding: 0;
			}
		}

		.info-content {
			margin: 0;
			color: $white;
			text-align: right;
			padding-right: 0.5em;
			padding-top: 0.5em;
			padding-bottom: 1em;
			position: relative;
			color: rgba($dark, 1);
			.price {
				letter-spacing: 1px;
				@extend %text-shadow;
				font-size: 2.75em;
				font-weight: bolder;
			}

			.per {
				font-family: $font-family-base;
				//       font-size: $font-small;
				font-weight: normal;
				position: absolute;
				bottom: 0px;
				right: 8px;
				z-index: 2;
				text-align: right;
				padding-right: 2.75em !important;

				&:after {
					position: absolute;
					content: "";
					display: block;
					width: 1.5em;
					height: 2px;
					display: block;
					background-color: $green;
					top: 50%;
					right: 5px;

					z-index: 5;
					margin-top: -1px;
				}
			}
		}

		.footer-content {
			margin: 0;
			padding: 0;
			margin-top: 6px;

			a {
				padding: 1em;
				margin: 0;
				display: block;
				width: 100%;
				text-transform: uppercase;
				font-family: $font-family-titles;
				letter-spacing: 1px;
				text-align: center;
				font-size: 1.25em;
				background: $secundary;
				color: $white;
				background-image: $btn-arrow-right;
				background-size: 20px 20px;
				background-position: 95% center;
				background-repeat: no-repeat;

				@include transition(all 0.3s ease);

				&:hover {
					@include transition(all 0.3s ease);
					background-color: $yellow;
					background-image: $btn-arrow-right-white;
					border: 0;
				}
			}
		}
	}

	/// label marker on map

	&---label--price {
		position: relative;
		background: $dark;
		border: 4px solid rgba($dark, 1);
		padding: 0.45em 1em 0.35em 1em;
		font-size: 1.05em;
		letter-spacing: 0.75px;
		font-weight: bolder;
		overflow: initial !important;
		color: $white;
	//	@extend %no-drop-shadow;
		@include translate(-50%, -100%);
	//	@extend %text-shadow;
		outline: none;

		&:after,
		&:before {
			top: 100%;
			left: 50%;
			display: block;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-color: rgba(0, 0, 0, 0);
			border-top-color: $dark;
			border-width: 5px;
			margin-left: -5px;
			margin-top: -1px;
		}
		&:before {
			border-color: rgba(0, 0, 0, 0);
			border-top-color: rgba($dark, 1);
			border-width: 10px;
			margin-left: -10px;
		}


		&.visited {

	
			//border-color: rgba($gray-500, 1);
			background: rgba($gray-500, 1);
			color: $dark;


			&:after {
			
				border-top-color: rgba($gray-500, 1);
				
			}


		}

		&.hover {
			@include transition(all 0.5s ease);
			background: $yellow;
			@include transform(translate(-50%, -115%) scale(1.15));
			color : $dark ;

			&:after { 

				@include transition(all 0.5s ease);
				border-top-color: $yellow;
				
			}
		}

	}

	/*
	&---label--price {

		padding: 0.25em 1.15em;
		
		border:2px solid $dark ;

		background: $suplementary;

		@include translate(-50%, 25%);

		color: $white;

		font-weight: 100 ;

		letter-spacing: 1px;

		font-size: 1em;

		@include transition(background-color 0.3s ease);

		&.hover {
			@include transition(all 0.15s ease);
			background: $secundary;
		//	@include transform(translate(-50%, 25%) scale(1.1));

		}

		&.selected {

			background: $green;

		}
	}

	*/
}

/// override close button styles ( classes added by default )
.infoBox {
	//transform: translateX(-50%) !important ;

	> img {
		width: 20px !important;
		height: 20px !important;
		position: absolute !important;
		z-index: 100 !important;
		background: rgba($green, 0.75) !important;
		//   right : 128px  ;
		top: 8px;
		right: 8px;
		margin: 1em !important;
	}
}
///--> end tile google-maps

.cluster {
	//display: none !important;
	//	border:4px solid red !important;
	@include translate(2.5%, -75%);
	//	overflow: hidden !important;

	&:focus, > div:focus {
		-webkit-box-shadow: 0 none !important;
		-moz-box-shadow: 0 none !important;
		box-shadow: 0 none !important;
		outline: 0 none !important;
	}
	
	

	&:empty {
		display: none !important;
	}

	img {
		top: 60% !important;
		left: 47.5% !important ;
		width: 100% !important;
		height: 100% !important;
		@include transform(translate(-50%, -50%));
		display: none;
	}

	background: $white;
	color: $dark;
	border: 4px solid $dark;

	span {
		display: block;
		width: 100%;
		height: 14px;
		position: relative;
		line-height: 14px !important;
		margin-left: -4px !important;
		white-space: nowrap;
		overflow: hidden;
		color: $dark;
		text-align: center !important;
		&:focus { outline: none;}
		outline: none;

		&:first-child {
			margin-top: 2px;
		}
	}



	&:hover {

		z-index: 9000001 !important;
	
		/*
			@include transition(background-color 0.3s ease);
		
			background-color: $secundary;
	
			&:after { 
	
				@include transition(border-top-color 0.3s ease);
				border-top-color: $secundary;
	
			}
			*/
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 0;
		height: 0;
		border: 8px solid transparent;
		border-top-color: $white;
		border-bottom: 0;
		border-left: 0;
		margin-left: -7px;
		margin-bottom: -8px;
	}
	
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 0;
		height: 0;
		border: 16px solid transparent;
		border-top-color: $dark;
		border-bottom: 0 !important;
		border-left: 0 !important;
		margin-left: -10px;
		margin-bottom: -16px;
		z-index: -1;
	}

}
