// layout init

.layout {
  &--popular-hotels {
    background: $secundary;

    padding-top: $grid-gutter-width / 2;

    padding-bottom: $grid-gutter-width / 2;

    h2 {
      color: $white;
    }

    //-- end pupular hotels
  }

  //-- end layout
}
