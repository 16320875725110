.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: $white;
	border-radius: 0px;
	border: 2px solid $gray-500;
	width: 278px;
	max-width: none;
	padding: 0;
	margin-top: 0px;
	top: 100px;
	left: 20px;
	z-index: 1040;
	display: none;
	font-size: 15px;
	line-height: 1em;

	@include media-breakpoint-down("sm") {
		position: fixed;
	}

	&.fixed {
		position: fixed;
		margin-top: -10px;
	}
}

.daterangepicker:before,
.daterangepicker:after {
	position: absolute;
	display: inline-block;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: "";
}

/*

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid $gray-500;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $white;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}


.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid $gray-500;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid $white;
}
*/
.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
	float: none;
}

.daterangepicker.single .drp-selected {
	display: none;
}

.daterangepicker.show-calendar .drp-calendar {
	display: block;
}

.daterangepicker.show-calendar .drp-buttons {
	display: block;
}

.daterangepicker.auto-apply .drp-buttons {
	display: none;
}

.daterangepicker .drp-calendar {
	display: none;
	max-width: 270px;

	@include media-breakpoint-down("xs") {
		min-width: 100%;
		max-width: 100%;
		width: 100%;
	}

}

.daterangepicker .drp-calendar.left {
	padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
	padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
	border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
	color: $white;
	border: solid black;
	border-width: 0 2px 2px 0;
	border-radius: 0;
	display: inline-block;
	padding: 3px;
}

.daterangepicker .calendar-table .next span {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	min-width: 32px;
	width: 32px;
	height: 24px;
	line-height: 24px;
	font-size: 12px;
	border-radius: 4px;
	border: 1px solid transparent;
	white-space: nowrap;
	cursor: pointer;
}

.daterangepicker .calendar-table {
	border: 1px solid $white;
	border-radius: 4px;
	background-color: $white;
}

.daterangepicker .calendar-table table {
	width: 100%;
	margin: 0;
	border-spacing: 0;
	border-collapse: collapse;
}

.daterangepicker td.week,
.daterangepicker th.week {
	font-size: 80%;
	color: $gray-500;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
	background-color: $white;
	border-color: transparent;
	color: $gray-400;
}

.daterangepicker td.in-range {
	background-color: $gray-100;
	border-color: transparent;
	color: $secundary;
	border-radius: 0;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
	background-color: $secundary;
	color: $white !important;
	border-color: transparent;
	border-radius: 4px;
	color: inherit;
}

.daterangepicker td.start-date {
	border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
	border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
	border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
	background-color: $primary;
	border-color: transparent;
	color: $white;
}

.daterangepicker th.month {
	width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
	color: $gray-400;
	cursor: not-allowed;
	text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default;
}

.daterangepicker select.monthselect {
	margin-right: 2%;
	width: 56%;
}

.daterangepicker select.yearselect {
	width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
	width: 50px;
	margin: 0 auto;
	background: $gray-100;
	border: 1px solid $gray-100;
	padding: 2px;
	outline: 0;
	font-size: 12px;
}

.daterangepicker .calendar-time {
	text-align: center;
	margin: 4px auto 0 auto;
	line-height: 30px;
	position: relative;
}

.daterangepicker .calendar-time select.disabled {
	color: $gray-400;
	cursor: not-allowed;
}

.daterangepicker .drp-buttons {
	clear: both;
	text-align: right;
	padding: 8px;
	border-top: 1px solid $gray-500;
	display: none;
	line-height: 12px;
	vertical-align: middle;
}

.daterangepicker .drp-selected {
	display: inline-block;
	font-size: 12px;
	padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
	margin-left: 8px;
	font-size: 12px;
	font-weight: bold;
	padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
	border-right: 1px solid $gray-500;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
	border-left: 1px solid $gray-500;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
	border-right: 1px solid $gray-500;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
	border-left: 1px solid $gray-500;
}

.daterangepicker .ranges {
	float: none;
	text-align: left;
	margin: 0;
}

.daterangepicker.show-calendar .ranges {
	margin-top: 8px;
}

.daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 100%;
}

.daterangepicker .ranges li {
	font-size: 12px;
	padding: 8px 12px;
	cursor: pointer;
}

.daterangepicker .ranges li:hover {
	background-color: $gray-100;
}

.daterangepicker .ranges li.active {
	background-color: $secundary;
	color: $white;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
	.daterangepicker {
		width: auto;
	}

	.daterangepicker .ranges ul {
		width: 140px;
	}

	.daterangepicker.single .ranges ul {
		width: 100%;
	}

	.daterangepicker.single .drp-calendar.left {
		clear: none;
	}

	.daterangepicker.single .ranges,
	.daterangepicker.single .drp-calendar {
		float: left;
	}

	.daterangepicker {
		direction: ltr;
		text-align: left;
	}

	.daterangepicker .drp-calendar.left {
		clear: left;
		margin-right: 0;
	}

	.daterangepicker .drp-calendar.left .calendar-table {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.daterangepicker .drp-calendar.right {
		margin-left: 0;
	}

	.daterangepicker .drp-calendar.right .calendar-table {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.daterangepicker .drp-calendar.left .calendar-table {
		padding-right: 8px;
	}

	.daterangepicker .ranges,
	.daterangepicker .drp-calendar {
		float: left;
	}
}

@media (min-width: 730px) {
	.daterangepicker .ranges {
		width: auto;
	}

	.daterangepicker .ranges {
		float: left;
	}

	.daterangepicker.rtl .ranges {
		float: right;
	}

	.daterangepicker .drp-calendar.left {
		clear: none !important;
	}
}

.datarange-background {
	display: none;
	@include media-breakpoint-down("sm") {
		position: fixed;
		background: rgba($black, 0.75);
		width: 100%;
		height: 100%;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1037;

	&:before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-image: url($base-64-background);
		@include opacity(0.15);
	}


	}
}
