.component---table-resume {


    font-size: $font-small;

    table th, table td {
    padding: 0.25em 0.75em ;
    }


    .bullet {

        position: relative;
        padding-left: 30px;

        &:before {
			content: "";
			width: 20px;
			height: 2px;
			position: absolute;
			background-color: $green;
			display: block;
			top: 50%;
			left: 0;
			@include translate(0%, -50%);
        }
        
        &.red {

            &:before { background-color: $red; }

        }


    }


}