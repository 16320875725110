/// vars --->
$border-color: $gray-500;
///--- end vars

.tile--room {
	min-height: 100px;
	border: 1px solid $border-color;
	border-bottom: 8px solid $border-color;
	position: relative;
	line-height: 1.5;
	//--temp
	margin-top: 8px !important;

	&---details {
		border-right: 1px solid $border-color;
		padding: 0 !important;

		.tracking {
			display: none !important;
		}
	}

	.js-btn-room-gallery { cursor: pointer; 
	
	&:hover { h3 { color: $green; }}
	
	}

	.has-gallery {


		cursor : pointer ;
		&:hover {

			span { border-color:$gray-500;}

		}
	}

	&---info {
		background: $gray-400;
		color:$white ;
		padding: 0 !important;
		font-size: small;
		width:100%;
		margin:0;
		padding:0.25em !important;
		display: none ;
		 span {
			 margin: 0.5em; 
			
			 strong {

				font-weight: normal;
				color : $dark ;

			 }
		 }
	}

	.info-display-show { display: block !important; }
	.info-display-hide { display: none !important; }

	&---name {
		border-bottom: 1px solid $border-color;
		padding: 0 !important;
		margin: 0 !important;
		position: relative;
		min-height: 50px;
		display: table;
		vertical-align: middle;
		display: flex;
		align-items: center;
	//	justify-content: center;

		&.js-btn-room-gallery {
			
			h3 { 
				
			
				@include transition(all 0.3s ease);
			}

			&:hover {
				h3 { 
					color: $green;
				}
			}


		}

	
		.has-images-line-dark {

			//border-top:1px solid $border-color;
			//border-bottom:1px solid $border-color;
			display: inline-block;
			position: relative;
			width: auto;
			//border:1px solid red;
			padding-top: 0 !important;
		//	margin-left: 2.5%;
			
			display: inline-flex;
			align-items: center;
			
			@include media-breakpoint-down("md") {

			
			}

			&:after {
					content: attr(data-quantity) '';
					display: flex-inline;
					width: auto;
					border: 1px solid $dark;
					border-radius: 2px;
					//background-color: $white;
				
					height: auto;
					font-size: 0.685rem;
					color: rgba($white, 1);
					text-align: center;
					font-weight: 400;
					padding: 0.15rem 0.35rem !important;
					@include transform(translate(0, -20%));
					background-color: rgba($dark, 1);
					
					/*
					position:absolute;
					right: 2px;
					top:50%;
					@include transform(translate(0, -55%));
					*/
					overflow: hidden;
					background-image: $icon-pictures-gallery;
					background-repeat: no-repeat;
					background-position: 4px center;
					padding-left: 28px !important;
					
					opacity: .65;
			}
		}


		.has-images-line {

			//border-top:1px solid $border-color;
			//border-bottom:1px solid $border-color;
			display: block;
			position: absolute;
			width: 100%;
		//	margin-left: 2.5%;
		//	margin-top: -.5px;
			bottom:12px;
			z-index: 1;
			
			@include media-breakpoint-down("md") {

				top:14px;
				bottom: auto;
			}

			&:after {
					content: attr(data-quantity) '';
					display: flex-inline;
					width: auto;
					border: 1px solid $border-color;
					background-color: $white;
					height: auto;
					font-size: 0.75rem;
					color: rgba($dark, .75);
					text-align: center;
					font-weight: 400;
					padding: 0.05rem 0.5rem !important;
					position:absolute;
					right: 2px;
					top:50%;
					@include transform(translate(0, -55%));
					overflow: hidden;
					background-image: $icon-pictures-gallery-dark;
					background-repeat: no-repeat;
					background-position: 4px center;
					padding-left: 30px !important;
					background-color: transparent;
					opacity: .75;
			}
		}
		.has-images {

			position: absolute;
			top:0;
			right:0;
			background-image: $icon-pictures-gallery-yellow;
			background-repeat: no-repeat;
			background-size: 40%;
			background-position: 85% 20%;
			width: 45px;
			height: 45px;
			z-index: 1;
			transform-style: preserve-3d;

			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 45px;
				height: 45px;
				background-image: $icon-fan-dark;
				background-repeat: no-repeat;
				background-position: top right;
				background-size: 75%;
				top:0;
				right:0;
				opacity: .5;
				z-index: -1;
				transform: translateZ(-1px);

		}

		}

		h2,
		h3 {
			padding: 0.25em 0.75em;
			width: auto;
			font-weight: bolder;
			font-size: 1.375em;
			font-weight: 800;
			margin: 0 !important;
			position: relative;
			display: inline-block;
			vertical-align: middle;
			text-transform: none;
			overflow: hidden;
			/*
			background: linear-gradient(to right, $yellow, $yellow 50%, $dark 50%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-size: 200% 100%;
			background-position: 100%;
			transition: background-position 275ms ease;

			&:hover { 

				background-position: 0 100%;

			}

			&:hover::before {
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
			  }
			  &::before {
				position: absolute;
				content: attr(data-content);
				color: rgba( $gray-600, .75);
				//text-decoration: underline;
				clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
				transition: clip-path 275ms ease;
			  }
*/
			.badge {
				position: relative; 
		
				border-radius: 2px;
				//height: 22px;
				background-color: rgba($color: $dark, $alpha: 0.75);
				display:inline-block;
				//@include transform(translate(50%, -25%));
				margin: 0 !important;
				padding: 0.05rem;
				
				width:auto;
				opacity: .90;

			i { margin-left: 0.25rem; }

				&::after {
					content: attr(data-quantity);
					display:  inline-block;
					width: auto;
					height: 100%;
					font-size: 0.65rem;
					color: $white;
					text-align: center;
					font-weight: 300;
					padding-left: 0.25rem !important;
					padding-right: 0.25rem !important;
					overflow: hidden;
				}
			}

/*
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border-left: 45px solid transparent;
				border-right: 45px solid transparent;
				border-top: 45px solid red;
				border-radius: 50%;
				transform: rotate(135deg);
				top: -22.5px;
				left: -22.5px;
				opacity: 0.25;
		}*/


		}

		&:after {
			position: absolute;
			content: "";
			display: block;
			width: 4px;
			height: calc(100% + 2px);
			display: block;
			background-color: $green;
			top: -1px;
			left: -1px;
			z-index: 5;
		}

		&:before {
			@include media-breakpoint-down("md") {
				position: absolute;
				content: "";
				display: block;
				width: 100%;
				height: 4px;
				display: block;
				background-color: $gray-100;
				bottom: -2px;
				left: 0;
				z-index: 5;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0 !important;
		margin: 0 !important;
		position: relative;

		li {
			display: inline-block;
			vertical-align: middle;
			padding: 0.25em;
			padding-left: 2.5em;
		}

		span.underline {
			border-bottom: 2px solid $green;
			&.red {
				border-bottom: 2px solid $red;
			}
		}

		.get-cancellation {
			cursor: pointer;
			position: relative;
			/* hover effect ??? maybe
      &:before {
        content: "";
        position: absolute;
        left: -4px;
        top: -4px;
        background-color: $green;
        z-index: -1;
        width: 0;
        opacity: 0;
        height: calc( 100% + 8px );
      }

      &:hover {
        color: $white;
        &:before {
          @include transition(all 0.3s ease);
          width: calc( 100% + 8px );
          opacity: 1;
        }
      }
      */
		}
	}
	&---details {
		ul {
			&:before,
			&:after {
				position: absolute;
				content: "";
				display: block;
				width: 1px;
				height: 100%;
				display: block;
				background-color: $border-color;
				top: 0;
				z-index: 5;
			}

			&:before {
				left: 33.333333%;
				@include media-breakpoint-down("sm") {
					left: 50%;
				}

				@include media-breakpoint-down("xs") {
					display: none;
				}
			}
			&:after {
				right: 33.333333%;

				@include media-breakpoint-down("sm") {
					display: none;
				}
			}

			li {
				background-color: transparent;
				background-size: 26px 26px !important;
				background-repeat: no-repeat;
				background-position: left 8px center;
				

				@include media-breakpoint-down("md") {
					padding-top: 0.25em;
					padding-bottom: 0.25em;
				}

				@include media-breakpoint-down("xs") {
					padding-top: 0.5em;
					padding-bottom: 0.5em;
				}
			}

			@include media-breakpoint-down("md") {
				border-bottom: 1px solid $border-color;
				padding-bottom: 15px !important;
			}

			@include media-breakpoint-down("xs") {
				padding-bottom: 20px !important;
			}
		}

		@include media-breakpoint-down("md") {
			border-right: 0;
		}
	}

	.has-taxes {
		@include media-breakpoint-up("lg") {
			min-height: 90px;
		}
	}

	&---offer {
		ul {
			&:after {
				position: absolute;
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				display: block;
				background-color: $border-color;
				top: 50%;
				left: 0;
				z-index: 5;
				margin-top: -0.5px;

				@include media-breakpoint-down("sm") {
					top: 0.75em;
					height: 100%;
					width: 1px;
					left: 50%;
					margin-top: -0px;
					margin-left: -0.5px;
				}
			}

			li {
				padding: 0 0.5em !important;

				&.description {
					white-space: nowrap; 
				}
				@include media-breakpoint-down("sm") {
					&.values {
						font-size: 2em;
						padding-top: 0.5em !important;
						padding-right: $grid-gutter-width * 2.5 !important;
					}
					&.description {
						text-align: right;
						position: relative;
						padding-right: 3em !important;
						margin-top: -4px;
						
						&:after {
							position: absolute;
							content: "";
							display: block;
							width: 1.5em;
							height: 2px;
							display: block;
							background-color: $green;
							top: 50%;
							right: $grid-gutter-width;

							z-index: 5;
							margin-top: -1px;
						}
					}
				}

				@include media-breakpoint-down("xs") {
					&.values {
						font-size: 1.765em;
						padding-top: 0.5em !important;
						padding-right: $grid-gutter-width / 2 !important;
					}

					&.description {
						padding-right: $grid-gutter-width * 1.5 !important;
						font-size: 12px;

						&:after {
							width: 1.15em;
							height: 1px;
							margin-top: -0.5px;
							right: $grid-gutter-width / 2 !important;
						}
					}
				}
			}
		}

		@include media-breakpoint-down("md") {
			margin-top: 0.75em !important;
			margin-bottom: 0.75em !important;
		}
	}

	&---prices {
		.values {
			font-weight: 900;
			font-size: 1.625em;
			font-family: $font-family-numbers;
			@extend %text-shadow;

			&.partial {
				color: $gray-400;
			}
		}

		// taxes
		small {
			position: relative;

			@include translate(0, -25%);

			@include media-breakpoint-down("sm") {
				position: absolute;
				@include translate(0, -15%);
				right: $grid-gutter-width * 2.5;
			}
			@include media-breakpoint-down("xs") {
				right: $grid-gutter-width / 2;

				@include translate(0, -15%);
				font-size: 12px;
			}
		}
	}
	.photo-info {

		position: absolute;
		top:50%;
		right:4px;
		width:30px;
		height: 30px;
		
		@include translate(0, -57.5%);
		z-index: 7;
		text-align: center;
		background-color: rgba($white, .75);
		.icon-rxxl--container {
			background-size: 90% !important;
			background-position: center center;
			opacity: 0.75;
			width: 25px;
			height: 25px;
			
		}
		.utils---tooltip {
			 left:auto; right:0; @include translate(0, -114%); 
			&:after { 
				left:auto; right:8px;
			}
		}
		&:hover {
			.utils---tooltip { display: block; }
		}
	}
	.utils--banner {
		position: absolute;
		top: 3px;
	//	left: auto;
	//	right:2px;
		z-index: 7;

		@include media-breakpoint-down("md") {
			top: -30px !important;
		}
	}

	&.best-offer {
		border-color: $yellow !important;

		&:before {
			display: none;
		}

		.tile--room---name {
			padding-bottom: 15px !important;
			min-height: 60px;
			&:before {
				background-color: $gray-100;
				height: 40px;
				bottom: -20px;
				z-index: 6;
			}

		}

		.tile--room---options {
			padding-top: 15px !important;
			@include media-breakpoint-only("md") {
				padding-top: 25px !important;
			}
		}

		// end best offer
	}

	.tile--room---options {
		@include media-breakpoint-down("md") {
			padding-top: 10px !important;
		}
	}

	.tracking {
		position: absolute;
		z-index: 6;
		bottom: 0px;
		left: 0.75em;
		height: 40px;
		display: block;
		width: auto;
		table,
		td {
			margin: 0;
			padding: 0;
			vertical-align: middle;
			height: 40px;
		}
		img {
			height: 40px;
			width: auto;
		}
	}

	@include media-breakpoint-down("md") {
		> .tracking {
			display: none;
		}

		.tile--room---name {
			.tracking {
				display: block !important;
				bottom: -20px !important;
				left: auto !important;
				right: 0.75em !important;
			}
		}
	}

	&---action {
		@include media-breakpoint-down("md") {
			padding-top: $grid-gutter-width / 2 !important;
			padding-bottom: $grid-gutter-width / 2 !important;
		}
	}


	// end tile room
}



.sub-container {

	position: relative;

	.photo-info {

		
	position: absolute;
	top:50%;
	right:4px;
	width:30px;
	height: 30px;
	
	@include translate(0, -120%);
	z-index: 7;
	text-align: center;
	background-color: rgba($white, .75);
	.icon-rxxl--container {
		background-size: 90% !important;
		background-position: center center;
		opacity: 0.75;
		width: 25px;
		height: 25px;
		
	}
	.utils---tooltip {
		 left:auto; right:0; @include translate(0, -114%); 
		&:after { 
			left:auto; right:8px;
		}
	}
	&:hover {
		.utils---tooltip { display: block; }
	}
	}

}