.component--category {
    width: auto;
    white-space: nowrap;
    display: inline-block;
    font-size: 1.25em;
    font-family: Times; // make sure ★ appears correctly
    line-height: 1.25em;
    position: relative;
    text-rendering: optimizeSpeed;

    &::before {
        content: "★★★★★";
        letter-spacing: 3px;
        color: $gray-500;
        word-wrap: nowrap;
    }

    &---percent {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        width: 80%;

        overflow: hidden;

        &::before {
            content: "★★★★★";
            letter-spacing: 3px;
            color: $green;
            word-wrap: nowrap;
        }
    }

    // end category
}

/// covers all porcents

@for $i from 0 through 100 {
	// for each $col_#{i}
	.width--#{$i}--percent {
		width: 1% * $i !important;
	}
}