.component---render-data {
	table {
		td {
            padding: 0.25em 0.75em;
            //text-align: center;
			strong {
				display: block;
			}
		}

		tr {
			td {
				&:nth-child(2) {
					position: relative;

					&:before {
						display: block;
						content: "";
						width: 1px;
						height: 70%;
						position: absolute;
						top: 50%;
						left: -0.5px;
                        background-color: $gray-500;
                        @include translate(0, -50%);
					}
				}
			}
		}
	}
}
