.form---search--container {
	height: 240px;

	position: relative;
	margin-top: -120px;
	z-index: 5;
	@include media-breakpoint-down("md") {
		height: 340px;
		margin-top: -90px;
	}

	@include media-breakpoint-down("sm") {
		height: 350px;
		margin-top: 0.5em;
	}

	@include media-breakpoint-down("xs") {
		height: 320px;
	}

	.container {
		height: 100%;
		@include media-breakpoint-down("sm") {
			padding: 0 !important;
		}
	}

	&:before {
		content: "";
		display: block;
		width: 75%;
		height: 100%;
		@include bg-opacity($complementary, 0.75);

		position: absolute;
		z-index: -1;
		-webkit-clip-path: polygon(0 0, 100% 0%, calc(100% - 120px) 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0%, calc(100% - 120px) 100%, 0% 100%);

		@include media-breakpoint-down("md") {
			width: 85%;
		}

		@include media-breakpoint-down("sm") {
			-webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
			clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
			width: 100%;
		}
		@include media-breakpoint-down("sm") {
			background-color: $white !important;
		}
	}

	.form---search {
		background: $white;
		height: auto;
		width: 100%;
		position: relative;
		@extend %drop-shadow;
		z-index: 4;
		padding: 0;
		top: 50%;
		transform: translateY(-50%);

		@include media-breakpoint-down("sm") {
			width: 100%;
			height: 100%;
			margin: 0;
			padding-bottom: 0.5em;
		}

		form {
			padding: 0.5em 0.75em !important ;
			margin: 0 !important ;
			position: relative;

			fieldset {
				border-right: 1px solid $gray-500;
				margin: 0 !important;
				padding-top: 30px;

				&:last-child,
				&:nth-last-child(2) {
					border-right: 0;
				}

				@include media-breakpoint-down("md") {
					// not ideal
					&.autocomplete {
						border-right: 0;
						border-bottom: 1px solid $gray-500;
						margin-bottom: 0.5em !important;
					}

					&:last-child {
						padding-top: 0.5em !important;
						margin-bottom: 0.5em !important;
					}
				}

				@include media-breakpoint-down("sm") {
					border-right: 0 !important ;

					border-bottom: 1px solid $gray-500;
					&.autocomplete {
						margin-bottom: 0em !important;
					}
					&:last-child {
						border: 0;
						margin-bottom: 0 !important;
					}
				}

				label {
					text-align: left !important;
					justify-content: left;
					//text-indent: 0.85em;
					font-family: $font-family-base;
					font-weight: 500;
					color: $dark;
					position: absolute;
					top: 15px;
					transform: translateY(-50%);
					padding: 0.5em;
					overflow: hidden;
					line-height: 1;
					border: 0;
					left: 0;
					z-index: 10;
					width: 100%;

					@include media-breakpoint-down("sm") {
						top: 20px;
					}
				}

				input,
				select {
					// @include transition(all 0.3s ease);
					color: $dark;
					width: 100%;
					font-size: 1em;
					//font-weight: 200 ;
					min-height: 50px;
					line-height: 50px;
					// border-bottom:8px solid $white;
					border: 0;
					padding: 0.25em !important;
					padding-top: 0;
					padding-bottom: 0;
				//	background-color: rgba( $dark, 0.25) !important;

					@include media-breakpoint-down("md") {
						max-height: 30px !important;
						min-height: 30px !important;
						height: 30px !important;
						line-height: 30px !important;

					}
				}
			}
		}

		.button--link {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			@include media-breakpoint-down("md") {
				top: auto;
				transform: translateY(0%);
				position: relative;
			}
		}
	}

	///------->

	.reset {
		bottom: 10px !important;
		right: 4px !important;

		@include media-breakpoint-down("md") {
			bottom: 11px !important;
		}

		@include media-breakpoint-down("sm") {
			bottom: 13px !important;
			
		}

		@include media-breakpoint-down("xs") {
			bottom: 7px !important;
			
		}

	}


	.utils--spinner {
		
		bottom: 10px !important;
		right: 8px !important;
	
		@include media-breakpoint-down("sm") {
			bottom: 10px !important;
		
		}
	}

}


#main-menu{

	.form---search--container {

		&:before { height: 100%; display: none; }

		position: relative;
		top:50%;
		.button--link {
		height: auto !important;
		}


		h4 {

			color: $white;
			padding:0 !important;
			padding-left: 34px !important;
			letter-spacing: 1px;
			position: absolute;
			top:-40px;
			left:0;
			height: auto !important;

			&:before {
				content: "";
				display: block;
				background: $white;
				width: 25px;
				height: 2px;
				position: absolute;
				top: 50%;
				margin-top: -1px;
				left: 0;
			}
		}

		  .btn-plus.js-close-nav-form {

			position: absolute;
			top:-38px;
			right:4px;

			@include transform(rotate(45deg) scale(1.25));
			cursor: pointer;
			background-color: transparent !important;
			&:hover {
				&:before,
				&:after {
					background-color: $red;
				}
			}
		}


		@include media-breakpoint-down("md") {
		
			&:before { display: none; }
			form { padding-top: 15px !important;}
			margin : $grid-gutter-width / 2 ;
			top:50% !important;
			@include translate(0, -50%);


		}

		@include media-breakpoint-down("sm") {
			margin : $grid-gutter-width / 2 ;
			top:50% !important;
			@include translate(0, -50%);

			form { padding-top: 15px !important;}

			&.check-a { 
			height: 230px;
			
			}

			.check-a { 
				
				height: 230px;
				padding-bottom: 0 !important;
				
			}
			
		}
		


	}
}