a,
a:hover,
a:link,
a:visited,
a:focus {
	text-decoration: none;
	cursor: pointer;
}

p {
	a {
		color: $primary;
		&:hover {
			color: $secundary;
		}
	}
}

.text-mutted {
	color: $gray-600;
}
