body > main > header.header--opacity {
	@include bg-opacity($black, 0.35);
}

body > main > header > nav a.logo {
	background: $white;
	height: 100%;
	padding: 0;
	margin: 0;
	display: block;
	width: 250px;
	position: absolute;
	z-index: 99;
	top: 0;

	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: $menu-link-height-lg ($menu-link-height-lg / 2.1) 0 0;
		border-color: $white transparent transparent transparent;
		position: absolute;
		left: 100%;
		top: 0;
	}

	&:before {
		content: "";
		display: block;
		width: 50vw;
		height: 100%;
		background: white;
		display: block;
		left: -50vw;
		top: 0;
		position: absolute;
		z-index: 98; /* to be below the parent element but it doesn't work' */
	}

	img {
		position: absolute;
		top: 50%;
		@include transform(translate(0, -50%));
		margin: 0 !important;
		padding: 0;
		width: 75%;
		height: auto;
		left: 0;
	}
}

@include render-into("xs") {
	body > main > header > nav a.logo {
		width: 180px;

		img {
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
		}
	}
}

@include render-into("sm") {
	body > main > header > nav a.logo {
		width: 200px;

		img {
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
		}
	}
}

@include render-into("md") {
	body > main > header > nav a.logo {
		img {
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
		}
	}
}
