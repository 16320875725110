/// MENU main header menu

header {
	position: relative;
	z-index: 1033;

	> nav.scrolled {
		@include media-breakpoint-up("lg") {
			@include transition(all 0.3s ease);
			background-color: $white !important;
		}
	}

	nav {
		z-index: 1033;
		@extend %drop-shadow;
		background: rgba($white, 0.75);

		@include media-breakpoint-down("md") {
			background-color: rgba($dark, 1);
		}
		.dropdown-menu {
			padding: 0;
			margin: 0;
			border: 0 solid transition !important;
			border: 0 solid rgba(0, 0, 0, 0.15);
			border-radius: 0;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			background-color: rgba($white, 1);
			
			.nav-link {
				border-bottom: 1px solid $gray-100;

				&:last-child {
					border-bottom: 0;
				}
			}

			.nav {
				background-color: $white;
				a:first-child {
					border-top: 1px solid $gray-100;
				}
				
			}

			@include media-breakpoint-down("md") {
			.form--fieldset---checkbox{
				
				label {

					span { top:0.475rem !important; 	 }

				}
				 }
		}
	}

		> .container {
			position: relative;
			min-height: 48px;
		}

		.navbar-brand {
			width: 180px !important ;
			height: calc(100% + 4px);

			position: absolute;
			padding: 0 !important ;
			margin: 0 !important ;
			z-index: 1032;
			display: block;
			top: -3px;

			@include media-breakpoint-down("xs") {
				width: 160px !important ;
			}

			img {
				position: relative;
				height: auto;
				width: 90%;
				margin: 0 auto;
				top: 50%;
				@include translate(0, -50%);
				@include media-breakpoint-down("md") {
					margin-left: 0.5em;
				}
			}

			@include media-breakpoint-down("md") {
				margin: 0.5em 0.75em;
				top: -2px;
			}

			&:before {
				content: "";
				position: absolute;
				height: 62px;
				width: 100vw;
				z-index: -1;
				right: 0;
				top: 50%;
				@include translate(0, -50%);
				background-color: $white;

				@include media-breakpoint-down("md") {
					height: 48px;
				}
			}

			$angle: calc(62px / 2.1);

			&:after {
				content: "";
				position: absolute;
				height: 0;
				width: 0;
				z-index: -1;
				left: 100%;
				top: 50%;
				@include translate(0, -50%);
				border-style: solid;
				border-width: 62px $angle 0 0;
				border-color: $white transparent transparent transparent;

				// only firefox
				@media screen and (min--moz-device-pixel-ratio: 0) {
					left: calc(100% - 1px) !important;
				}

				@include media-breakpoint-down("md") {
					border-width: 48px calc(48px / 2.1) 0 0;
				}
			}
		}

		.form-inline {
			width: 280px;

			small {
				line-height: 0.75em;
			}

			@include media-breakpoint-down("md") {
				margin: 0 auto;
			}
		}

		@include media-breakpoint-up("lg") {
			li:not(.search-button):hover .dropdown-menu {
			//	display: block;
			}

			.nav-item {
				// border-right: 1px solid $gray-100;

				&:last-child {
					border-right: 0;
				}
			}
		}

		.nav-item {
			> a {
				line-height: 3em;

				.icon-rxxl--container {
					margin-right: 8px;
				}

				@include media-breakpoint-down("md") {
					line-height: 2em;
				}
			}

			a {
				color: $dark;
				font-weight: normal;
				letter-spacing: 1px;
			}

			.flag-icon {
				margin-right: 0.45em;
				border: 0;
			}

			&.disabled {
				pointer-events: none;
			}
		}

		.nav-item a.dropdown-toggle {
			padding-right: 30px !important ;
			position: relative;

			&:after {
				position: absolute;
				top: 50%;
				right: 8px;

				width: 12px;
				height: 2px;
				display: block;
				background: $gray-600;
				@include translate(0, -50%);
				content: "";
				border: 0 !important;
			}

			&:before {
				position: absolute;
				top: 50%;
				right: 13px;
				display: block;
				width: 2px;
				height: 12px;
				background: $gray-600;
				@include translate(0, -50%);
				content: "";
				@include transition(all 0.3s ease);
				border: 0 !important;
			}
		}

		@include media-breakpoint-up("lg") {
			.nav-item:hover a.dropdown-toggle:before {
			//	height: 2px;
			}
		}

		@include media-breakpoint-down("md") {
			.nav-item.show a.dropdown-toggle:before {
				height: 2px;
			}
		}

		.buttons-container {
			position: absolute;
			width: auto;
			height: 48px;
			right: 0;
			float: right;
			z-index: 1300;
		}

		//--- hambuerger
		button:focus {
			outline: none !important;
		}

		button {
			border: 1px solid transparent !important;
			position: relative;
			height: 100% !important ;
			z-index: 1035;
			top: 50%;
			@include translate(0, -50%);

			&.hidden {
				display: none;
			}

			@media (max-width: 345px) {
				padding-left: 0.465em !important;
				padding-right: 0.465em !important;
			}

			.animated-icon {
				width: 30px;
				@include media-breakpoint-down("xs") {
					width: 28px;
				}

				height: 30px;
				position: relative;
				margin: 0px;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.5s ease-in-out;
				-moz-transition: 0.5s ease-in-out;
				-o-transition: 0.5s ease-in-out;
				transition: 0.5s ease-in-out;
				cursor: pointer;
			}

			&.search,
			&.best-offer-caller {
				.animated-icon {
					background-position: 50%;
					background-repeat: no-repeat;
					background-color: transparent;
					background-size: 95%;
				}
			}

			&.search {
				.animated-icon {
					background-image: $icon-search;
					background-position: center top;
				}
			}

			&.best-offer-caller {
				.animated-icon {
					background-image: $icon-best-offer;
				}
			}

			&.info-sidebar {
				.animated-icon {
					background-image: $icon-booking;
					background-size: 80%;
					background-position: 50%;
					background-repeat: no-repeat;
					width: 38px !important;
				}
			}

			.animated-icon span {
				display: block;
				position: absolute;
				height: 2px;
				width: 100%;
				border-radius: 0px;
				opacity: 1;
				left: 0;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.25s ease-in-out;
				-moz-transition: 0.25s ease-in-out;
				-o-transition: 0.25s ease-in-out;
				transition: 0.25s ease-in-out;
			}

			.animated-icon span {
				background: $white;
			}

			.animated-icon span:nth-child(1) {
				top: 12px;
				width: 80%;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
			}

			.animated-icon span:nth-child(2) {
				opacity: 0;
				top: 10px;
				left: -60px;
			}
			.animated-icon span:nth-child(3) {
				top: 12px;
				width: 80%;
				-webkit-transform: rotate(-135deg);
				-moz-transform: rotate(-135deg);
				-o-transform: rotate(-135deg);
				transform: rotate(-135deg);
			}
			/*
			&.info-sidebar {

				.animated-icon {
					background: none;
				}

				
				.animated-icon span {
					left: 4px;
					@include opacity(1);
					&:nth-child(2) {
						opacity: 0 !important;
						@include opacity(0);
					}
				}
			}
*/				

			.utils---filter-bubble { display: none; }

			&.collapsed {

				&.info-sidebar {
					.animated-icon {
					}
				}

				.utils---filter-bubble { 
					
					display: block;
					margin-top: -5px;
					left : 65% !important;
				
				
				
				}

				.animated-icon span {
					border-radius: 0px;
					opacity: 1;
					left: 0;
					-webkit-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-o-transform: rotate(0deg);
					transform: rotate(0deg);
					position: relative;
				}
				/*
				&.info-sidebar {
					.animated-icon span {
						left: 4px;
						opacity: 0 !important;
						@include opacity(0);
					}
				}
				*/
				.animated-icon span:nth-child(1) {
					top: 2px;
					width: 100%;
				}

				.animated-icon span:nth-child(2) {
					top: 10px;
					opacity: 1;
					width: 60%;
				}

				.animated-icon span:nth-child(3) {
					top: 18px;
					width: 75%;
				}

				///--- settings

				.animated-icon.settings span {
					&:before {
						content: "";
						position: absolute;
						width: 2px;
						height: 9px;
						background: $white;
						top: -3px;
					}
				}

				.animated-icon.settings span:nth-child(1) {
					top: 2px;
					width: 100%;

					&:before {
						left: 10%;
					}
				}

				.animated-icon.settings span:nth-child(2) {
					top: 10px;
					opacity: 1;
					width: 100%;

					&:before {
						left: 80%;
					}
				}

				.animated-icon.settings span:nth-child(3) {
					top: 18px;
					width: 100%;

					&:before {
						left: 50%;
					}
				}
			}
		}

		// responsive

		@include media-breakpoint-down("md") {
			.dropdown-menu.show {
				border-top: 1px solid $gray-100;
			}

			.navbar-collapse.collapse {
				position: fixed;
				z-index: 98;
				transition: all 0.3s ease;
				top: 47.5px;
				bottom: 0;
				left: -600px; /* -width of sidebar */
				width: 300px;
				height: 100% !important;
				display: block;
				background: $white;
				border-right: 1px solid $gray-100;

				ul > li {
					border-bottom: 1px solid $gray-100;

					&:first-child {
						border-top: 1px solid $gray-100;
					}

					a {
						padding-left: 0.75em;
					}
				}
			}

			.navbar-collapse.collapsing {
				transition: all 0.18s ease-in;
				position: fixed;
				top: 48px;
				bottom: 0;
				left: -600px; /* -width of sidebar */
				width: 300px; /* width of sidebar */
				height: 100% !important;
				display: block;
			}

			.navbar-collapse.collapse.show {
				left: 0;
				width: 300px; /* width of sidebar */
			}
		}

		li {
			&.search-button {
				@include media-breakpoint-up("lg") {
					width: 50px;
				}

				a {
					margin-left: 0 !important;
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					height: 100%;
					width: calc(100%);
					z-index: -1;
					background-color: rgba($dark, 1);

					@include media-breakpoint-down("md") {
						width: 100%;
					}
				}

				&:after {
					content: "";
					position: absolute;
					z-index: -1;
					bottom: 0;
					right: 100%;
					top: 0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 64px calc(64px / 2.1);
					border-color: transparent transparent $dark transparent;
					@include opacity(1);

					@include media-breakpoint-down("md") {
						display: none;
					}
				}

				.nav-link {
					color: $white !important;
					margin-left: 16px;

					&:before {
						display: none;
					}

					&:after {
						height: 40px;
						width: 20px;
						left: auto;
						bottom: auto;
						top: 50%;
						right: 8px;
						@include translate(0, -50%);

						background-size: 100%;
						background-position: 50%;
						background-repeat: no-repeat;
						background-color: transparent;
						background-image: $icon-search;
					}
				}

				//background-color: rgba($dark, 1) ;
				@include media-breakpoint-down("md") {
					// we cant hide it we need to put outside viewport

					position: fixed !important ;
					top: -1000px;
					right: -1000px;
					z-index: 9999;
					border-bottom: 0 !important ;
					height: 48px !important;
					width: 50px !important;
					span {
						display: none;
					}
				}

				&.round {
					span,
					&:after,
					&:before {
						display: none;
					}
					margin-left: 0;
					background-color: transparent;
					.nav-link {
						position: absolute;
						top: 50%;
						@include translate(0, -50%);
						width: 40px;
						height: 40px;
						border-radius: 100%;
						background-color: rgba($green, 0.75);
						right: auto;
						&:after {
							background-position: left -5px center !important ;
							background-size: 25px 25px !important ;
						}
						&:before {
							display: none;
						}
					}
				}
			}

			.nav-link {
				// just in 1 line
				overflow: hidden;
				white-space: nowrap;
			}

			// end search button
		}

		.nav---search-form---container {
			background-color: rgba($dark, 0.75);
			width: 100%;
			position: fixed !important ;
			top: 64px;
			left: 0;
			z-index: -1;
			@include transition(all 0.1s ease);

			max-height: 0;
			display: block;
			overflow: hidden;
			opacity: 0;

			&:before {
				content: "";
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: block;
				width: 100%;
				height: 100%;
				background-image: url($base-64-background);
				@include opacity(0.15);
				position: absolute;
				z-index: -1;
			}

			@include media-breakpoint-down("md") {
				top: 48px !important;
			}

			&.show {
				@include transition(all 0.3s ease);
				max-height: 260px;
				height: 260px;
				opacity: 1;

				@include media-breakpoint-down("md") {
				}

				@include media-breakpoint-down("md") {
					max-height: 310px;
					height: 310px;
					border-top: 2px solid $dark;
				}

				@include media-breakpoint-down("sm") {
					max-height: calc(100% - 48px);
					height: calc(100% - 48px);
				}
			}

			/*

			form {
				width: 100%;
				height: 100%;
				border: 1px solid transparent;
				display: none;

				.container {
					height: 100%;
					position: relative;
				}

				.nav--search-form----inner {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;

					@include translate(-50%, -50%);

					h4 {
						position: absolute;
						color: $white;
						padding-left: 78px !important;
						@include translate(0, -60%);
						letter-spacing: 1px;

						&:before {
							content: "";

							display: block;
							background: $white;
							width: 40px;
							height: 2px;
							position: absolute;
							top: 50%;
							margin-top: -1px;
							left: $grid-gutter-width + 6;
						}
					}

					> .btn-plus {
						top: -20px;
						right: 40px;
						position: absolute;
						@include transform(rotate(45deg));
						cursor: pointer;
						z-index: 100;

						@include media-breakpoint-down("md") {
							top: -15px;
						}
				


						&:hover {
							&:before,
							&:after {
								background-color: $red;
							}
						}
					}

					.row {
						background-color: $white;
						@include media-breakpoint-down("sm") {
							padding-bottom: 12px !important ;
						}
					}
				}
			}

			*/
		}

		// end main-menu
	}
}

/// button best offer
.nav--hotel {
	span.float-right {
		position: relative;
	}

	.utils---traking {
		border: 0;
		@include translate(0, 0);
		margin-top: 2px !important;
	}

	button {
		display: inline-block;
		background: none;
		cursor: hand;
		border: 0;
		height: 40px;
		margin-top: 0;
		margin-bottom: 0;
		top: 0;

		&:focus {
			outline: none;
		}

		&.hidden {
			display: none;
		}

		i.icon-rxxl--container {
			width: 30px;
			height: 20px;
			position: relative;
			margin: 0px;
			cursor: pointer;
		}

		i.icon-rxxl--container {
			width: 40px;

			&:before {
				display: block;
				position: absolute;
				content: "";
				width: 40px;
				height: 30px;

				background-position: 50%;
				background-repeat: no-repeat;
				background-color: transparent;
				background-image: $icon-best-offer;
				background-size: contain;
			}
		}
	}
}

.nav--hotel {
	@include media-breakpoint-down("md") {
		button {
			display: none !important ;
		}
	}

	i.icon-rxxl--container:before {
		background-image: $icon-best-offer-green !important;
		background-size: 30px 30px !important;
	}
	button {
		span {
			color: $green;
			text-transform: none;
			font-weight: bold;
			font-size: 1.1875em;
			//	font-family: $font-family-titles;
		}

		span.price {
			font-family: $font-family-numbers;
			font-weight: bolder;
			padding-right: $grid-gutter-width / 2 !important;
			padding-left: $grid-gutter-width / 6 !important;
			@extend %text-shadow;
		}
	}
}

/// MENU banner box

$highlight: $secundary;
$highlight-text: $white;

.box-banners nav {
	text-align: left;
	font-size: 100%;
	line-height: 1em;

	/* Make sure no padding & margins round <ul>s.
	 * Very likely to have been set in CSS for rest of site */
	ul {
		padding: 0;
		margin: 0;
		margin-bottom: $grid-gutter-width !important;
	}

	/* Main menu items */
	li {
		display: inline-block;
		position: relative;
		width: 48%;
		padding: 0;
		margin: 0;
		@include transition(all 0.3s ease);

		/* Space out the links */
		a {
			padding: 0.75em;
			padding-right: 1.25em;
			display: inline-block;
			text-align: left;
			position: relative;
			width: auto;
			position: relative;
			span {
				display: inline-block;
				margin-right: 0.5em;
			}

			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 2px;
				background-color: $green;
				display: block;
				left: 0;
				bottom: 0;
				@include transition(all 0.3s ease);
			}

			&.light-bg {
				color: $dark;
			}

			&.disabled {
				pointer-events: none;
			}

			i {
				width: 40px;
				//&.icon-rxxl---location { background-size: 110%; }
			}

			&.contact {
				padding-left: 0;
			}
		}

		@include media-breakpoint-down("md") {
			//	width:32.5%;
		}

		@include media-breakpoint-down("sm") {
			//	width:49%;
		}

		@include media-breakpoint-down("xs") {
			width: 100%;
			margin: 0;
			&.xs-width {
				width: 100%;
			}
			a {
				padding: 0.5em 0.25em;
				span {
					margin-right: 0.25em;
				}
			}
		}
	}

	/* Highlight li on hover */
	li:hover {
		// background-color: $highlight;

		a:before {
			width: 100%;
		}
		/* Change anchor colour to highlighted text */
		> a {
			color: $highlight-text;
			background: none;
		}

		> a.light-bg {
			color: $secundary;
		}

		/* Display subs and sub-subs on parent hover */
		> ul {
			display: block;
		}
	}
}

footer {
	.container {
		position: relative;
	}

	a.go-to-top {
		position: absolute;
		width: 60px;
		height: 70%;
		top: 15%;
		right: 0;
		border-left: 2px solid $white;
		background-image: $custom-up-arrow;
		background-size: 20%;
		background-repeat: no-repeat;
		background-position: center center;
		content: "";
	}
}

/// tool bar

.top-bar {
	&.sticky {
		position: fixed;
		top: 48px;
		z-index: 200;
	}
}

.toolbar {
	display: none;
}

.toolbar {
	& > ul {
		width: auto;
		height: 50px;

		display: inline-block;

		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		border: 0px;
		border-left: 1px solid white;
		height: 50px;
		width: auto;
		position: relative;
		margin: 0;
		padding: 0;

		margin-right: 10px;

		> a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 0;
			line-height: 50px;
			color: $white;
			position: relative;
			min-width: 50px;
			padding-right: 40px;
			top: 0;

			span {
				float: left;
				padding: 0 1em;
			}

			&:before {
				content: " ";
				width: 40px;
				height: 100%;
				z-index: 2;
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				background-color: rgba($red, 0);

				background-image: $custom-up-arrow;
				background-size: 75%;
				background-repeat: no-repeat;
				background-position: center center;
			}

			&.option-sorting {
				&.asc {
					
					&:before {
						background-image: $icon-order-up;
						background-size: 75%;
						
					}
				}
				&.desc {
					&:before {
						background-image: $icon-order-down;
						background-size: 75%;
					}
				}
			}
			&.option-map {
				&:before {
					background-image: $icon-pointer-map-dark;
					background-size: 65%;
				}

				&.show:before {
					background-image: $icon-pointer-map;
				}
			}

			&.option-edit {
				&:before {
					background-image: $icon-edit-dark;

					background-size: 70%;
				}

				&.show:before {
					background-image: $icon-edit;
				}
			}
			&.option-layout {
				&:before {
					//	background-image: $icon-layout-tiles;
					background-size: 100% !important;
				}

				&.full {
					&:before {
						//		background-image: $icon-layout-full;
						background-size: 100% !important;
					}
				}
			}
		}

		.dropdown-menu {
			background: $secundary;
			border: 0;
			margin-right: calc(-1em + 2px);
			margin-top: 1px;
			padding: 0;

			a {
				font-weight: 100;
				padding: 1em;
				position: relative;
				color: $white;

				&:hover {
					background-color: $primary;
				}

				&:before {
					content: " ";
					width: 40px;
					height: 100%;
					z-index: 2;
					position: absolute;
					display: block;
					top: 0;
					right: 0;
					background-color: rgba($red, 0);

					background-image: $custom-up-arrow;
					background-size: 80%;
					background-repeat: no-repeat;
					background-position: center center;
				}

				&.selected {
					background: $primary;
				}

				&.asc {
					&:before {
						background-image: $custom-pointer-up;
					}
				}

				&.desc {
					&:before {
						background-image: $custom-pointer-down;
					}
				}
			}
		}
	}
}

.pagination {
	margin: ($form-grid-gutter-width * 2) auto;

	&:empty {
		display: none;
	}
}

.nav {
	&--hotel {
		border-top: 4px solid $gray-100;
		border-bottom: 4px solid $gray-100;

		padding-top: 0 !important;
		padding-bottom: 0 !important;

		&.sticky {
			position: fixed;
			top: 64px;
			width: 100%;
			background: rgba($white, 1);
			border-color: $gray-100;
			z-index: 100;

			@include media-breakpoint-down("sm") {
				top: 48px;
			}
		}

		ul li {
			@include transition(all 0.3s ease);
		}

		@include media-breakpoint-down("sm") {
			font-size: 0.95em;

			.utils---traking {
				position: absolute;
				top: 0;
				right: 0;
				@include translate(0, -100%);
			}
		}

		a {
			//font-family: $font-family-titles;
			font-size: 1em;

			@include media-breakpoint-down("sm") {
				font-size: 0.95em;
			}

			@include media-breakpoint-down("xs") {
				font-size: 0.86em;
			}

			color: $dark;
			font-weight: normal;
			text-transform: none;

			@include transition(all 0.3s ease);

			&:hover {
				@include transition(all 0.3s ease);
				color: $secundary;
			}
		}

		li.active a {
			color: $primary;
		}
	}

	//-- end nav
}

#magic-line {
	position: absolute;
	bottom: -4px;
	left: 0;
	width: 100px;
	height: 4px;
	background: $green;
}

$nav--settings--height: 50px;
///---- tools navigation

.nav--settings {
	border: 1px solid transparent;
	display: inline-block;
	width: auto;
	font-size: 0.1px;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;

	& > ul {
		list-style: none;
		border-right: 1px solid $white;
	}

	ul,
	li,
	a,
	i {
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		width: $nav--settings--height;
		height: $nav--settings--height;
		border-left: 1px solid $white;
		position: relative;

		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

.settings-bar {
	background: $secundary;
	height: $nav--settings--height;
	margin: 0;
	padding: 0;
	z-index: 1032;

	&.sticky {
		position: fixed;
		top: 62px;
		width: 100%;
		@include media-breakpoint-down("md") {
			top: 48px;
		}
	}

	& > div {
		height: 100%;
		position: relative;
	}

	.information {
		position: relative;
		padding-left: 54px;
		color: $white;
		height: 100%;
		display: none;
		line-height: $nav--settings--height;

		&.active {
			display: inline-block;
		}

		&:before {
			position: absolute;
			height: 100%;
			width: $nav--settings--height;
			top: 0;
			left: 0;
			background-image: $icon-info-white;
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center center;
			content: "";
		}
	}

	.button-apply-changes {
		color: $white;
		height: 100%;
		line-height: $nav--settings--height;
		width: auto;

		border: 0;
		@extend %no-drop-shadow;

		position: relative;
		background: transparent;
		padding: 0;
		cursor: pointer;
		display: none;

		&.active {
			display: inline-block;
			border: 1px solid transparent;
		}

		&:focus {
			@extend %no-drop-shadow;
			border: 0;
			outline: none !important ;
		}

		i {
			position: relative;
			display: block;
			min-width: $nav--settings--height;
			height: 100%;
			margin: 0;
			padding: 0;
			font-style: normal;

			&:before {
				position: absolute;
				height: 100%;
				width: $nav--settings--height;
				top: 0;
				left: 0;
				background-image: $icon-apply-changes;
				background-size: 65%;
				background-repeat: no-repeat;
				background-position: center center;
				content: "";
			}

			&:after {
				background: $secundary;
				width: 315px;
				font-size: 90%;
				position: absolute;
				top: calc(100%);
				left: 0;
				display: none;
				text-align: left;
				margin-left: ($grid-gutter-width / 2) * -1;
				padding: 0.25em 0.75em;
				content: attr(data-text);
				text-transform: uppercase;
			}

			&:hover:after {
				display: table;
				z-index: 10;
			}
		}
	}

	.navbar-nav {
		display: none;

		&.active {
			display: flex;
		}

		li {
			min-width: $nav--settings--height;

			border-left: 1px solid $white;

			a {
				color: $white;

				position: relative;
				margin: 0;
				padding: 0;
				height: $nav--settings--height;
				line-height: $nav--settings--height;
				display: block;
				white-space: nowrap;
			}

			i,
			span {
				width: $nav--settings--height;
				height: $nav--settings--height;
				position: relative;
				padding-right: 8px;
			}

			span {
				@include media-breakpoint-down("xs") {
					display: none !important ;
				}
			}

			i {
				padding-right: $nav--settings--height;

				@include media-breakpoint-down("xs") {
					width: $nav--settings--height / 2;
					padding-right: $nav--settings--height / 2;
				}
			}

			i:before {
				position: absolute;
				height: 100%;
				width: $nav--settings--height;
				height: $nav--settings--height;
				//  background-image: $icon-apply-changes ;
				background-size: 65%;
				background-repeat: no-repeat;
				background-position: center center;
				content: "";
				display: block;
				top: 0;
				left: 0;

				@include translate(0, -30%);

				@include media-breakpoint-down("xs") {
					width: $nav--settings--height / 1.25;
					height: $nav--settings--height / 1.25;
					top: 100%;
					left: -15%;
					@include translate(0, -70%);
				}
			}
		}
	}

	.map {
		&:before {
			background-size: 65%;
			background-image: $icon-pointer-map-dark;
		}

		&.show:before {
			background-image: $icon-pointer-map;
		}
	}


	.edit {
		&:before {
			background-size: 65%;
			background-image: $icon-edit;
		}

		&.show:before {
			background-image: $icon-edit;
		}
	}

	.layout {
		&:before {
			background-size: 65%;
			background-image: $icon-layout-full;
		}

		&.full:before {
			background-size: 75%;
			background-image: $icon-layout-square;
		}

		@include media-breakpoint-down("md") {
			display: none !important ;
		}
	}

	.sorting {

		
		&.asc:before {
			
			background-image: $icon-order-up;
			background-size: 75%;
			

		}

		&.desc:before {
			background-image: $icon-order-down;
			background-size: 75%;
		}
	}

	.dropdown-menu {
		background: $secundary;
		border: 0;
		margin-right: 0;
		margin-top: 0px;
		padding: 0;
		right: 0;

		a {
			font-weight: normal;
			padding-left: 1em !important;
			position: relative;
			color: $white;
			font-size: $font-small;

			&:hover {
				background-color: $primary;
			}

			&:before {
				content: " ";
				width: 40px;
				height: 100%;
				z-index: 2;
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				background-color: rgba($red, 0);

				background-image: $custom-up-arrow;
				background-size: 80%;
				background-repeat: no-repeat;
				background-position: center center;
			}

			&.selected {
				background: $primary;
			}

			&.asc {
				&:before {
					background-image: $btn-arrow-up-white;
					background-size: 25%;
				}
			}

			&.desc {
				&:before {
					background-image: $btn-arrow-down-white;
					background-size: 25%;
				}
			}
		}
	}

	.text-message
	{

		line-height: 50px;

	}

	.btn-go-back
	{

		i {
					line-height: 50px;
					background-size: 15px 15px;		
			}

	}

	// end settings bar
}

.site-nav__line {
	background: $green;
	content: "";
	display: block;
	height: 4px;
	position: absolute;
	bottom: -4px;
	left: 0;
	width: 100%;
}

@import "navs/filters-room-list";
@import "navs/booking-process";
@import "navs/voucher";
