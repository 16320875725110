/* overrides CSS */
// remove all focus shadows
.form-control:focus,
a:focus,
textarea:focus,
button:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="submit"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
.uneditable-input:focus {
	-webkit-box-shadow: 0 none !important;
	-moz-box-shadow: 0 none !important;
	box-shadow: 0 none !important;
	outline: 0 none !important;
}

// remove autocomplete bakgrounds
input:-webkit-autofill,
input.edge-autoSuggestFieldFilled,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: 0 0 0 30px white inset !important;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

::-ms-clear {
	display: none;
}

/// set an 8 columns environment ( hard coded needs to be better )

.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8 {
	position: relative;
	min-height: 1px;
	padding-right: $grid-gutter-width / 2;
	padding-left: $grid-gutter-width / 2;
}

.col-xs-8 {
	width: 12.5%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-8 {
		width: 12.5%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-8 {
		width: 12.5%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-8 {
		width: 12.5%;
		float: left;
	}
}

// animated css

.animated.delay-8s {
	-webkit-animation-delay: 8s;
	animation-delay: 8s;
}

.animated.delay-12s {
	-webkit-animation-delay: 12s;
	animation-delay: 12s;
}

.fixed-top {
	top: 0 !important ;
}

.row.row-eq-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
  }