.tile--search-result {
	&---container {
		margin-bottom: $grid-gutter-width / 2 !important;
		position: relative;
		overflow: hidden;

		/// body
		@include media-breakpoint-down("sm") {
			//pointer-events: none;
		}

		&.item-hotel {
			[class^="col-"],
			[class*=" col-"] {
				flex: 0 0 100%;
				max-width: 100%;
			}

			.tile-view {
				display: block;
			}
			.full-view {
				display: none;
			}
		}

		[class^="col-"],
		[class*=" col-"] {
			border-bottom: 1px solid transparent;
			margin: 0;
			padding: 0;
		}

		& > .body {
			border: 1px solid $gray-500;
			border-bottom: 8px solid $gray-500;
			background: $dark;
			color: $dark;
			background: $white;
			@extend %drop-shadow;
			position: relative;
			height: 100%;
			//overflow: hidden;
			padding-bottom: 125px !important;

			.button--link {
				@include media-breakpoint-down("sm") {
					pointer-events: all;
				}
			}

			&:hover {
				// browsers add transform value ( makes the tile wobble )
				will-change: auto !important;
				@include transition(border-color 0.3s ease);
				border-color: $yellow;
				//border-color: $red;
				.button--link {
					@include transition(all 0.3s ease);
					color: $white;

					&:after {
						@include transition(all 0.5s ease);
						@include transform(translateX(10%) skewX(15deg));
						opacity: 1;
					}

					i,
					i:before,
					i:after {
						background-image: $btn-arrow-right-white;
					}

					i:before {
						animation: animate-arrow-before 500ms ease-in-out forwards 0.15s;
					}
					i:after {
						animation: animate-arrow-after 500ms ease-in-out forwards 0.1s;
					}
					i {
						animation: animate-arrow 500ms ease-in-out forwards 0.05s;
					}
				}
			}
		}

		///header
		.tile-header {
			position: relative;
			// overflow: hidden ;
			background-color: $dark;
			z-index: 1;

			&:after {
				content: " ";
				@extend %aspect-ratio-2;
				position: relative;
				display: block;
				top: 0;
				width: 100%;
				z-index: 1;
			}

			figure {
				position: absolute;
				width: 100%;
				height: 100%;
				display: block;
				overflow: hidden;
				margin: 0;

				z-index: 4;

				&.no-image {
					z-index: 1;
					@extend %no-image;
					background-color: $dark;
					background-size: contain;
					background-position: 50% !important;
					&:after {
						z-index: -1;
					}
					&:before {
						z-index: -1;
					}
				}

				> img {
					position: absolute;
					//margin-left:-100% ;
					@include transition(all 0.3s ease);
					top: 50%;
					left: 50%;
					@include transform(translate(-50%, -50%));
					// always will be landscape format ( more width that height )
					width: 100%;
					height: 100%;
					object-fit: cover;
					z-index: 5;
				}
			}
		} //end header

		// title

		h2 {
			position: relative;
			z-index: 5;
			width: 100%;
			bottom: 0;
			font-weight: bold;
			margin: 0;
			text-transform: none;
			@include translate(0, 0);
			font-size: $font-size-base * 1.1875;
			height: 80px;
			color: $dark;
			//@include bg-opacity($dark, 1);
			border-bottom: 1px solid $gray-500;

			span {
				padding: $grid-gutter-width / 2;
				position: absolute;
				width: 100%;
				text-align: center;
				top: 50%;
				@include translate(0, -60%);
			}

			.component--category {
				font-size: 90%;
				position: absolute;
				top: 100%;
				left: 50%;
				@include translate(-50%, -110%);
			}
		} // end title

		// rated tag

		.component---rating-tag {
			top: 100%;
			left: -10px;
			@include translate(0, -115%);
		}
		// price

		.price--label {
			width: auto;
			height: 65px;
			position: relative;
			text-align: right;
			margin: $grid-gutter-width / 2;
			margin-bottom: 0;
			margin-top: 0;
			padding-right: $grid-gutter-width / 2;

			.amount {
				display: block;
				position: relative;
				height: 100%;
				width: 100%;

				margin: 0;
				padding: 0;
				line-height: 0;

				div.value {
					display: block;
					position: absolute;
					width: auto;
					right: 0;
					top: 50%;
					@include translate(0, -100%);

					span {
						display: inline-block;
						line-height: auto;
						font-size: 1.75em;
						font-weight: 900;
						font-family: $font-family-numbers;
						@extend %text-shadow;
						letter-spacing: 1.5px;
					}
				}
				div.details {
					position: absolute;
					bottom: 14px;
					right: 0;
					width: auto;
					display: block;
					font-size: $font-small;
					padding-right: 28px;
					color: $dark;

					.begins {
						position: relative;
						margin-right: 16px;
						&:before {
							content: "";
							width: 1px;
							height: 80%;
							position: absolute;
							background-color: $green;
							display: block;
							top: 50%;
							right: -9px;
							@include transform(translate(0%, -50%) rotate(25deg));
						}
					}

					&:before {
						content: "";
						width: 20px;
						height: 2px;
						position: absolute;
						background-color: $green;
						display: block;
						top: 50%;
						right: 0;
						@include translate(0%, -50%);
					}
				}
			}
		}

		// end price
		.content-right {
			height: 100%;
			z-index: 5;
		}

		.tile-body {
			display: block;
			width: 100% !important;
			height: auto;
			z-index: 1;
			position: relative;

			p {
				padding: 0;
				margin: $grid-gutter-width / 2;
			}
		}

		.tile-footer {
			padding: $grid-gutter-width / 2;
			padding-top: 0;
			position: absolute;
			border-top: 1px solid $gray-500;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 125px;
			z-index: 2 !important;
		}
		.component---list-amenities {
			@include media-breakpoint-down("sm") {
				li {
					pointer-events: auto;
				}
				li:hover,
				li:focus {
					.utils---tooltip {
						display: block;
					}
				}
			}
		}

		&.item-hotel-full,
		&.selected {
			@include media-breakpoint-up("lg") {
				width: 100% !important ;
				flex: 0 0 100%;
				max-width: 100%;

				padding: $grid-gutter-width / 2;
				margin-bottom: 0 !important;

				.tile-view {
					display: none;
				}
				.full-view {
					display: block;
					z-index: 1 !important;
				}

				// rated tag

				.component---rating-tag {
					top: $grid-gutter-width / 2;
					@include translate(0, 0);
				}

				.component---list-amenities {
					text-align: left;
					margin: 0;
					z-index: 10 !important;
					position: relative;
				}

				.body {
					padding-bottom: 0 !important;

					background-color: rgba($white, 0.25);
				}

				.tile-header {
					height: 100% !important;
					width: 100% !important;
					z-index: 1 !important;
					position: absolute !important;
					&:after {
						padding-top: 0 !important;
					}
				}

				.content-right {
					border-bottom: 0 !important;
					padding-bottom: 60px !important;
					border: 0;
					display: inline-block !important;
				}

				.content-left {
					border-bottom: 0 !important;
					position: relative;
					z-index: 2;
					top: 0;
					left: 0;
					@supports (-webkit-overflow-scrolling: touch) {
						//border: 2px solid blue;
					}
					display: inline-block !important;
					figure {
						@supports (-webkit-overflow-scrolling: touch) {
						//	border: 4px solid black;
						}
						z-index: 10;
						top: 0;
						left: 0;
						img {
							@supports (-webkit-overflow-scrolling: touch) {
							//	border: 4px solid $green;
							}
						}
					}
				}

				.tile-footer {
					left: auto;
					right: 0 !important;
					border: 0 !important;
					height: auto;

					> div {
						width: calc(50% - 20px);
						position: absolute;
						top: auto;

						bottom: $grid-gutter-width / 2;

						&:first-child {
							left: 0;
						}
						&:last-child {
							right: $grid-gutter-width / 2;
						}
					}
				}
			}

			@include media-breakpoint-down("md") {
				[class^="col-"],
				[class*=" col-"] {
					flex: 0 0 100%;
					max-width: 100%;
				}

				.tile-view {
					display: block;
				}
				.full-view {
					display: none;
				}
			}
		}
	}
	// end container
}
