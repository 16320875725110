.component---hotel-header {
  height: auto;
  position: relative;

  h1 {
    display: block;
    width: 100%;
    text-transform: none;
    padding-left: $grid-gutter-width / 4;
    margin-bottom: $grid-gutter-width / 2;
    height: auto;

    @include media-breakpoint-down("sm") {
      text-align: center;
      padding-bottom: 0.5em;
      padding-top: 0.5em;
      padding-left: 0.25em;
      padding-left: 0.25em;
    }
  }

  div.address {
    font-size: 1em;
    display: inline-block;
    width: auto;
    position: relative;
    font-family: $font-family-base;
    font-weight: normal;
    padding-right: $grid-gutter-width;

    > p {
      margin-bottom: 0 !important;
    }

    @include media-breakpoint-down("sm") {
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 0.75em;
    }

    .component--category {
      font-size: 1.25em !important;

      .component--category---percent:before {
        color: $dark;
      }

      @include media-breakpoint-down("sm") {
        position: absolute;
        top: 0;
        left: 50%;
        @include translate(-50%, -80%);

        &:after {
          content: "";
          width: calc(100% + 13px);
          height: 100%;
          background-color: $white;
          display: block;
          position: absolute;
          top: 0;
          left: -8px;
          z-index: -2;
          border-left: 1px solid $gray-100;
          border-right: 1px solid $gray-100;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -0.4em;
      width: 100%;
      height: 1px;
      background-color: $gray-100;
    }
  }

  &.location-header {
    padding-left: 100px;

    @include media-breakpoint-down("sm") {
      padding-left: 85px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 90px;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: $gray-100;

      @include media-breakpoint-down("sm") {
        left: 75px;
      }
    }

    h1 {
      @include media-breakpoint-down("sm") {
        text-align: left;
      }
    }

    .address {
      @include media-breakpoint-down("sm") {
        padding-top: 0;
        text-align: left;
      }
      &:before {
        left: -10px;
        min-width: 175%;
      }
    }

    background-image: $icon-location-point-dark;
    background-size: auto 60px;
    background-position: 20px center;
    background-repeat: no-repeat;

    @include media-breakpoint-down("sm") {
      background-image: $icon-location-point-dark;
      background-size: auto 50px;
      background-position: left 12.5px center;
      background-repeat: no-repeat;
    }

    &.user-icon {
      background-image: $icon-user-male;
    }
  }

  //end hotel-header
}
