.pagination {
  .page-item {
    border-right: 1px solid $gray-500;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    &:last-child {
      border-right: 0;
    }

    &.wide {
      width: 140px;
      text-align: right;
      &:not(:first-child) {
        text-align: left;
      }
    }

    //   @include transform( scale(1) rotate(19deg) translateX(0px) translateY(0px) skewX(-10deg) skewY(-20deg) );

    .page-link {
      padding: 1.1em 1.6em;
      display: inline-block;
      text-decoration: none;
      color: $dark;
      border: 0;
      background: none;
      //      @include transform(  scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(20deg) skewY(0deg));

      @include media-breakpoint-down("sm") {
        padding: 0.75em 1.25em;
      }
    }

    &.active {
      background-color: $secundary;
      pointer-events: none;
      border: 0;

      .page-link {
        color: $white;
        pointer-events: none;
      }
    }

    &.fill {
      pointer-events: none !important;
    }

    &.link:hover .page-link {
      color: $primary;
    }


    &.first, &.last, &.center {

      border-bottom : 1px solid $gray-500;


    }
    &.center {
      text-align: center;
    }

    @include media-breakpoint-down("sm") {
      display: none;

      &.wide,
      &.active {
        display: inline-block;
      }

      &.wide {
        margin-right: 10px;
        border-right: 1px solid $gray-500;

        &:not(:first-child) {
          margin-right: 0px;
          margin-left: 10px;
          border-right: 0;
          border-left: 1px solid $gray-500;
        }

      }

      

    }
  }
}
