.nav---filters-room-list {
	background-color: $secundary;
	color: $white;
	z-index: 1;

	&.open {

		z-index: 25;
	}

	a {
		color: $white;
	}

	button {
		border: 0;
		width: auto;
		padding: 0 !important;
		position: relative;
		z-index: 10;

		&:before {
			content: attr(data-title);
			position: absolute;
			font-size: $font-smaller;
			background-color: transparent;
			width: auto;
			height: 100%;
			padding-right: 8px;
			line-height: 28px;
			color: $white;
			white-space: nowrap;
			display: block;
			right: 100%;
			@include media-breakpoint-down("sm") {
				color: $gray-600 !important;
			}
		}

		&:hover, &.show {
			&:before {
				display: block;
			}
		}
	}

	&.sticky {
		position: fixed;
		top: 48px;
		z-index: 200;
		width: 100%;
	}

	h2 {
		@include media-breakpoint-down("sm") {
			padding-top: 0.25em;
			padding-bottom: 0.25em;
		}
	}

	.nav-item {
		margin-left: $grid-gutter-width / 4;

		//	@include media-breakpoint-down("md") {
		margin: 0.25em ($grid-gutter-width / 2);
		//	}
	}

	.nav-link {
		border: 1px solid $white;
		position: relative;
		color: $white;
		height: 28px;
		padding-top: 0;
		padding-bottom: 0;
		min-width: 40px;
	}
	//icons
	i {
		width: 40px;
		background-size: 25px 25px;
		background-position: center center;
		background-color: $white;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
	}

	span {
		margin-left: 40px;
		padding-left: 0.25em;
		padding-right: 0.25em;
		color: $white;
		line-height: 28px;
		height: 28px;
		font-size: small;
		display: inline-block;
	}

	.nav-link {
		&:hover {
			.utils---tooltip {
				display: block;
			}
		}
	}

	.nav-item {
		&.active {
			@include opacity(1);

			.nav-link {
				border-color: $white;
				span {
					color: $white;
				}
				i {
					background-color: $white;
				}
			}
		}

		&.disabled {
			pointer-events: none;
			.nav-link {
				border-color: $dark-blue;
				span {
					color: $dark-blue;
				}
				i {
					background-color: $dark-blue;
				}
			}
		}

		@include media-breakpoint-down("sm") {
			.nav-link {
				border-color: $gray-600 !important;
				span {
					color: $gray-600 !important;
				}
				i {
					background-color: $gray-600 !important;
				}
			}

			&.disabled {
				@include opacity(0.45);
			}
		}
	}

	button {
		position: absolute;
		right: $grid-gutter-width / 2;
		top: 50%;
		@include translate(0, -50%);

		@include media-breakpoint-down("sm") {
			top: 100%;
			@include translate(0, calc(50% + 2px));
			z-index: 0;
			right: 0;
		}

		.nav-link {
			border-color: $white !important;
			pointer-events: none;
			span {
				color: $white !important;
			}
			i {
				background-color: $white !important;
			}

			padding-right: 30px !important;

			background-image: $icon-close-white;
			background-position: right 10px center;
			background-size: 8px 8px;
			background-repeat: no-repeat;

			@include media-breakpoint-down("sm") {
				border-color: $gray-600 !important;
				background-image: $icon-close-gray;

				pointer-events: none;
				span {
					color: $gray-600 !important;
				}
				i {
					background-color: $gray-600 !important;
				}
			}
		}

		&.collapsed {
			.nav-link {
				background-image: $btn-arrow-down;
				background-size: 10px 10px;
				@include media-breakpoint-down("sm") {
					background-image: $btn-arrow-down-gray;
				}
			}
		}

		&:hover,
		&:focus {
			.utils---tooltip {
				display: none !important;
			}
		}
	}

	//	@include media-breakpoint-down("md") {
	.show,
	.collapsing {
		position: absolute;
		top: 36px;
		right: $grid-gutter-width / 2;
		width: auto;
		min-width: 100px;
		width:auto;
		background-color: $secundary;
		z-index: 150;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		text-align: center;

		@include media-breakpoint-down("sm") {
			top: calc(100% + 44px);
			right: 0;
			background-color: $gray-500;
			min-width: 150px;
		}



	}
	//	}
}
