$el-height: 40px;
$angle: $el-height / 2.5;
.component {
	&---rating-tag,
	&---bestdeal-tag {
		position: absolute;
		width: auto;
		height: $el-height;
		padding-left: 50px;
		padding-right: 1.25em;
		display: inline-block;
		line-height: $el-height;
		//
		top: $grid-gutter-width / 2;
		left: -10px;
		z-index: 10;
		color: $white;
		letter-spacing: 1px;
		text-transform: capitalize;
		font-weight: normal;

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			@extend %gradient-logo;
			top: 0px;
			left: 0px;
			z-index: -1;
			@include opacity(0.9);
		}

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			background: transparent;
			position: absolute;
			z-index: -1;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: $el-height $angle 0 0;
			border-color: $green transparent transparent transparent;
			top: 0;
			left: 100%;
			@include opacity(0.9);
		}

		span {
			&:before {
				content: "";
				display: block;
				width: 60px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-repeat: no-repeat;
				background-size: 20px 20px;
				background-position: center center;
				background-image: $icon-rating-white;
            }
            

            &:after {
				content: "";
				display: block;
				position: absolute;
				top: 100%;
                left: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 0;
                border-color: transparent $dark transparent transparent;
                @include opacity(0.9);
                
            }
            
		}
	}

	&---bestdeal-tag {
		top: 100%;
		@include translate(0%, -50%);
		@include media-breakpoint-down("md") {
			@include translate(0%, -50%);
		}

		span {
			&:before {
				background-size: 30px 30px;
				background-image: $icon-best-offer;
			}
		}
	}
}
