.transfer {
	border: 0;
	width: 100%;
	padding: 5em;
	// this is the color on the widget
	background:#01cf7c;

	iframe {
		border: 0;
		width: 100%;
		min-height: 170px;

		.cab--searchform {
			//background-color: black !important;
		}

		@media only screen and (max-width: 818px) {
			min-height: 760px;
		}
	}

	@include media-breakpoint-down("md") {
		padding: 2.5em;
	}

	@include media-breakpoint-down("md") {
		padding: $grid-gutter-width / 2;
	}
}

.iframe---home-widget {
	position: relative;
	height: 290px;
	max-height: 290px;
	max-height: 290px;
	padding-top: 2.5em;

	.utils--spinner {
		left: 50%;
		top: 50%;
		position: absolute;
		z-index: 1034;
		@include transform(translate(-50%, -50%) scale(1.75));
	}

	img { 
		
		width: 160px;
		height: auto; 
		position: absolute;
		top:50%;
		left:50%;
		@include translate(-50%, -50%);
	
	}
}

.iframe---rentalcar {
	position: relative;
	min-height: 320px;

	.utils--spinner {
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		@include translate(-50%, -50%);

		div {
			border-color: transparent;
			width: 10px;
		}

		height: 60px;
		width: 100px;
		border: none !important ;
	}

	/// iframe

	/*

	.ct-ui-base {
		font-family: $font-family-base !important;
		font-size: 1em !important;

		border: none !important;
		margin: 0 !important ;
		color: $dark !important ;
	}

	// -- title

	.ct-ui-font-large {
		position: relative !important;

		// top right bottom left
		padding: 0.75em 2.5em 0.75em 0em !important;
		margin: 0 !important ;
		margin-bottom: 0.75em !important ;

		font-family: $font-family-base !important;
		text-transform: none !important;
		font-weight: 900 !important;
		width: auto !important;
		display: inline-block !important;
		color: $dark !important ;
		background: $gray-100;
		padding-left: 0.75em !important;

		font-size: 1.5em !important;

		&:before {
			content: "" !important ;
			display: block !important ;
			width: 100vw !important ;
			height: 100% !important ;
			background: $gray-100;

			display: block;
			right: 100% !important ;
			top: 0 !important ;
			position: absolute !important ;
			z-index: -1 !important ; // to be below the parent element but it doesn't work
		}
	}

	input.ct-input,
	label,
	.ct-select {
		font-family: $font-family-base !important;
		font-size: 1em !important;
		font-weight: normal !important;
		padding: 0.75em !important;
		border: 2px solid $gray-500 !important;
		min-height: 50px;
		color: $dark !important ;
		box-shadow: 0px 0px transparent !important;
		position: relative !important ;

		&:focus {
			box-shadow: 0px 0px transparent !important;
		}

		&:invalid,
		&.ct-error {
			box-shadow: 0px 0px transparent !important;
		}
	}

	.ct-select {
		&:after {
			display: none !important;
		}
	}

	select {
		border: 0 !important;
		width: 100% !important ;
		height: 100% !important;
		font-family: $font-family-sans-serif !important;
		font-size: 1em !important;
		font-weight: normal !important;
		position: absolute !important;
		color: $dark !important;
		top: 0 !important;
		left: 0 !important ;

		-webkit-appearance: button !important;
		-moz-appearance: button !important;
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		// just in case
		-webkit-box-sizing: border-box !important;
		-moz-box-sizing: border-bo !important;
		box-sizing: border-box !important;
		-webkit-appearance: none !important;
		-moz-appearance: none !important;

		-webkit-padding-end: 1em !important;
		-moz-padding-end: 1em !important;
		-webkit-padding-start: 2px !important;
		-moz-padding-start: 2px !important;

		background-color: $white !important;
		background-image: $btn-arrow-down-gray !important;
		background-position: right 10px center !important;
		background-size: 10px 10px !important;
		background-repeat: no-repeat !important;
		padding-left: 0.75em !important ;
	}

	.ct-icon-cancel-circle,
	.ct-icon-calendar {
		position: absolute !important;
		width: 25px !important;
		height: 25px !important;
		min-height: 25px !important;
		display: table !important;
		border-radius: 50%;
		background-color: $gray-500 !important;
		font-size: 1em !important;
		top: 50% !important;
		@include translate(0, -50%);
		cursor: pointer !important;

		&:before {
			content: "×" !important;
			position: absolute !important;
			width: 100% !important;
			height: 100% !important;
			text-align: center !important;
			display: table-cell !important;
			vertical-align: middle !important;
			color: rgba($dark, 0.5) !important;
			line-height: 20px !important;
		}
	}

	.ct-icon-calendar {
		border-radius: 0 !important;
		background-color: transparent !important ;
		&:before {
			content: " " !important;
			background-image: $icon-calendar-gray !important;
		}
	}

	.ct-different-dropoff {
		margin-bottom: 0 !important;
	}

	.ct-checkbox {
		border: 0 !important ;
		padding-top: 0.25em !important ;
		padding-bottom: 0.25em !important ;

		.ct-icon-checkmark-square-empty,
		.ct-icon-checkmark-square {
			border: 2px solid $gray-500 !important;

			@include translate(-25%, 0);

			&:before {
				content: "" !important ;
			}
		}

		.ct-icon-checkmark-square {
			&:before {
				background-size: 10px 10px !important;
				background-position: 50% !important;
				background-repeat: no-repeat !important;
				background-color: transparent !important;
				font-style: normal !important;
				background-image: $form-icon-valid !important;
				position: absolute;
				width: 100% !important;
				height: 100% !important;

				top: 0 !important;
				left: 0 !important;
			}
		}
	}

	.ct-ui-s-error {
		border: 0 !important;
		position: relative;
		z-index: 2;

		top: 5px !important;
		width: auto !important;
		background-color: transparent !important;

		left: auto !important;
		right: 0 !important;

		padding-bottom: 1em !important;
		//@include translate(0, -40%);

		.ct-icon-cancel {
			z-index: 102;
			display: table !important;
			&:before {
				content: "×" !important;
				position: absolute;
				width: 100%;
				height: 100%;
				text-align: center;
				display: table-cell;
				vertical-align: middle;
			}
			height: 30px !important;
			width: 30px !important;

			top: 0 !important;
			right: -2.5px !important;
		}

		label {
			border: 0 !important;
			width: auto !important ;
			position: relative !important;
			background-color: $red !important;
			padding: 0.75em 1.5em !important;
			z-index: 100;
			background-color: $red;
			color: $white;
			position: relative;
			width: auto;
			min-width: 4em;
			cursor: pointer !important ;

			&:after {
				content: "";
				position: absolute;
				bottom: 1px;
				left: 50%;
				width: 0;
				height: 0;
				border: 15px solid transparent;
				border-top-color: $red;
				border-bottom: 0;
				border-left: 0;
				margin-left: -10px;
				margin-bottom: -15px;
			}
		}
	}

	/// button
	.ct-btn,
	.ct-btn-s {
		background-color: transparent;

		font-family: $font-family-sans-serif !important;
		font-size: 1em !important;
		font-weight: normal !important;
		padding: 0.25em 0.75em !important;
		min-height: 50px;
		color: $dark !important ;
		box-shadow: 0px 0px transparent !important;
		position: relative !important ;
		text-transform: uppercase;
		border: 0 !important;
		overflow: hidden;

		span {
			padding: 0.25em !important ;
			position: static !important;

			&:after {
				//	display: none !important;
				position: absolute !important;
				top: 50% !important;
				right: 0 !important;
				@include translate(0, -50%);
				margin: 0 !important ;

				width: 60px !important;
				height: 100% !important;

				display: block !important;
				content: "" !important;
				background-image: $btn-arrow-right !important;
				background-size: 10px 10px !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				border: 0;
			}
		}

		&:before {
			position: absolute !important;
			content: "" !important;
			width: 100% !important;
			height: 100% !important;
			left: 0% !important;
			bottom: 0 !important;
			background: $yellow !important;
			z-index: -2 !important;
		}

		&:after {
			position: absolute !important;
			content: "" !important;
			width: 120% !important;
			height: 120% !important;
			left: -20% !important;
			bottom: -10% !important;
			background-color: $green !important;
			@include transition(all 0.5s ease);
			@include transform(translateX(-100%) skewX(15deg));
			z-index: -1 !important;
			opacity: 0 !important;
			@include opacity(0);
		}

		&:hover,
		&:focus,
		.active {
			@include transition(all 0.3s ease);
			color: $white !important;
			@include opacity(1);
			background-color: transparent !important;

			&:after {
				@include transition(all 0.5s ease);
				@include transform(translateX(10%) skewX(15deg));
				opacity: 1 !important;
				background-color: $green !important;
				@include opacity(1);
			}

			span {
				&:after {
					background-image: $btn-arrow-right-white !important;
				}
			}
		}
	}

	*/
	/// end iframe rent a car
}

/*
.ui-datepicker {
	.ui-datepicker-close {
		display: none !important;
	}

	font-family: $font-family-base !important;
	font-size: 1em !important;
	font-weight: normal !important;
	border: 2px solid $gray-500 !important;
	margin-top: -2px !important;

	box-shadow: 0px 0px transparent !important;

	.ui-datepicker-header {
		background-color: $secundary !important;
		font-size: 1em !important;
	}
	.ui-datepicker-title {
		font-family: $font-family-base !important;
		font-size: 1em !important;
		font-weight: normal !important;
	}

	.ct-icon-caret-left {
		&:before {
			content: "\00a0" !important ;
			background-size: 10px 10px !important;
			background-repeat: no-repeat !important;
			background-image: $btn-arrow-left-white !important ;
			background-position: center center;
		}
	}
	.ct-icon-caret-right {
		&:before {
			content: "\00a0" !important ;
			background-size: 10px 10px !important;
			background-repeat: no-repeat !important;
			background-position: center center;
			background-image: $btn-arrow-right-white !important ;
		}
	}

	table {
		td,
		th {
			font-size: 80% !important;

			a,
			span {
				text-align: center !important;
				font-weight: normal !important;
				padding: 0.25em !important ;
			}

			td.ct-active {
				background-color: $green !important;
			}

			a.ct-active {
				background-color: $secundary !important;
				color: $white !important;
			}
		}

		th {
			background-color: $gray-400;
		}
		thead {
			border-bottom: 6px solid $white !important;
		}
	}

	

	// end ui datapiker rent a car
}

*/

// end iframe rental car

.iframe---get-your-guide {
	width: 100% !important;
}
