@import "components/hotel-edit-bar";
@import "components/hotel-banner-box";
@import "components/hotel-header";
@import "components/header-widget";
@import "components/booking-summary";
@import "components/modal";
@import "components/alert";
@import "components/loader";
@import "components/image-reference";
@import "components/rating-tag";
@import "components/list-amenities";
@import "components/help-center";
@import "components/form-select";
@import "components/table-resume";
@import "components/render-data";
@import "components/phrases";
@import "components/category";
@import "components/mobile-micro-banner";
@import "components/tooltips";
@import "components/popover";
@import "components/modal-3d-payment";
@import "components/captcha";

.components {
	&--map---container {
		background-color: $dark;
		position: relative;
		overflow: hidden;
		display: none;
		margin-bottom: $grid-gutter-width / 2 !important;
		margin-left: $grid-gutter-width / 2 !important;
		margin-right: $grid-gutter-width / 2 !important;
		padding: 0 !important ;
		
		@include media-breakpoint-down("sm") {

			margin-top: $grid-gutter-width / 2 !important;
			margin-bottom: $grid-gutter-width !important;

		 }
		
		 &.sticky {

			margin-top: $grid-gutter-width * 2.5 !important;

		 }
		

		.loadme {
			left:50%;
		}
		&.show {
			display: block;
		}
		// calc(~"100vh - 150px")
		&:after {
			content: "";
			position: relative;
			display: block;
			top: 0;
			width: 100%;

			z-index: -1;
			// aspect ratio 16:9
			content: " ";
			padding-top: 56.25%;
			@include media-breakpoint-down("sm") {
				// 2 sticky header 50 + 48 + margin-bottom
				padding-top: calc(100vh - 254px) ;
				margin-top:15px;

			}

		}
	}

	&--map---container--box {
		width: 100%;
		height: 100%;
		position: absolute;
		margin: 0;
		padding: 0;

		

	}

	// end components
}




// error page 404

.error-404 {
	border: 8px solid $gray-500;
	width: 80%;
	margin: 14em auto;
	display: table;

	div:last-child {
		border-left: 8px solid $gray-500;
		width: 75%;
	}

	div {
		display: table-cell;
	}

	.number {
		background-image: $custom-404;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.hotel {
		background-image: $icon-hotel-dark;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}

	h2,
	p {
		padding: 1rem;
		margin: 0;
	}

	h2 {
		font-weight: 700;
	}
}

.home-rw {
	background: transparent;
	position: relative;

	&:after {
		position: absolute;
		width: 100vw;
		height: 100%;
		top: 0;
		left: 0;
		content: "";
		display: block;
		z-index: -1;
		background: $gray-100;

		@include media-breakpoint-down("sm") {
			left: -15px;
			width: 110vw;
			margin-top:-10px;
			}
	}

	.trustpilot-container {
		position: relative;
		padding: 20px;

		div {

			margin: 0 auto !important ;
		}
	}

	h2 {
		color: $white;
		position: absolute;
		bottom: -30px;
		left: 25%;
		z-index: 10;
		padding: 1.25rem;
		text-transform: none;
		font-weight: 600;

		&:after {
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 0;
			content: "";
			display: block;
			z-index: -1;
			background: $gray-800;
		}
	}
}

h1.title {
	text-transform: none;
	font-weight: 600;
	margin-top: -50px;
	z-index: 10;
	position: relative;
	color: $white;
	pointer-events: none;
	margin-bottom: 16px;

	@include media-breakpoint-down("md") {
		margin-top: -44px;
		margin-bottom: 8px;
	}

	@include media-breakpoint-down("sm") {
		margin-top: 0px;
		background-color: $secundary;
		margin-bottom: 0px;
		padding: 0.45em 0.65em;
	}
}

.home-au {
	@include media-breakpoint-down("sm") {
		margin-top: 14px;
	}

	h3 {
		color: $primary;
		font-family: $font-family-base;
		font-weight: 600;
		text-transform: none;
	}

	p {
		color: $complementary;
	}
}

.quality-service {
	h3 {
		font-family: $font-family-base;
		font-weight: 600;
		text-transform: none;
	}
}

.top-bar {
	width: 100%;
	height: 50px;
	background: $secundary;
	color: $white;
}

.results {
	padding-top: 10px !important;
}


.loadme {
	width: 40px;
	height: 40px;
	background-color: $green;
	position: relative;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
	top: 50%;
	//left: 50%;
	margin-top: -20px;
	margin-left: -20px;

	&.done {
		-webkit-animation: out 600ms forwards ease-in-out;
		animation: out 600ms forwards ease-in-out;
	}
}

@keyframes out {
	from {
		width: 40px;
		height: 40px;
		background-color: $green;
		position: relative;
		-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
		animation: sk-rotateplane 1.2s infinite ease-in-out;
		top: 50%;
		left: 50%;
		margin-top: -20px;
		margin-left: -20px;
	}

	to {
		width: 100%;
		height: 100%;
		background-color: $white;
		left: 20px;
		top: 20px;
		opacity: 0;
		display: none;
	}
}

@-webkit-keyframes sk-rotateplane {
	0% {
		-webkit-transform: perspective(120px);
		background-color: $white;
	}
	50% {
		-webkit-transform: perspective(120px) rotateY(180deg);
		background-color: $blue;
	}
	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
		background-color: $green;
	}
}

@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		background-color: $white;
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		background-color: $blue;
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		background-color: $green;
	}
}

#results {
	position: relative;
	width: 100%;
	margin: 0 auto;
	.loadme {
		margin-top: 25% !important;
	}
}

.google-popup--container .bo--actions {
	width: 100%;
}
.google-popup--container .bo--actions .amount {
	font-size: 24px;
}
.google-popup--container .bo--actions .action {
	text-align: right;
}
.google-popup--container .bo--actions .action .button.button__medium__green {
	margin-top: 10px;
}

.google-popup--container {
	background: $green;
	margin: 0;
	border-radius: 0;
	left: -50%;
	top: -15%;
	width: 200px;
	min-height: 200px;
	position: relative;
	margin: 0 !important;
	padding: 0 !important;
	border: 5px solid $white;

	a {
		display: block;
		width: 100%;
		height: 100%;
		background: $red;
		margin: 0 !important;
		padding: 0 !important;
		position: relative;

		.hotel-image {
			width: 100%;
			position: absolute;
			overflow: hidden;
			z-index: 4;
			background: $dark;

			top: 0;

			&:after {
				content: "";
				position: relative;
				display: block;
				top: 0;
				width: 100%;
				z-index: 1;
				// aspect ratio 16:9

				padding-top: 56.25%;
			}

			img {
				position: absolute;

				// always will be landscape format ( more width that height )
				width: 100% !important;
				margin: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.map-label {
	padding: 1em;
	background: $green;
	color: $white;
	font-family: $font-family-numbers;
	font-weight: 800;
	font-size: 1em;
	letter-spacing: 1px !important ;
}

///-----

.button {
	&--link {
		@extend .btn;

		background-color: transparent;
		text-align: center;
		text-transform: uppercase;
		color: $dark;
		padding: 0.975em;
		position: relative;
		font-size: $button-font-size;
		overflow: hidden;
		pointer-events: all !important;
		border: 0 !important ;

		top: auto;
		@include translate(0, 0);

		&:before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			left: 0%;
			bottom: 0;
			background: $yellow;
			z-index: -2;
		}

		&:after {
			position: absolute;
			content: "";
			width: 120%;
			height: 120%;
			left: -20%;
			bottom: -10%;
			background: $green;
			@include transition(all 0.5s ease);
			@include transform(translateX(-100%) skewX(15deg));
			z-index: -1;
			opacity: 0;
		}

		&---arrow {
			@include transition(all 0.3s ease);

			&,
			&:before,
			&:after {
				width: 60px;
				height: 100%;
				top: 0;
				right: 0;
				position: absolute;
				display: block;
				content: "";
				background-image: $btn-arrow-right;
				background-size: 10px 10px;
				background-position: center;
				background-repeat: no-repeat;
			}

			&:before {
				transform: translateX(-20px);
				opacity: 0;
			}

			// end arrows
		}

		&:hover,
		&:focus,
		.active {
			@include transition(all 0.3s ease);
			color: $white;

			&:after {
				@include transition(all 0.5s ease);
				@include transform(translateX(10%) skewX(15deg));
				opacity: 1;
			}

			i,
			i:before,
			i:after {
				background-image: $btn-arrow-right-white;
			}

			i:before {
				animation: animate-arrow-before 500ms ease-in-out forwards 0.15s;
			}
			i:after {
				animation: animate-arrow-after 500ms ease-in-out forwards 0.1s;
			}
			i {
				animation: animate-arrow 500ms ease-in-out forwards 0.05s;
			}
		}

		&---white {
			color: $white;

			&:after {
				background-color: $white;
			}

			i,
			i:before,
			i:after {
				background-image: $btn-arrow-right-white;
			}

			&:hover,
			&:focus,
			.active {
				color: $green;

				i,
				i:before,
				i:after {
					background-image: $btn-arrow-right-green;
				}
			}
		}

		&---dark {
			color: $white;
			&:before {
				background-color: $dark;
			}

			i,
			i:before,
			i:after {
				background-image: $btn-arrow-right-white;
			}

			&:hover {
				color: $white !important;
			}
		}
		// end link
	}

	//end button
}

@keyframes animate-arrow {
	0% {
		transform: translateX(0px);
		opacity: 1;
	}
	70%,
	100% {
		transform: translateX(10px);
		opacity: 1;
	}
}

@keyframes animate-arrow-after {
	0% {
		transform: translateX(-20px);
		opacity: 0;
	}
	70%,
	100% {
		transform: translateX(-5px);
		opacity: 0.65;
	}
}

@keyframes animate-arrow-before {
	0% {
		transform: translateX(-30px);
		opacity: 0;
	}
	70%,
	100% {
		transform: translateX(-10px);
		opacity: 0.45;
	}
}

