.component---header-widget {
	position: absolute;
	background-color: rgba($gray-200, 1);
	right: 0;
	height: 60px;
	@include opacity(0.9);
	width: auto;
	padding: 0.75em;
	top: 50%;
	@include translate(0, -50%);
	z-index: 2;

	&:after {
		content: "";
		position: absolute;
		display: block;
		width: 100vw;
		height: 100%;
		left: 100%;
		z-index: -1;
		background-color: rgba($gray-200, 1);
		top: 0;
	}

	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 60px calc(60px / 2.1);
		border-color: transparent transparent $gray-200 transparent;
		right: 100%;
		z-index: -1;
		top: 0;
	}

	span.reviewed {
		position: absolute;
		font-size: small;
		color: $dark;
		bottom: 4px;
		left: 1.25em;
		padding-left: 1em;
		z-index: 2;
		white-space: nowrap;
	}

	.trustpilot-widget {
		margin-top: 0px !important;
		border: 1px solid transparent;


		img { border:0; margin:0; padding:0; width:auto; height: 25px; margin-top:-10px !important; margin-left:15px !important; margin-right: 120px; }
	}

	//end hotel-header
}
